import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../../services/communication.service';
import { AccountService } from '../../services/account.service';
import { publicMethods } from '../../globals';

@Component({
  selector: 'saved-searches',
  templateUrl: './saved-searches.component.html',
  styleUrls: ['./saved-searches.component.css'],
})
export class SavedSearchesComponent implements OnInit {
  isSuperUser: boolean;

  privateSavedSearches: Array<any>;
  sharedSavedSearches: Array<any>;

  organisations: Array<any> = [];
  organisationUsers: Array<any> = [];

  isLoading = this.accountService.isLoading;

  constructor(
    private communicationService: CommunicationService,
    private accountService: AccountService,
    private pubMethods: publicMethods
  ) {}

  ngOnInit(): void {
    document.title = 'Saved Searches';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.getSavedSearchDetails();
  }

  getSavedSearchDetails(): void {
    this.accountService
      .getSavedSearchDetails()
      .subscribe(data => this.updateSavedSearchDetails(data));
  }

  updateSavedSearchDetails(data): void {
    if (this.isSuperUser) {
      this.organisations = data.organisations;
      this.organisationUsers = data.organisation_users;
      this.organisationUsers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForViewable = true));
      this.organisationUsers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForEditable = true));
    }
    this.privateSavedSearches = data.saved_searches?.private;
    this.updateSavedSearchEvents(this.privateSavedSearches);
    this.sharedSavedSearches = data.saved_searches?.shared;
    this.updateSavedSearchEvents(this.sharedSavedSearches);
  }

  updateSavedSearchEvents(listObject) {
    for (let i = 0; i < listObject?.length; i++) {
      let search = listObject[i];
      let params = search.params;
      search['getParams'] =
        this.pubMethods.getGetSearchStringFromParams(params);
      search['outputForParamsDisplay'] =
        this.pubMethods.changeSearchParamsToDisplayFormat(params);
    }
  }

  processSavedSearchDeleted(data, searchId) {
    if (data.success) {
      this.pubMethods.showInfoMessage('Saved search has been deleted.');
      this.privateSavedSearches = this.privateSavedSearches.filter(
        savedSearch => savedSearch.id !== searchId
      );
      this.sharedSavedSearches = this.sharedSavedSearches.filter(
        savedSearch => savedSearch.id !== searchId
      );
    }
  }

  deleteSavedSearch(searchId) {
    this.accountService
      .deleteSavedSearchByID(searchId)
      .subscribe(data => this.processSavedSearchDeleted(data, searchId));
  }

  removeUserOnSharedSavedSearch(userId, searchId) {
    this.accountService
      .removeUserFromSavedSearch(userId, searchId)
      .subscribe(data => this.updateSharedSavedSearchAfterUpdate(data));
  }

  addUserOnSharedSavedSearch(userId, searchId) {
    this.accountService
      .addUserToSavedSearch(userId, searchId)
      .subscribe(data => this.updateSharedSavedSearchAfterUpdate(data));
  }

  addTeamOnSharedSavedSearch(orgId, searchId) {
    this.accountService
      .addTeamToSavedSearch(orgId, searchId)
      .subscribe(data => this.updateSharedSavedSearchAfterUpdate(data));
  }

  removeTeamOnSharedSavedSearch(orgId, searchId) {
    this.accountService
      .removeTeamFromSavedSearch(orgId, searchId)
      .subscribe(data => this.updateSharedSavedSearchAfterUpdate(data));
  }

  updateSharedSavedSearchAfterUpdate(data): void {
    if (data.shared_saved_searches) {
      this.sharedSavedSearches = data.shared_saved_searches;
      this.updateSavedSearchEvents(this.sharedSavedSearches);
      this.pubMethods.showInfoMessage('Your saved search was updated.');
    }
  }
}
