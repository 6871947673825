<app-messages></app-messages>

<nav class="app-nav-container">
  <a class="mr-sm-5" href="/dashboard">    
    <img class="brand-logo" src="/assets/website-images/zint_brand_logo_white.png" alt="zint">
  </a>
  @if (accountEmail && appComponentRouter.url !== '/logout') {    
  <div class="nav-search-menu-container">
    <div *ngIf="appComponentRouter.url !== '/request_new_activation_token'" class="company-name-search-container">
      <i class="fal fa-search"></i>
      <input type="text" class="search-input" [(ngModel)]="companyNameSearch" (ngModelChange)="searchForCompanyByName()"
        maxlength="160" id="navbarDropdownMenuLink" 
        data-toggle="dropdown" 
        placeholder="Search for a company" />
        <div class="dropdown-menu overflow-auto">
          @if (isLoadingNameSearch.loadingCompanyNameSearch) {
            <zint-loader size="small"></zint-loader>
          }
          @for (shortlistedCompany of shortlistedCompaniesByName; track $index) {
          
            <a class="dropdown-item" href="company/{{ shortlistedCompany.company_number }}/">
              <table style="width: 100%">
                <tbody>
                  <tr>
                    <td>
                      {{ shortlistedCompany.company_name }}
                    </td>
                    <td style="float: right; font-style: italic">
                      &nbsp;&nbsp;&nbsp;{{
                      shortlistedCompany.revenue
                      | companyDataPipe : "Revenue" : ""
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </a>
          }
        </div>
    </div>
     <div class="nav-icon-menus">
      @if (appComponentRouter.url !== '/request_new_activation_token') {

        <a href="/dashboard" class="icon-nav-link"
          matTooltip="Find Companies">
          <i class="fas fa-filter"></i>        
        </a>

        <a *ngIf="hasZintGrowAccess" href="/grow" class="icon-nav-link"
          matTooltip="Zint Grow">
          <i class="fas fa-bolt"></i>
          <span *ngIf="zgUnreadCount" class="zg-counter-badge">{{zgUnreadCount}}</span>
        </a>

        <div class="dropdown">
          <a class="icon-nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
            <i class="fas fa-stream"></i>
          </a>

          <div class="dropdown-menu dropdown-menu-right"
            >

            <a class="dropdown-item border-bottom pb-2" *ngIf="accountEmail" 
            [ngClass]="{'nav-button-functionality-disabled': !hasPropensityScoreAccess}" 
            [attr.href]="hasPropensityScoreAccess
                          ? '/propensity-score'
                          : 'https://www.zint.io/platform-features/propensity-modelling'" 
            matTooltipPosition="below" 
            [matTooltip]="hasPropensityScoreAccess
                            ? ''
                            : 'You do not have access to Propensity Scoring'">
              <i class="fal fa-star mr-2"></i>
              Propensity Score
            </a>
            
            <a class="dropdown-item border-bottom py-2" *ngIf="accountEmail" href="/saved-searches">
              <i class="fal fa-play-circle mr-2"></i>
                Saved Searches
              </a>

            <a class="dropdown-item border-bottom py-2" [ngClass]="{
                        'dropdown-nav-button-functionality-disabled': !hasCrossSellAccess
                      }" [attr.href]="
                        hasCrossSellAccess
                          ? '/cross-sell-opportunities'
                          : 'https://www.zint.io/platform-features/cross-sell-tools'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
                        hasCrossSellAccess
                          ? ''
                          : 'You do not have access to the Cross-Sell Tool'
                      "><i class="fal fa-link mr-2"></i>Cross-Sell Tool</a>
          
            <a class="dropdown-item border-bottom py-2" [ngClass]="{
                        'dropdown-nav-button-functionality-disabled':
                          !hasCompanyMatcherAccess
                      }" [attr.href]="
                        hasCompanyMatcherAccess
                          ? '/matcher'
                          : 'https://www.zint.io/platform-features/pipeline-builder'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
                        hasCompanyMatcherAccess
                          ? ''
                          : 'You do not have access to the the Company Matcher'
                      "><i class="fal fa-upload mr-2"></i>Company Matcher</a>
          
            <a class="dropdown-item border-bottom py-2" [ngClass]="{
                        'dropdown-nav-button-functionality-disabled': !hasZintGrowAccess
                      }" [attr.href]="
                        hasZintGrowAccess
                          ? '/grow'
                          : 'https://www.zint.io/platform-features/trigger-event-notifcations'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
                        !hasZintGrowAccess ? 'You do not have access to Zint Grow' : ''
                      "><i class="fal fa-bolt mr-2"></i>Zint Grow</a>
          
            <a class="dropdown-item border-bottom py-2"[ngClass]="{
                        'dropdown-nav-button-functionality-disabled':
                          !hasMarketMappingReportAccess
                      }" [attr.href]="
                        hasMarketMappingReportAccess
                          ? '/market-mapping-report'
                          : 'https://www.zint.io/platform-features/pipeline-builder'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
                        hasMarketMappingReportAccess
                          ? ''
                          : 'You do not have access to Market Mapping Reports'
                      "><i class="fal fa-file-check mr-2"></i>Market Mapping Report</a>
          
            <a class="dropdown-item border-bottom py-2"[ngClass]="{
                        'dropdown-nav-button-functionality-disabled':
                          !hasSalesforceV2AccessMessage
                      }" [attr.href]="
                        hasSalesforceV2AccessMessage
                          ? '/salesforce'
                          : 'https://www.zint.io/platform-features/collaboration-tools'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" target="_blank" [matTooltip]="
                        hasSalesforceV2AccessMessage
                          ? ''
                          : 'You do not have access to the Salesforce Integration'
                      "><i class="fab fa-salesforce mr-2"></i>Salesforce Integration</a>
          
          
          
            <a class="dropdown-item border-bottom py-2" [ngClass]="{
                        'dropdown-nav-button-functionality-disabled':
                          !hasHubspotAccess
                      }" [attr.href]="
                        hasHubspotAccess
                          ? '/hubspot'
                          : 'https://www.zint.io/platform-features/collaboration-tools'
                      " matTooltipPosition="before" [matTooltipClass]="'narrow-mat-tt'" target="_blank" [matTooltip]="
                        hasHubspotAccess
                          ? ''
                          : 'You do not have access to the HubSpot Integration'
                      "><i class="fab fa-hubspot mr-2"></i>HubSpot Integration</a>         
          
          
          
            <a class="dropdown-item border-bottom py-2" [ngClass]="{
                        'dropdown-nav-button-functionality-disabled':
                          !hasCalendarIntegrationAccess
                      }" [attr.href]="
                        hasCalendarIntegrationAccess
                          ? '/calendar-integration'
                          : 'https://www.zint.io/platform-features/workflow-tools'
                      " matTooltipPosition="before" target="_blank" [matTooltipClass]="'narrow-mat-tt'" [matTooltip]="
                        hasCalendarIntegrationAccess ? '' : 'You do not have access to the Calendar Integration'
                      "><i class="fal fa-calendar-alt mr-2"></i>Calendar Integration</a>

            <a class="dropdown-item border-bottom py-2" 
              href="https://chromewebstore.google.com/detail/zint-spotlight-understand/haakpflnidjehklpfeipfkhejoeaodni" 
              matTooltipPosition="before" target="_blank"
              matTooltip="Visit Chrome Web Store">
                      <i class="fab fa-chrome mr-1"></i>
                      Chrome Extension
            </a>
            
          
            <a class="dropdown-item border-bottom py-2" href="#" data-toggle="dropdown">
              <i class="fal fa-bell mr-2"></i>
              <i *ngIf="
                        !isLoadingAlerts.isLoadingNotifications &&
                        notifications &&
                        notifications.length > 0" class="fa fa-circle notification-circle"></i>
              Alerts
            </a>
          
            <div class="dropdown-menu" id="alerts-dropdown-menu">
              <div class="alerts-holder">
                @if (isLoadingAlerts.isLoadingNotifications) {
                  <zint-loader size="small"></zint-loader>
                }
                <div class="text-center mt-1" *ngIf="
                              !isLoadingAlerts.isLoadingNotifications &&
                              notifications &&
                              notifications.length == 0
                            ">
                  <span>You have no notifications.</span>
                </div>
                <ng-container *ngFor="let notification of notifications">
                  <div class="dropdown-item">
                    <div class="row align-items-center">
                      <div class="col-sm-10">
                        <span>{{
                          notification.number_of_unread_companies
                          }}
                          organisations matched your alert "{{
                          notification.name
                          }}"</span>
                        <span class="alerts-time-ago">({{ notification.latest_company_found }})</span>
                      </div>
                      <div class="col-sm-2">
                        <a class="basic-button-base button-dark alerts-play-button" [attr.href]="notification.search_url"><i
                            class="fa fa-play"></i></a>
                        <button class="basic-button-base button-dark alerts-mark-unread-button"
                          (click)="markAlertAsRead($event, notification.id)">
                          <i class="fal fa-spinner fa-spin" *ngIf="isLoadingAlerts.isLoadingMarkAsRead"></i>
                          <i class="fa fa-envelope-open" *ngIf="!isLoadingAlerts.isLoadingMarkAsRead"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                </ng-container>
                <a href="/alerts" class="manage-alert-link">Manage your alerts</a>
              </div>
            </div>
          
            <a class="dropdown-item border-bottom py-2" href="https://support.zint.io" title="Knowledge Base" target="_blank"><i
                class="fal fa-graduation-cap mr-2"></i>Knowledge Base</a>   

            <a class="dropdown-item pt-2" href="https://www.zint.io" title="Zint" target="_blank">
              <i class="fa-kit fa-zint mr-2"></i>Visit Zint.io
            </a>            
          </div>
        </div>
      }

      <div class="dropdown">
        <a class="icon-nav-link dropdown-toggle" data-toggle="dropdown" role="button">
          <i class="fas fa-circle-user"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-right">
          @if (appComponentRouter.url !== '/request_new_activation_token') {
              <a class="dropdown-item border-bottom pb-2" role="button" href="/account">
                <i class="fal fa-cog fa-fw mr-2"></i>
                Manage Account
              </a>
              <a *ngIf="isSuperUser" class="dropdown-item border-bottom py-2" role="button" href="/manage-team">
                <i class="fal fa-users fa-fw mr-2"></i>
                Manage Team
              </a>
              <a href="/manage-territories" *ngIf="isSuperUser && hasTerritoryAssignmentAccess" class="dropdown-item border-bottom py-2" role="button">
                <i class="fal fa-map fa-fw mr-2"></i>
                Manage Territories
              </a>
          }
            <a class="dropdown-item font-weight-bold pt-2" href="/logout" title="Log out"><i
                class="far fa-sign-out-alt fa-fw mr-2"></i>&nbsp;Logout</a>
        </div>
      </div>
     </div>
  </div>
  }
  @else {
    <div class="d-flex">
      <a class="nav-link text-grey-400" href="/login">Login</a>
      <a class="nav-link text-grey-400" href="/register">Register</a>
    </div>
  }

</nav>

<authentication></authentication>
<push-messages></push-messages>
<plan-features-enabled></plan-features-enabled>
<router-outlet></router-outlet>

<div class="fixed-bottom" *ngIf="showCookieBanner">
  <div class="row">
    <div class="col-md-3 col-lg-2">
      <div style="
          background-color: #000a36;
          color: white;
          padding: 10px;
          margin-bottom: 5px;
          margin-right: 5px;
          margin-left: 5px;
          border-radius: 2px;
        ">
        We use cookies to deliver essential services and for performance
        monitoring.
        <button class="btn-sm basic-button-base button-light" (click)="acceptCookies()">
          Okay
        </button>
      </div>
    </div>
  </div>
</div>
