import {
  Component,
  effect,
  ElementRef,
  HostListener,
  NgZone,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { CompanyPageSectionEnums } from '../../classes/company';
import { baseUrl, publicMethods } from '../../globals';
import { CompanyService } from '../../services/company.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { combineLatest } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';
import { ActivatedRoute } from '@angular/router';
import {
  IFeaturePermissions,
  ProfileOpenSearchType,
} from '../../classes/types';
import { PropensityComponentOpacityPipe } from '../../pipes/propensity-component-opacity';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../../services/message.service';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { KeyValue } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ZintGrowSubscriptionsService } from '../../services/zint-grow-subscriptions.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { TaggingService } from '../../services/tagging.service';
declare var $: any;
declare var zE: any; // Zendesk SDK

@Component({
  selector: 'company-profile-page',
  templateUrl: './company-profile-page.component.html',
  styleUrl: './company-profile-page.component.css',
  providers: [PropensityComponentOpacityPipe],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyProfilePageComponent {
  loadingStatus: Record<string, any> = {};
  loadingError: string = '';
  isSmallScreen: boolean = false;
  company: Record<string, any> = {};
  companyNumber: string = '';
  userCustomProperties: Record<string, any> = {};
  accountDetails: Partial<{
    isSuperUser: boolean;
    email: string;
    userId: number;
  }> = {};
  teamMembers: Record<string, any>[] = [];
  featurePermissions: Partial<IFeaturePermissions> = {};
  permissionsCheckMap: Partial<Record<CompanyPageSectionEnums, boolean>> = {};
  toggleAllNavSections: boolean = false;
  allNavSections: string[] = [];
  navSectionPolledCount: Partial<
    Record<CompanyPageSectionEnums, number | string>
  > = {};
  isReordering: boolean = false;
  tempReOrderingList: any[];
  isAllExpansionPanelsOpen: boolean = false;
  expandedSections: Record<string, boolean> = {};
  zintGrowColumns: Record<string, any>[] = [];
  zintGrowColumnsFollowing: Record<string, any>[] = [];
  hasColumnEditAccess: boolean = false;

  salesforceLinks: Record<string, any>[] = [];

  fullWebsiteSearchTerm: string = '';
  accountsSearchText: string = '';
  accountsDateSelected: string = 'any';
  yearAccountsMadeUpToDates: string[] = [];

  fullWebsiteResults: Record<string, any>[] = [];
  accountsResults: Record<string, any> = {};
  customAttrsUpdated: boolean = false;

  companyAIPitch: string = '';
  hasCalledCustomInsights = false; // used to prevent continuous calls to endpoint
  customInsights: Record<string, any>[] = [];

  selfPublishedCompanyNewsSearchText: string = '';
  thirdPartyNewsSearchText: string = '';

  openSearchModalTitle: string = '';
  currentOpenSearchType: string = '';

  availableFinancialTerms: string[] = [];
  availableAssetsData: Record<string, any> = {};
  sparkLineRowData: Record<string, any> = {};

  jobsListToRender: Record<string, any>[] = [];
  jobSearchTerm: string = '';

  impExpModalType: 'imports' | 'exports' = 'imports';

  companyShareholders: Record<string, any>[] = [];
  companiesShareholderIn: Record<string, any>[] = [];

  renderStructureGraphs: boolean = false;
  showClusterNotStructure: boolean = false;

  impExpChartColorScheme = {
    imports: {
      domain: ['#FBFBFB', 'skyblue', '#00A1E0'],
    },
    exports: {
      domain: ['#FBFBFB', '#D2C2E4', '#9D7AC4'],
    },
  };

  detailedImportsData: Record<string, any>[] = [];
  detailedExportsData: Record<string, any>[] = [];

  governmentContractsToRender: Record<string, any>[] = [];
  allGovernmentContracts: Record<string, any>[] = [];

  availableEnergyTerms: string[] = [];
  availableEmissionsTerms: string[] = [];

  supplierData: Record<string, any>[] = [];

  officersPSCsData: Record<string, any>[] = [];

  llMEnergyEmissionsData: Record<string, any> = {};

  @ViewChildren(MatExpansionPanel, { read: ElementRef })
  panelElements: QueryList<ElementRef>;
  @ViewChildren(MatExpansionPanel)
  expansionPanels: QueryList<MatExpansionPanel>;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public pubMethods: publicMethods,
    private companyService: CompanyService,
    private communicationService: CommunicationService,
    private messageService: MessageService,
    private propensityOpacityPipe: PropensityComponentOpacityPipe,
    private zone: NgZone,
    private titleService: Title,
    private zintGrowSubscriptionService: ZintGrowSubscriptionsService,
    private dataStore: DataSharingService,
    private taggingService: TaggingService
  ) {
    this.loadingStatus = this.companyService.isLoading;

    effect(() => {
      if (this.dataStore.companyTagsUpdated() === true) {
        this.getCompanyUserDetails();
      }
    });
  }

  ngOnInit() {
    this.companyNumber = this.route.snapshot.paramMap.get('id');

    this.communicationService.getEmailMessage().subscribe(email => {
      this.accountDetails = { ...this.accountDetails, email };
    });
    this.communicationService.getIsSuperUserMessage().subscribe(check => {
      this.accountDetails = { ...this.accountDetails, isSuperUser: check };
    });
    this.communicationService.getUserAccountId().subscribe(userId => {
      this.accountDetails = { ...this.accountDetails, userId };
    });
    this.checkPermissions();
    this.getAllNavSections();
    this.getCompanyProfilePageData();
  }

  ngAfterViewInit() {
    this.checkIfSmallScreen();
  }

  loadTwitterScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfSmallScreen();
  }

  checkIfSmallScreen(): void {
    this.isSmallScreen = window.innerWidth <= 992;
  }

  toggleAccordion(): void {
    if (this.isAllExpansionPanelsOpen === false) {
      this.isAllExpansionPanelsOpen = true;
      localStorage.setItem(
        'profileExpandAll',
        JSON.stringify(this.isAllExpansionPanelsOpen)
      );
      this.accordion.openAll();
      this.snackbar.open(
        "Preference setting saved. Clicking on 'Collapse All' will remove this setting.",
        'Okay',
        {
          duration: 8000,
        }
      );
    } else {
      this.isAllExpansionPanelsOpen = false;
      localStorage.removeItem('profileExpandAll');
      this.accordion.closeAll();
    }
  }

  setExpandAll(): void {
    const expandAllLS = JSON.parse(localStorage.getItem('profileExpandAll'));

    if (expandAllLS) {
      this.isAllExpansionPanelsOpen = true;
      setTimeout(() => {
        // needs this delay for accordions to load async
        this.accordion.openAll();
      });
    }
  }

  findExpansionPanelById(sectionId: string): MatExpansionPanel {
    /* find index of the panel from the native elements since we only have direct access to html id from ElementRef
    Then using this index, return the right MatExpansionPanel.
    */
    const panelIndex = this.panelElements
      .toArray()
      .findIndex(el => el.nativeElement.getAttribute('id') === sectionId);

    if (panelIndex > -1) {
      return this.expansionPanels.toArray()[panelIndex];
    }
  }

  setSectionExpandedState(sectionId?: string): void {
    this.expandedSections = {
      ...this.expandedSections,
      [sectionId]: this.findExpansionPanelById(sectionId)?.expanded,
    };
  }

  scrollToSectionAndExpand(sectionId?: string): void {
    if (sectionId) {
      this.zone.runOutsideAngular(() => {
        this.performScrollAndExpand(sectionId);
      });
    } else {
      /* Subscribe to route.fragment if sectionId is not provided
       * so it keeps track of the anchor fragments to scroll
       */
      this.route.fragment.subscribe(fragment => {
        if (fragment) {
          this.zone.runOutsideAngular(() => {
            this.performScrollAndExpand(fragment);
          });
        }
      });
    }
  }

  private performScrollAndExpand(section: string): void {
    // just an abstraction to use within scrollToSection&Expand

    let frameAnimationRef: number;

    frameAnimationRef = requestAnimationFrame(() => {
      this.companyService.scrollToSelectorOnceExists(section);
      const sectionPanel = this.findExpansionPanelById(section);
      if (sectionPanel) {
        this.zone.run(() => {
          sectionPanel.expanded = true;
          cancelAnimationFrame(frameAnimationRef);
        });
      }
    });
  }

  checkPermissions(): void {
    const permissionObs$ = combineLatest([
      this.communicationService.getHasAIAccountsSummaryAccess(),
      this.communicationService.getHasAIPitchBuilderAccessMessage(),
      this.communicationService.getHasCrossSellAccessMessage(),
      this.communicationService.getHasSupplierDataAccessMessage(),
      this.communicationService.getHasPatentsAccessMessage(),
      this.communicationService.getHasEnergyAndEmissionsAccessMessage(),
      this.communicationService.getHasZintGrowAccessMessage(),
      this.communicationService.getHasAIResearchAssistantAccessMessage(),
      this.communicationService.getHasAICompanyAssistantAccessMessage(),
      this.communicationService.getHasCustomInsightsAccessMessage(),
      this.communicationService.getHasSalesforceV2AccessMessage(),
      this.communicationService.getHubspotAccess(),
      this.communicationService.getHasShareFilingsAccessMessage(),
      // Add more permissions as needed
    ]);

    /**
     * destructuring observable array here but we can also use the old
     * subscribe(data => {
     * hasAiAccountsSummaryAccess: data[0],
     * hasAiPitchBuilderAccess: data[1], ...}).
     * Either approach needs to maintain and follow the order from above combineLatest
     */

    permissionObs$.subscribe(
      ([
        hasAiAccountsSummaryAccess,
        hasAiPitchBuilderAccess,
        hasCrossSellAccess,
        hasSupplierDataAccess,
        hasPatentsAccess,
        hasEnergyAndEmissionsAccess,
        hasZintGrowAccess,
        hasAIResearchAssistantAccess,
        hasAICompanyAssistantAccess,
        hasCustomInsightsAccess,
        hasSalesforceV2Access,
        hasHubspotAccess,
        hasShareFilingsAccess,
      ]) => {
        this.featurePermissions = {
          ...this.featurePermissions,
          hasAiAccountsSummaryAccess,
          hasAiPitchBuilderAccess,
          hasCrossSellAccess,
          hasSupplierDataAccess,
          hasPatentsAccess,
          hasEnergyAndEmissionsAccess,
          hasZintGrowAccess,
          hasAIResearchAssistantAccess,
          hasAICompanyAssistantAccess,
          hasCustomInsightsAccess,
          hasSalesforceV2Access,
          hasHubspotAccess,
          hasShareFilingsAccess,
        };

        /**
         * the map below maps the nav and sections names to the features
         */
        this.permissionsCheckMap = {
          strategic_insights:
            this.featurePermissions.hasAiAccountsSummaryAccess,
          pitch_builder: this.featurePermissions.hasAiPitchBuilderAccess,
          cross_sell_opportunities: this.featurePermissions.hasCrossSellAccess,
          probable_suppliers: this.featurePermissions.hasSupplierDataAccess,
          patents_filed: this.featurePermissions.hasPatentsAccess,
          energy_and_emissions:
            this.featurePermissions.hasEnergyAndEmissionsAccess,
          ai_research_assistant:
            this.featurePermissions.hasAIResearchAssistantAccess,
          ai_company_assistant:
            this.featurePermissions.hasAICompanyAssistantAccess,
          custom_insights: this.featurePermissions.hasCustomInsightsAccess,
        };
      }
    );
  }

  callPermissionOnlyDataEndpoints(): void {
    this.getSupplierData();
    this.getCompaniesShareholderIn();
    this.getCompanyShareholders();
    this.getLLMEnergyEmissionsData();
  }

  getCompanyProfilePageData(): void {
    if (this.companyNumber) {
      this.companyService.getCompanyProfileData(this.companyNumber).subscribe(
        data => {
          if (data) {
            this.company = data.company;
            this.scrollToSectionAndExpand();
            this.getAvailableTableRowHeaders();
            this.processAssetsData();
            this.jobsListToRender = this.company.hiring_for;
            this.governmentContractsToRender =
              this.company.government_contracts_awarded;
            this.handleOfficersPSCsData();
            this.openSearchFromQueryParams();
            this.titleService.setTitle(
              'Zint - ' + this.company.company_overview?.name
            );
            this.dataStore.changeDataSourceCurrentCompanyIds([
              this.company?.company_id,
            ]);
            if (this.company.latest_news.twitter_handle) {
              this.loadTwitterScript();
            }
            this.callPermissionOnlyDataEndpoints();
            this.setExpandAll();
          }
        },
        error => {
          this.loadingStatus.loadingCompanyProfileData = false;
          this.loadingError = error?.error?.message || 'An error has occurred';
          setTimeout(() => {
            window.location.replace('/dashboard');
          }, 2200);
        }
      );
      this.getCompanyUserDetails();
    }
  }

  getCompanyUserDetails(): void {
    /**
     * This method populates custom properties in overview
     * */
    this.companyService
      .getCompanyUserDetails(this.companyNumber)
      .subscribe(data => {
        this.userCustomProperties = data;
        this.userCustomProperties['hasSomeData'] =
          !this.pubMethods.isEmptyObjectOrArray(data);
        this.updateCustomColumnsDetails();
      });
  }

  getStrategicAnalysis(): void {
    if (!this.permissionsCheckMap.strategic_insights) {
      this.messageService.show(
        'You do not have permission for this feature. Please ask your team superuser or contact customer success via chat.'
      );

      zE('messenger', 'open');
      return;
    }
    this.scrollToSectionAndExpand('strategic_insights_section');
  }

  getCompanyShareholders(): void {
    if (!this.featurePermissions.hasShareFilingsAccess) return;
    this.companyService
      .getCompanyShareholders(this.companyNumber)
      .subscribe(data => {
        this.companyShareholders = data;
      });
  }

  getCompaniesShareholderIn(): void {
    if (!this.featurePermissions.hasShareFilingsAccess) return;
    this.companyService
      .getCompaniesShareholderIn(this.companyNumber)
      .subscribe(data => {
        this.companiesShareholderIn = data;
      });
  }

  getLLMEnergyEmissionsData(): void {
    if (!this.featurePermissions.hasEnergyAndEmissionsAccess) return;
    this.companyService.getLLMEnergyEmissionsData(this.companyNumber).subscribe(
      data => {
        this.llMEnergyEmissionsData = data;
      },
      error => {
        this.llMEnergyEmissionsData = {};
      }
    );
  }

  refreshZintGrowColumns(): void {
    this.zintGrowColumns = this.userCustomProperties.custom_columns.filter(
      col => col.is_zint_grow_column === true
    );
    this.zintGrowColumnsFollowing =
      this.userCustomProperties.custom_columns.filter(
        col =>
          col.is_zint_grow_column === true &&
          col.value === this.accountDetails.email
      );
  }

  initFollowZintGrow(): void {
    const editableZGCols = this.zintGrowColumns.filter(col => col.can_edit);

    if (editableZGCols?.length === 1) {
      this.followCompanyInZintGrow(editableZGCols[0]);
    } else if (editableZGCols?.length > 1) {
      $('#followZintGrowModal').modal();
    }
  }

  followCompanyInZintGrow(zintGrowColumnAdding: Record<string, any>): void {
    this.zintGrowSubscriptionService
      .updateUserColumnCompany(
        zintGrowColumnAdding.id,
        this.company['company_id'],
        this.accountDetails.userId
      )
      .subscribe(
        data => {
          if (data.value === this.accountDetails.userId) {
            this.snackbar.open('Company added to Zint Grow!', 'X', {
              duration: 2200,
            });
            this.zintGrowColumnsFollowing.push(zintGrowColumnAdding);
            $('#followZintGrowModal').modal('hide');
          }
        },
        error => {
          if (error?.error?.hasOwnProperty('nonFieldErrors')) {
            this.messageService.show(
              'This column may already be associated with a different user in your team, try a different column'
            );
          }
        }
      );
  }

  unfollowCompanyInZintGrow(): void {
    if (this.zintGrowColumnsFollowing) {
      const zintGrowColumnRemoving = this.zintGrowColumnsFollowing[0];
      this.hasColumnEditAccess = zintGrowColumnRemoving.can_edit;
      if (!this.hasColumnEditAccess) {
        this.requestColumnEditAccessForZintGrow();
        return;
      }
      this.companyService
        .updateCustomOptionColumn(
          zintGrowColumnRemoving.id,
          this.company.company_id,
          ''
        )
        .subscribe(data => {
          this.zintGrowColumnsFollowing = this.zintGrowColumnsFollowing.filter(
            col => col.id !== zintGrowColumnRemoving.id
          );
          this.snackbar.open('Company removed from Zint Grow', 'X', {
            duration: 2200,
          });
        });
    }
  }

  requestColumnEditAccessForZintGrow(): void {
    this.messageService.show(
      'You do not have permission to do this. Please ask your team superuser or contact customer success via chat.'
    );

    zE('messenger', 'open');
  }

  getAllNavSections(): void {
    this.companyService.getAllCompanySections().subscribe(data => {
      if (data) {
        this.allNavSections = data['sections_order'];
      }
    });
  }

  getSectionIcon(section: string): string {
    return this.pubMethods.renderNavSectionIcon(section);
  }

  hasAnyStrategicInsights(insightObj: Record<string, any>): boolean {
    const meaningfulInsightsKeys = [
      'bullets',
      'business_model',
      'future_events',
      'pains_challenges',
      'strategic_goals',
    ];

    return meaningfulInsightsKeys.some(key =>
      this.pubMethods.hasData(insightObj[key])
    );
  }

  getSectionPolledCount(paramsObj: {
    countObj: Object | Array<any> | string;
    countNestedObj?: boolean;
    isAssetData?: boolean;
    isImpExpData?: boolean;
    isEnergyData?: boolean;
    isSustainabilityData?: boolean;
    isStrategicInsights?: boolean;
  }): number {
    const {
      countObj,
      countNestedObj = true,
      isStrategicInsights = false,
      isAssetData = false,
      isImpExpData = false,
      isEnergyData = false,
      isSustainabilityData = false,
    } = paramsObj;
    let count = 0;
    if (typeof countObj === 'string') return (count += countObj.length ? 1 : 0);

    if (Array.isArray(countObj)) return (count += countObj.length);

    if (typeof countObj === 'object' && countObj !== null) {
      if (isStrategicInsights) {
        // count the insights objects with only meaningful data
        let insightsCount = 0;
        Object.values(countObj).forEach((arr: Array<any>) => {
          arr.forEach(obj => {
            insightsCount += this.hasAnyStrategicInsights(obj) ? 1 : 0;
          });
        });
        return insightsCount;
      }

      if (isImpExpData) {
        // tally up the values
        let impExpCount = 0;
        Object.values(countObj).forEach((arr: Array<any>) =>
          arr.forEach(obj => {
            impExpCount += Number(obj.value);
          })
        );
        return impExpCount;
      }

      if (isEnergyData) {
        // count the unique years
        if (!countObj['energy'] && !countObj['emissions']) return 0;

        const uniqueYearsOnly = new Set(
          countObj['energy']
            .concat(countObj['emissions'])
            .map(obj => obj.made_up_to)
        );
        return uniqueYearsOnly.size;
      }

      if (isSustainabilityData) {
        // count the unique column year
        if (!countObj['energy'] && !countObj['emissions']) return 0;

        let dataCount = new Set(
          countObj['energy']['columns'].concat(countObj['emissions']['columns'])
        );

        return dataCount.size;
      }

      if (countNestedObj) {
        Object.values(countObj).forEach(val => {
          if (val) {
            count += this.getSectionPolledCount({
              countObj: val,
              countNestedObj: !isAssetData, // count the nestedObjs unless it is assetData
            });
          }
        });
      } else {
        count += this.pubMethods.hasData(countObj) ? 1 : 0;
      }
    }

    return count;
  }

  getSectionOrder(section: string): number {
    return this.allNavSections.indexOf(section);
  }

  showAllNavSections(): void {
    this.toggleAllNavSections = !this.toggleAllNavSections;
    this.isReordering = false;
  }

  handleNavStyle(sectionName: string): string {
    const lowerCaseName = sectionName.toLowerCase();

    const anomaliesMap: Partial<Record<CompanyPageSectionEnums, string>> = {
      events_attending: 'events',
    };

    const defaultSections = ['company_overview', 'corporate_structure'];

    const simpleStringsForLengthCheck: string[] = Object.values(
      CompanyPageSectionEnums
    ).filter(
      key =>
        !Object.keys(this.permissionsCheckMap).includes(key) &&
        !Object.keys(anomaliesMap).includes(key) &&
        key !== CompanyPageSectionEnums.Sustainability
    );

    let lengthCheckStr =
      simpleStringsForLengthCheck.find(str => str === lowerCaseName) ||
      anomaliesMap[lowerCaseName];

    if (defaultSections.includes(lowerCaseName)) return '';

    if (lengthCheckStr) {
      return this.handleLengthCheckStr(lengthCheckStr);
    }

    if (Object.keys(this.permissionsCheckMap).includes(lowerCaseName)) {
      return this.handlePermissionNavSection(lowerCaseName);
    }

    if (lowerCaseName === 'sustainability') {
      return this.handlePermissionNavSection(lowerCaseName);
    }

    return '';
  }

  handleLengthCheckStr(lengthCheckStr: string): string {
    const counterKey =
      lengthCheckStr === 'events' ? 'events_attending' : lengthCheckStr;

    if (lengthCheckStr === 'asset_breakdown') {
      this.navSectionPolledCount[counterKey] = this.getSectionPolledCount({
        countObj: this.company[lengthCheckStr],
        isAssetData: true,
      });
    } else if (
      lengthCheckStr === 'legal_entity_identifier' ||
      lengthCheckStr === 'website'
    ) {
      this.navSectionPolledCount[counterKey] = this.getSectionPolledCount({
        countObj: this.company[lengthCheckStr],
        countNestedObj: false,
      });
    } else if (lengthCheckStr === 'imports_&_exports') {
      this.navSectionPolledCount[counterKey] = this.getSectionPolledCount({
        countObj: this.company[lengthCheckStr],
        isImpExpData: true,
      });
    } else if (lengthCheckStr === 'government_contracts_awarded') {
      this.navSectionPolledCount[counterKey] =
        this.governmentContractsToRender.length >= 20
          ? `~${this.governmentContractsToRender.length}`
          : this.governmentContractsToRender.length;
    } else if (lengthCheckStr === 'share_information') {
      this.navSectionPolledCount['share_information'] =
        this.company?.share_allotments?.length +
        this.companiesShareholderIn?.length +
        this.companyShareholders?.length;

      const noNavForShareInfo =
        this.company?.share_allotments?.length < 1 &&
        this.companiesShareholderIn?.length < 1 &&
        this.companyShareholders?.length < 1;
      return noNavForShareInfo ? 'nav-no-data' : '';
    } else {
      this.navSectionPolledCount[counterKey] = this.getSectionPolledCount({
        countObj: this.company[lengthCheckStr],
      });
    }

    return this.pubMethods.hasData(this.company[lengthCheckStr])
      ? ''
      : 'nav-no-data';
  }

  handlePermissionNavSection(sectionName: string): string {
    const permissionCheckOnly = [
      'pitch_builder',
      'ai_research_assistant',
      'ai_company_assistant',
      'custom_insights',
      'cross_sell_opportunities',
    ];

    if (permissionCheckOnly.includes(sectionName)) {
      return this.permissionsCheckMap[sectionName] ? '' : 'nav-no-permission';
    }

    if (sectionName === 'strategic_insights') {
      if (this.permissionsCheckMap.strategic_insights) {
        this.navSectionPolledCount['strategic_insights'] =
          this.getSectionPolledCount({
            countObj: this.company['strategic_insights'],
            isStrategicInsights: true,
          });

        return this.pubMethods.hasData(this.company['strategic_insights'])
          ? ''
          : 'nav-no-data';
      } else return 'nav-no-permissions';
    }

    if (sectionName === 'patents_filed') {
      if (this.permissionsCheckMap.patents_filed) {
        this.navSectionPolledCount['patents_filed'] =
          this.getSectionPolledCount({
            countObj: this.company['patents_filed'],
          });

        return this.pubMethods.hasData(this.company['patents_filed'])
          ? ''
          : 'nav-no-data';
      } else return 'nav-no-permissions';
    }

    if (sectionName === 'probable_suppliers') {
      if (this.permissionsCheckMap.probable_suppliers) {
        this.navSectionPolledCount['probable_suppliers'] =
          this.supplierData?.length - 1;

        return this.supplierData?.length > 1 ? '' : 'nav-no-data';
      } else {
        return 'nav-no-permissions';
      }
    }

    if (sectionName === 'energy_and_emissions') {
      if (this.permissionsCheckMap.energy_and_emissions) {
        this.navSectionPolledCount['energy_and_emissions'] =
          this.getSectionPolledCount({
            countObj: this.company['energy_and_emissions'],
            isEnergyData: true,
            countNestedObj: false,
          });

        return this.pubMethods.hasData(this.company['energy_and_emissions'])
          ? ''
          : 'nav-no-data';
      } else {
        return 'nav-no-permissions';
      }
    }

    if (sectionName === 'sustainability') {
      if (this.permissionsCheckMap.energy_and_emissions) {
        this.navSectionPolledCount['sustainability'] =
          this.getSectionPolledCount({
            countObj: this.llMEnergyEmissionsData,
            isSustainabilityData: true,
          });

        return this.pubMethods.hasData(this.llMEnergyEmissionsData)
          ? ''
          : 'nav-no-data';
      } else {
        return 'nav-no-permissions';
      }
    }
  }

  showPremiumIcon(navName: string): boolean {
    /* returns true only if NEEDS permission but LACKS it */

    const lowerCaseNav = navName.toLowerCase();

    if (!this.permissionsCheckMap.hasOwnProperty(lowerCaseNav)) return false;

    if (this.permissionsCheckMap.hasOwnProperty(lowerCaseNav)) {
      // return the flipped boolean
      return !this.permissionsCheckMap[lowerCaseNav];
    }
  }

  visitZintPage(nav: string): void {
    const zintPageMap: Partial<Record<CompanyPageSectionEnums, string>> = {
      pitch_builder: 'https://www.zint.io/platform-features/pitch-builder',
      strategic_insights:
        'https://www.zint.io/platform-features/zint-strategic-summary',
      custom_insights:
        'https://www.zint.io/platform-features/zint-research-assistant',
      ai_research_assistant:
        'https://www.zint.io/platform-features/zint-research-assistant',
      energy_and_emissions: 'https://www.zint.io/use-cases/energy',
      sustainability: 'https://www.zint.io/use-cases/energy',
      cross_sell_opportunities:
        'https://www.zint.io/platform-features/cross-sell-tools',
      patents_filed: 'https://www.zint.io/platform-features/pipeline-builder',
      probable_suppliers: 'https://www.zint.io',
    };

    window.open(zintPageMap[nav], '_blank');
  }

  reOrderNavSections(): void {
    let profilePageSelf = this;
    this.isReordering = true;
    this.toggleAllNavSections = true;
    const navSectionsWithoutOverview = this.allNavSections.filter(
      nav => nav !== 'company_overview'
    );
    this.tempReOrderingList = navSectionsWithoutOverview;
    $('#reOrderSectionsModal').modal();

    $('#reOrderSectionsModal').on('hidden.bs.modal', function () {
      profilePageSelf.isReordering = false;
    });
  }

  saveReOrderedSections(): void {
    if (this.isReordering) {
      this.isReordering = false;
      this.allNavSections = ['company_overview', ...this.tempReOrderingList];
      $('#reOrderSectionsModal').modal('hide');

      this.companyService
        .saveUserPreferredNavOrder(this.allNavSections)
        .subscribe(
          () => {},
          error => {
            this.messageService.show(
              'Sorry there was an error saving your reorder request'
            );
          }
        );
    }
  }

  dragSort(event: CdkDragDrop<any>): void {
    moveItemInArray(
      this.tempReOrderingList,
      event.previousIndex,
      event.currentIndex
    );
  }

  collapseAllNavs(): void {
    if (!this.toggleAllNavSections) return;
    setTimeout(() => {
      this.toggleAllNavSections = false;
    }, 220);
  }

  getTagsTotal(): number {
    const {
      tags = [],
      team_member_tags = [],
      organisation_tags = [],
    } = this.userCustomProperties;

    const filtered = [
      ...tags,
      ...team_member_tags,
      ...organisation_tags,
    ].filter(tag => !tag.is_propensity);

    return filtered.length;
  }

  removeTag(tagId): void {
    this.taggingService
      .tagOrUntagCompanies(tagId, [this.company['company_id']], null, false)
      .subscribe(
        data => {
          if (data && data.total === 1) {
            this.getCompanyUserDetails();
          } else {
            this.messageService.show('Could not remove tag.');
          }
        },
        error => {
          this.messageService.show('Could not remove tag.');
        }
      );
  }

  updateCustomColumnsDetails(): void {
    this.teamMembers = this.userCustomProperties?.team_members;
    if (this.userCustomProperties.custom_columns) {
      this.sortCustomProperties(this.userCustomProperties.custom_columns);

      this.userCustomProperties.custom_columns.forEach(col => {
        if (col.column_type === 'User') {
          col['options'] = this.teamMembers;
        }
      });
      this.refreshZintGrowColumns();
    }
    this.salesforceLinks = this.userCustomProperties?.salesforce_links;

    //reset the signal back to default state
    this.dataStore.companyTagsUpdated.update(flag => (flag = false));
  }

  sortCustomProperties(customColumns: Record<string, any>[]): void {
    // Sort propensity columns to appear first
    customColumns.sort((a, b) => {
      if (a.is_propensity && !b.is_propensity) {
        return -1; // a first
      } else if (!a.is_propensity && b.is_propensity) {
        return 1; // a not first
      } else {
        return 0; // original order
      }
    });
  }

  getPropensityComponentStyle(
    scoreTag: Record<string, any>
  ): Record<string, any> {
    // prettier-ignore
    const styleObj = {
      'background-color':
        scoreTag?.propensity_component_weighting >= 0
          ? '#' + scoreTag?.colour
          : 'var(--zint-red-600)',
      opacity: this.propensityOpacityPipe.transform(
        scoreTag?.propensity_component_weighting
      ),
      cursor: this.showPropensitySearchLink(scoreTag) ? 'pointer' : 'auto',
    };

    return styleObj;
  }

  showPropensitySearchLink(propensityTag): boolean {
    const params = propensityTag.propensity_component_params;
    if (
      params.homepageSearchTerm ||
      params.fullWebsiteSearchTerm ||
      params.accountsSearchTerm ||
      params.newsContaining
    ) {
      return true;
    } else {
      return false;
    }
  }

  openSearchFromQueryParams() {
    const params = this.route.snapshot.queryParams;

    if (params.accountsSearch) {
      if (params.accountsDate) {
        this.accountsDateSelected = params.accountsDate;
      }
      this.accountsSearchText = params.accountsSearch;

      this.scrollAndModalSearch('financials_section', 'accounts-search');
    } else if (params.fullWebsiteSearch) {
      this.fullWebsiteSearchTerm = params.fullWebsiteSearch;

      this.scrollAndModalSearch('website_section', 'website-search');
    } else if (params.companyNewsSearch) {
      if (
        !this.pubMethods.isEmptyObjectOrArray(this.company?.latest_news?.news)
      ) {
        this.scrollToSectionAndExpand('latest_news_section');
        this.thirdPartyNewsSearchText = params.companyNewsSearch;
      }
    } else if (params.selfPublishedCompanyNewsSearch) {
      if (
        !this.pubMethods.isEmptyObjectOrArray(
          this.company?.latest_news?.self_published_news
        )
      ) {
        this.scrollToSectionAndExpand('latest_news_section');
        this.selfPublishedCompanyNewsSearchText =
          params.selfPublishedCompanyNewsSearch;
      }
    }
  }

  openSearchPreview(propensityTag): void {
    const params = propensityTag.propensity_component_params;

    if (params.homepageSearchTerm || params.fullWebsiteSearchTerm) {
      if (params.homepageSearchTerm) {
        this.fullWebsiteSearchTerm = params.homepageSearchTerm;
      }
      if (params.fullWebsiteSearchTerm) {
        this.fullWebsiteSearchTerm = params.fullWebsiteSearchTerm;
      }

      this.scrollAndModalSearch('website_section', 'website-search');
    }

    if (params.accountsSearchTerm) {
      this.accountsSearchText = params.accountsSearchTerm;

      this.scrollAndModalSearch('financials_section', 'accounts-search');
    }

    if (params.newsContaining) {
      this.scrollToSectionAndExpand('latest_news_section');
      if (params.newsSource === 'selfPublished') {
        this.selfPublishedCompanyNewsSearchText = params.newsContaining;
      } else {
        this.thirdPartyNewsSearchText = params.newsContaining;
      }
    }
  }

  private scrollAndModalSearch(
    sectionId: string,
    searchType: ProfileOpenSearchType
  ) {
    this.scrollToSectionAndExpand(sectionId);

    if (searchType === 'accounts-search') {
      this.accountsSearch();
    } else if (searchType === 'website-search') {
      this.fullWebsiteSearch();
    }

    setTimeout(() => {
      this.openSearchModal(searchType);
    }, 500);
  }

  fullWebsiteSearch(searchTerm?: string): void {
    if (searchTerm) {
      this.fullWebsiteSearchTerm = searchTerm;
    }

    if (!this.fullWebsiteSearchTerm.trim().length) return;

    this.companyService
      .runFullWebsiteSearch(this.companyNumber, this.fullWebsiteSearchTerm)
      .subscribe(data => (this.fullWebsiteResults = data.matching_webpages));
  }

  accountsSearch(searchObj?: Record<string, any>): void {
    if (searchObj) {
      this.accountsSearchText = searchObj.searchTerm;
      this.accountsDateSelected = searchObj.selectedDate;
    }

    if (!this.accountsSearchText.trim().length) return;

    this.companyService
      .searchCompanyAccounts(
        this.companyNumber,
        this.accountsSearchText,
        this.accountsDateSelected
      )
      .subscribe(data => {
        this.accountsResults = data;
        this.accountsResults['accounts_results'].forEach(
          (account: Record<string, any>) => {
            account.processedPageNumber =
              this.processAccountsResultPageNumber(account);
          }
        );
      });
  }

  getYearAccountsMadeUpToDates(): void {
    this.yearAccountsMadeUpToDates = this.company.financials.accounts?.map(
      accountObj => accountObj.made_up_to
    );
  }

  processAccountsResultPageNumber(
    accountObj: Record<string, any>
  ): string | number {
    if (!accountObj) return '';
    const { accounts_type, page_number } = accountObj;

    if (!accounts_type) return page_number;

    if (accounts_type === 'ocr') {
      const pageNumberPlusOne = Number(page_number) + 1;
      return pageNumberPlusOne;
    } else {
      return page_number;
    }
  }

  showUpdatedCustomAttrsMessage(): void {
    this.customAttrsUpdated = true;
    setTimeout(() => {
      this.customAttrsUpdated = false;
    }, 1500);
  }

  openInsightsModal(): void {
    $('#AiInsightsModal').modal();
  }

  generateAiPitch(): void {
    this.companyService.validateAIPitchBuilderSettings().subscribe(data => {
      if (data.settings_configured === true) {
        if (data.counter === 0) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'You do not have any AI credits on your account. Please contact customer success to add more.'
          );
          zE('messenger', 'open');
        } else {
          this.getAiPitch();
        }
      } else {
        if (this.accountDetails.isSuperUser) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'We were not able to auto-generate your AI configuration. Please navigate <a href="/pitch-builder">here</a> to configure.'
          );
        } else {
          this.pubMethods.showInfoMessage(
            'Please ask your superuser to configure your AI settings or contact customer success through chat for assistance.'
          );
        }
      }
    });
  }

  getAiPitch() {
    this.loadingStatus['loadingAIPitch'] = true;
    const source = new EventSource(
      baseUrl + '/get_ai_pitch/' + this.companyNumber,
      { withCredentials: true }
    );
    source.addEventListener('message', message => {
      if (message.data) {
        const data = JSON.parse(message.data);
        if (data.content) {
          this.companyAIPitch += data.content;
        }
      } else {
        this.loadingStatus['loadingAIPitch'] = false;
        source.close();
      }
    });

    source.addEventListener('error', e => {
      this.loadingStatus['loadingAIPitch'] = false;

      source.close();
    });
  }

  getCustomInsights(): void {
    // If it is a list then this will not hit the endpoint again
    if (!this.hasCalledCustomInsights) {
      this.companyService
        .getExistingCustomInsights(this.company.company_id)
        .subscribe(data => {
          this.customInsights = data;
          this.hasCalledCustomInsights = true;
        });
    }
  }

  openCustomInsightsModal(): void {
    $('#generateCustomInsightsModal').modal('show');
  }

  openSearchModal(openSearchType: ProfileOpenSearchType): void {
    if (openSearchType === 'website-search') {
      this.openSearchModalTitle = 'Search Website';
      this.currentOpenSearchType = 'website-search';
    } else if (openSearchType === 'accounts-search') {
      this.getYearAccountsMadeUpToDates();
      this.openSearchModalTitle = 'Search Accounts';
      this.currentOpenSearchType = 'accounts-search';
    }

    // Open the modal
    const modalElement = document.getElementById('openSearchModal');
    if (modalElement) {
      $(modalElement).modal('show');
    }
  }

  getAvailableTableRowHeaders(): void {
    /**
      Take all non-null keys of every financial, energy or emissions objects and remove dupes.
      Then remove non row headers e.g submission_details for accounts and low_energy for energy etc.
     */

    const nonLineItems = [
      'url_to_pdf_file',
      'url_to_xbrl_file',
      'submission_details',
      'has_been_indexed',
      'id',
      'made_up_to',
      'software_type',
      'average_number_employees_during_period',
    ];

    const nonEnergyItems = ['low_energy', 'made_up_to'];

    this.availableFinancialTerms = Array.from(
      new Set(
        this.company.financials.accounts?.flatMap(yearAccObj =>
          Object.keys(yearAccObj).filter(
            key => !nonLineItems.includes(key) && yearAccObj[key] !== null
          )
        )
      )
    );

    if (this.availableFinancialTerms.length) {
      this.getSparkLineRowData();
    }

    if (this.company.energy_and_emissions?.energy?.length) {
      this.availableEnergyTerms = Array.from(
        new Set(
          this.company.energy_and_emissions.energy.flatMap(energyObj =>
            Object.keys(energyObj).filter(
              key => !nonEnergyItems.includes(key) && energyObj[key] !== null
            )
          )
        )
      );
    }

    if (this.company.energy_and_emissions?.emissions?.length) {
      this.availableEmissionsTerms = Array.from(
        new Set(
          this.company.energy_and_emissions.emissions.flatMap(emissionsObj =>
            Object.keys(emissionsObj).filter(
              key => !nonEnergyItems.includes(key) && emissionsObj[key] !== null
            )
          )
        )
      );
    }
  }

  isDate(val: string): boolean {
    if (!val) return;
    return val.toString().indexOf('-') >= 1;
  }

  processAssetsData(): void {
    /**
     * Loop through the asset_breakdown obj to collect keys for both tangible and intangible assets.
     * Based on the length of these keys arrays, return whether "both" or only "tangible", or only "intangible" assets are available.
     * The processed data is saved to availableAssetsData which is then used to dynamically populate the assets table header columns.
     */

    if (this.pubMethods.isEmptyObjectOrArray(this.company.asset_breakdown))
      return;

    let tangibleKeys = [];
    let intangibleKeys = [];

    Object.values(this.company.asset_breakdown).forEach(assetYearObj => {
      if (assetYearObj['tangible']) {
        Object.keys(assetYearObj['tangible']).forEach(key =>
          tangibleKeys.push(key)
        );
      }
      if (assetYearObj['intangible']) {
        Object.keys(assetYearObj['intangible']).forEach(key =>
          intangibleKeys.push(key)
        );
      }
    });

    let availableAssetType = '';

    if (!!tangibleKeys?.length && !!intangibleKeys?.length) {
      availableAssetType = 'both';
    } else {
      availableAssetType = tangibleKeys.length ? 'tangible' : 'intangible';
    }
    this.availableAssetsData = {
      availableAssetType,
      tangible: Array.from(new Set(tangibleKeys)),
      intangible: Array.from(new Set(intangibleKeys)),
    };
  }

  performJobSearch(searchTerm?: string): void {
    if (!searchTerm.trim().length) {
      this.resetSearch('jobSearch');
      return;
    }

    this.jobSearchTerm = searchTerm;

    this.companyService
      .searchCompanyJobs(this.companyNumber, this.jobSearchTerm)
      .subscribe(data => {
        if (data) {
          this.jobsListToRender = data.matching_jobs?.length
            ? data.matching_jobs
            : [{ summary: 'No jobs found' }];
        }
      });
  }

  resetSearch(option?: string): void {
    if (option === 'jobSearch') {
      this.jobSearchTerm = '';
      this.jobsListToRender = this.company.hiring_for;
    }
  }

  confirmDownloadAllGovernmentContracts(): void {
    if (!this.allGovernmentContracts.length) {
      this.getAllGovernmentContracts();
    }

    $('#confirmDownloadModal').modal();
  }

  getAllGovernmentContracts(showAll?: boolean): void {
    this.companyService
      .getAllGovernmentContracts(this.companyNumber)
      .subscribe(data => {
        if (data) {
          this.allGovernmentContracts = data;

          if (showAll) {
            this.governmentContractsToRender = this.allGovernmentContracts;
          }
        }
      });
  }

  downloadAllGovtContracts(): void {
    window.open(
      baseUrl +
        '/download_government_contracts?companyNumber=' +
        this.companyNumber
    );
  }

  hasLowEnergyUse(energyData: Record<string, any>[]): boolean {
    return energyData.some(obj => obj.low_energy === true);
  }

  getSupplierData() {
    if (!this.featurePermissions.hasSupplierDataAccess) return;
    const supplierDataPageNumber = 1;
    this.companyService
      .getSupplierGraph(this.companyNumber, supplierDataPageNumber)
      .subscribe(data => {
        if (data) {
          this.supplierData = data.company_mentions_graph?.nodes;
        }
      });
  }

  handleOfficersPSCsData(): void {
    /**merge two arrays together */
    this.officersPSCsData = [
      ...this.company.corporate_officers?.officers,
      ...this.company.corporate_officers?.pscs,
    ];
  }

  getCompanyDetailedImportsExportsData(): void {
    this.companyService
      .getCompanyDetailedImportsExports(this.companyNumber)
      .subscribe(data => {
        this.detailedExportsData = data['detailed_exports'];
        this.detailedImportsData = data['detailed_imports'];
      });
  }

  getSparkLineRowData() {
    this.availableFinancialTerms
      .filter(term => term !== 'submitted')
      .forEach(term => {
        const seriesData = this.company.financials?.accounts?.map(
          yearAccObj => {
            if (!yearAccObj.hasOwnProperty(term)) {
              return null;
            }
            return yearAccObj[term];
          }
        );
        this.sparkLineRowData[term] = seriesData.reverse();
      });
  }

  chunkForCarousel(arr: Record<string, any>[], chunkSize = 3): any[][] {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  getFirstObjectKey(obj: Record<string, any>): string {
    return Object.keys(obj)[0];
  }

  originalOrder(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    /*
    keep the original order of the object/dict with keyvalue pipe -
    negative values reverses order.
    https://v17.angular.io/api/common/KeyValuePipe#parameters
    */
    return 0;
  }
}
