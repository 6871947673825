import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../services/data-sharing.service';
import { CommunicationService } from '../../services/communication.service';
import { AccountService } from '../../services/account.service';
import { TaggingService } from '../../services/tagging.service';
import { publicMethods } from '../../globals';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
declare var $: any;

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
  emailOnAccount: string;
  isSuperUser: boolean;
  isParentOrgSuperUser: boolean;

  planName: string;
  organisationSuperuserEmail: string;
  periodType: string;
  downloadLimit: number;
  downloadsThisPeriod: number;
  tagLimit: number;
  currentTagCount: number;
  periodStarted: any;
  userTags: Array<any>;

  // TO DELETE
  tagViewableUserToDelete: any;
  viewableTagToDeleteOn: any;
  tagEditableUserToDelete: any;
  editableTagToDeleteOn: any;
  // ^^^ TO DELETE

  privateSavedSearches: Array<any>;
  sharedSavedSearches: Array<any>;
  searchHistory: Array<any>;
  checkboxHasTrackingEnabled: boolean;
  downloadDateTrackingEnabled: boolean;

  userDownloadFormat: Array<any>;
  unusedCSVDownloadHeaders: Array<any>;

  shouldLoadResultsView = false;

  customColumns: Array<any>;
  newColumnName: string;
  newColumnType: string;
  newColumnIsLockable: boolean = false;
  newColumnLockDays: number;
  newColumnMaximumDuplicateEntries: number;
  columnTypeOptions: Array<any>;
  newColumnOptionsEnabled: boolean = false;
  listOfOptionNumbers: Array<number> = [];
  newColumnOptions: Array<any> = [];
  customColumnIdToDelete: number;
  customColumnNameToDelete: string;

  columnPrivacy: string = 'private';
  organisations: Array<any> = [];
  organisationUsers: Array<any> = [];
  organisationTeams: Array<any> = [];
  viewableUserToDelete: any;
  viewableUserColumnToDeleteOn: any;
  viewableTeamToDelete: any;
  viewableTeamColumnToDeleteOn: any;
  editableUserToDelete: any;
  editableUserColumnToDeleteOn: any;
  editableTeamToDelete: any;
  editableTeamColumnToDeleteOn: any;

  constructor(
    private data: DataSharingService,
    private communicationService: CommunicationService,
    private accountService: AccountService,
    private taggingService: TaggingService,
    private pubMethods: publicMethods
  ) {}

  isLoading = this.accountService.isLoading;

  ngOnInit() {
    document.title = 'Account';
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService.getEmailMessage().subscribe(accountEmail => {
      this.emailOnAccount = accountEmail;
    });
    this.communicationService
      .getIsParentOrgSuperUserMessage()
      .subscribe(isParentOrgSuperUser => {
        this.isParentOrgSuperUser = isParentOrgSuperUser;
      });
    this.getUserAccountDetails();
    this.getUserTagDetails();
    let accountSelf = this;
    $('#tagModal').on('hide.bs.modal', function (e) {
      accountSelf.getUserTagDetails();
    });
  }

  trackingPreferencesUpdated(data) {
    if (data.success) {
      this.pubMethods.showInfoMessage('Your preferences have been updated.');
    }
  }

  updateTrackingPreferences() {
    this.accountService
      .saveAccountTrackingPreferences(this.checkboxHasTrackingEnabled)
      .subscribe(data => this.trackingPreferencesUpdated(data));
  }

  updateDownloadDateTracking() {
    this.accountService
      .toggleDownloadDateTracking(this.downloadDateTrackingEnabled)
      .subscribe(data => this.trackingPreferencesUpdated(data));
  }

  populateSearchHistory(data): void {
    this.searchHistory = data.search_history;
    this.updateSearchHistoryEventsOrSavedSearch(this.searchHistory);
  }

  updateSearchHistoryEventsOrSavedSearch(listObject) {
    for (var i = 0; i < listObject.length; i++) {
      var search = listObject[i];
      var params = search.params;
      search['getParams'] =
        this.pubMethods.getGetSearchStringFromParams(params);
      search['outputForParamsDisplay'] =
        this.pubMethods.changeSearchParamsToDisplayFormat(params);
    }
  }

  processSearchHistoryDeleted(data) {
    if (data.success) {
      this.pubMethods.showInfoMessage('Your search history has been deleted.');
      this.searchHistory = [];
    }
  }

  deleteSearchHistory() {
    this.accountService
      .deleteUserSearchHistory()
      .subscribe(data => this.processSearchHistoryDeleted(data));
  }

  updateGenericFormatData(
    selectedFormat,
    unusedHeaders,
    data_header_names,
    data_selected_format,
    data_custom_columns
  ) {
    // selectedFormat - the variable in the 'this' scope that defines the format that the user has selected in the UI. i.e. their download or spreadsheet format.
    // unusedHeaders - the headers for this format that are currently unused.
    // data_header_names - the data received from the endpoint that states all the header names available.
    // data_selected_format - the data received from the endpoint that states the format currently selected by the user.
    // data_custom_columns - the data received from the endpoint that states all the custom columns available to the user.

    // Firstly, parse the data from the backend.
    this[selectedFormat] = [];
    data_selected_format?.forEach(
      function (el) {
        try {
          this[selectedFormat].push(JSON.parse(el));
        } catch (err) {
          this[selectedFormat].push(el);
        }
      }.bind(this)
    );

    // Set the unused headers on standard header names.
    this[unusedHeaders] = data_header_names.filter(
      headerName => data_selected_format.indexOf(headerName) == '-1'
    );

    // Set the unused headers on custom columns.

    // Firstly, add the propensity model component columns to the list of all custom columns.
    var propensityModelComponentsColumns = data_custom_columns
      .map(col => Object.assign({}, col))
      .filter(col => col.is_propensity);
    propensityModelComponentsColumns.map(
      el => (el.is_propensity_model_components_column = true)
    );
    data_custom_columns = data_custom_columns.concat(
      propensityModelComponentsColumns
    );

    var unusedResultsCustomColumns = data_custom_columns
      .map(function (el) {
        return {
          name: el.name,
          id: el.id,
          is_propensity_model_components_column:
            el.is_propensity_model_components_column ? true : false,
        };
      })
      .filter(
        columnDetail =>
          this[selectedFormat]
            .map(
              // Check uniqueness on the ID of the column and on whether it is a propensity model component column.
              function (el) {
                return el.id + '__' + el.is_propensity_model_components_column;
              }
            )
            .indexOf(
              columnDetail.id +
                '__' +
                columnDetail.is_propensity_model_components_column
            ) == -1
      );
    this[unusedHeaders] = this[unusedHeaders].concat(
      unusedResultsCustomColumns
    );
  }

  updateDownloadFormatData(data): void {
    this.updateGenericFormatData(
      'userDownloadFormat',
      'unusedCSVDownloadHeaders',
      data.csv_download_header_names,
      data.user_download_format,
      data.custom_columns
    );
  }

  updateUserAccountInformation(data): void {
    this.planName = data.plan_name;
    this.organisationSuperuserEmail = data.superuser_email;
    this.periodType = data.period_type;
    this.downloadLimit = data.download_limit;
    this.downloadsThisPeriod = data.downloads_this_period;
    this.periodStarted = data.period_started;
    this.tagLimit = data.tag_limit;
    this.currentTagCount = data.tag_count;
    if (this.isSuperUser) {
      this.organisations = data.organisations;
      this.organisationUsers = data.organisation_users;
      this.organisationTeams = data.organisation_teams;
      this.organisationUsers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForViewable = true));
      this.organisationUsers
        .filter(usr => usr.isOrganisationAdmin)
        .map(usr => (usr.checkedForEditable = true));
    }
    this.updateDownloadFormatData(data);
    this.checkboxHasTrackingEnabled = data.activity_history_enabled;
    this.downloadDateTrackingEnabled = data.download_date_tracking_enabled;
    this.populateSearchHistory(data);
    this.customColumns = data.custom_columns;
    // we need to exclude non superusers from creating columns of type "User".
    this.columnTypeOptions = data.column_type_options.filter(
      type => this.isSuperUser || type != 'User'
    );
  }

  addOrRemoveHeaderToDownload(headerName, add): void {
    if (add) {
      var arrayToRemove = this.unusedCSVDownloadHeaders;
      var arrayToAdd = this.userDownloadFormat;
    } else {
      var arrayToAdd = this.unusedCSVDownloadHeaders;
      var arrayToRemove = this.userDownloadFormat;
    }
    if (arrayToRemove.length > 1) {
      var index = arrayToRemove.indexOf(headerName);
      if (index !== -1) arrayToRemove.splice(index, 1);
      arrayToAdd.unshift(headerName);
    }
  }

  processResult(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage('Your preferences have been updated.');
    }
  }

  saveDownloadFormat(): void {
    this.accountService
      .updateColumnFormat(this.userDownloadFormat, '/save_download_columns/')
      .subscribe(data => this.processResult(data));
  }

  saveCompanyViewCustomColumnOrder(): void {
    // we only want to pass the name and ID attributes of the custom columns.
    this.accountService
      .updateCompanyViewCustomColumnOrder(
        this.customColumns.map(function (item) {
          return { name: item['name'], id: item['id'] };
        })
      )
      .subscribe(data => this.processResult(data));
  }

  getUserAccountDetails(): void {
    this.accountService
      .getUserAccountDetails()
      .subscribe(data => this.updateUserAccountInformation(data));
  }

  updateTagDetails(data): void {
    this.userTags = data.user_tags;
  }

  getUserTagDetails(): void {
    this.taggingService
      .getUserTags(false)
      .subscribe(data => this.updateTagDetails(data));
  }

  triggerDeleteTag(tagId): void {
    this.data.changeDataSourceTagIdToDelete(tagId);
  }

  updateCustomColumnDeleted(data): void {
    if (data.success) {
      this.customColumns = data.custom_columns;
      this.updateCustomColumnsAfterUpdate(data);
      this.customColumnNameToDelete = null;
      this.customColumnIdToDelete = null;
      $('#confirmDeleteCustomColumnModal').modal('hide');
      this.pubMethods.showInfoMessage('Your custom column was deleted.');
    }
  }

  deleteCustomColumn(): void {
    this.accountService
      .deleteCustomColumnFromId(this.customColumnIdToDelete)
      .subscribe(data => this.updateCustomColumnDeleted(data));
  }

  mustBeFromOptionListClicked(): void {
    if (this.newColumnOptionsEnabled) {
      this.listOfOptionNumbers = [1];
    } else {
      this.listOfOptionNumbers = [];
      this.newColumnOptions = [];
    }
  }

  addNewOption(): void {
    if (this.listOfOptionNumbers.length === 0) {
      this.listOfOptionNumbers = [1];
    } else {
      this.listOfOptionNumbers.push(
        this.listOfOptionNumbers[this.listOfOptionNumbers.length - 1] + 1
      );
    }
  }

  removeNewOption(): void {
    if (this.listOfOptionNumbers.length > 1) {
      this.listOfOptionNumbers.pop();
      this.newColumnOptions.pop();
    }
  }

  newOptionAddedUpdateCustomColumns(column, data): void {
    column.isAddingOption = false;
    this.updateCustomColumnsAfterUpdate(data);
  }

  addNewOptionFromExistingColumn(column): void {
    if (column.optionToAdd) {
      this.accountService
        .addOptionFromExistingColumn(column.id, column.optionToAdd)
        .subscribe(data => (this.customColumns = data.custom_columns));
    } else {
      this.pubMethods.showInfoMessage(
        'Please enter a value for the new option.'
      );
    }
  }

  updateCustomColumnsAfterUpdate(data): void {
    if (data.custom_columns) {
      this.customColumns = data.custom_columns;
      this.pubMethods.showInfoMessage('Your custom attribute was updated.');
      this.updateDownloadFormatData(data);
    }
  }

  customColumnCreatedComplete(data): void {
    this.newColumnName = '';
    this.newColumnType = '';
    this.newColumnOptionsEnabled = false;
    this.newColumnOptions = [];
    this.columnPrivacy = 'private';
    this.newColumnIsLockable = false;
    this.newColumnLockDays = null;
    this.newColumnMaximumDuplicateEntries = null;
    this.updateCustomColumnsAfterUpdate(data);
  }

  createNewCustomColumn(): void {
    if (this.newColumnName && this.newColumnType) {
      if (this.isSuperUser && this.columnPrivacy == 'shared') {
        this.accountService
          .createCollaborativeCustomColumn(
            this.newColumnName,
            this.newColumnType,
            this.newColumnOptionsEnabled,
            this.newColumnOptions,
            this.newColumnIsLockable,
            this.newColumnLockDays,
            this.newColumnMaximumDuplicateEntries
          )
          .subscribe(data => this.customColumnCreatedComplete(data));
      } else {
        this.accountService
          .createCustomColumn(
            this.newColumnName,
            this.newColumnType,
            this.newColumnOptionsEnabled,
            this.newColumnOptions
          )
          .subscribe(data => this.customColumnCreatedComplete(data));
      }
    } else {
      this.pubMethods.showInfoMessage(
        'Please enter a column name and define the column type.'
      );
    }
  }

  customColumnNameUpdated(data, column, name): void {
    if (data.success) {
      column.name = name;
      column.isEditingName = false;
      this.updateDownloadFormatData(data);
    }
  }

  editCustomColumnName(column, columnName): void {
    this.accountService
      .updateCustomColumnName(column.id, columnName)
      .subscribe(data =>
        this.customColumnNameUpdated(data, column, columnName)
      );
  }

  addNewViewableUser(userId, columnId): void {
    this.accountService
      .callAddNewViewableUser(userId, columnId)
      .subscribe(data => this.updateCustomColumnsAfterUpdate(data));
  }

  addNewViewableTeam(teamId, columnId): void {
    this.accountService
      .calladdNewViewableTeam(teamId, columnId)
      .subscribe(data => this.updateCustomColumnsAfterUpdate(data));
  }

  addNewEditableUser(userId, columnId): void {
    this.accountService
      .callAddNewEditableUser(userId, columnId)
      .subscribe(data => this.updateCustomColumnsAfterUpdate(data));
  }

  addNewEditableTeam(teamId, columnId): void {
    this.accountService
      .calladdNewEditableTeam(teamId, columnId)
      .subscribe(data => this.updateCustomColumnsAfterUpdate(data));
  }

  updateAfterDeletingViewingAccess(data): void {
    this.updateCustomColumnsAfterUpdate(data);
    $('#confirmDeleteViewableUserModal').modal('hide');
  }

  updateAfterDeletingEditingAccess(data): void {
    this.updateCustomColumnsAfterUpdate(data);
    $('#confirmDeleteEditableUserModal').modal('hide');
  }

  deleteViewingAccessForUserOnColumn(): void {
    this.accountService
      .callDeleteViewingAccessForUserOnColumn(
        this.viewableUserToDelete.userId,
        this.viewableUserColumnToDeleteOn.id
      )
      .subscribe(data => this.updateAfterDeletingViewingAccess(data));
  }

  deleteViewingAccessForTeamOnColumn(): void {
    this.accountService
      .callDeleteViewingAccessForTeamOnColumn(
        this.viewableTeamToDelete.id,
        this.viewableTeamColumnToDeleteOn.id
      )
      .subscribe(data => this.updateAfterDeletingViewingAccess(data));
  }

  deleteEditingAccessForUserOnColumn(): void {
    this.accountService
      .callDeleteEditingAccessForUserOnColumn(
        this.editableUserToDelete.userId,
        this.editableUserColumnToDeleteOn.id
      )
      .subscribe(data => this.updateAfterDeletingEditingAccess(data));
  }

  deleteEditingAccessForTeamOnColumn(): void {
    this.accountService
      .callDeleteEditingAccessForTeamOnColumn(
        this.editableTeamToDelete.id,
        this.editableTeamColumnToDeleteOn.id
      )
      .subscribe(data => this.updateAfterDeletingViewingAccess(data));
  }

  updateOptionsWhenColumnTypeChanges(): void {
    if (this.newColumnType == 'User') {
      this.newColumnOptionsEnabled = false;
    }
  }

  // TO DELETE SOME BELOW

  updateTagDetailsAfterChange(data): void {
    this.userTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.view_users = data.updated_tag.view_users));
    this.userTags
      .filter(tag => tag.id == data.updated_tag.id)
      .map(tag => (tag.edit_users = data.updated_tag.edit_users));
    $('#confirmDeleteViewableUserOnTagModal').modal('hide');
    $('#confirmDeleteEditableUserOnTagModal').modal('hide');
  }

  addNewViewableUserToTag(userId, tagId): void {
    this.taggingService
      .addViewableUserToTag(userId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteViewingAccessForUserOnTag(): void {
    this.taggingService
      .removeViewableUserFromTag(
        this.tagViewableUserToDelete.userId,
        this.viewableTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  addNewEditableUserToTag(userId, tagId): void {
    this.taggingService
      .addEditableUserToTag(userId, tagId)
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  deleteEditingAccessForUserOnTag(): void {
    this.taggingService
      .removeEditableUserFromTag(
        this.tagEditableUserToDelete.userId,
        this.editableTagToDeleteOn.id
      )
      .subscribe(data => this.updateTagDetailsAfterChange(data));
  }

  dragSort(event: CdkDragDrop<any>, sortType: string): void {
    moveItemInArray(this[sortType], event.previousIndex, event.currentIndex);
  }
}
