import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { CompanyService } from '../services/company.service';
import { MessageService } from '../services/message.service';
import { CompanyClustersComponent } from './company-clusters/company-clusters.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessagesComponent } from './messages/messages.component';
import { publicMethods } from '../globals';
import { DashboardService } from '../services/dashboard.service';
import { AngularOpenlayersModule } from 'ng-openlayers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxGraphModule } from '@kr0san89/ngx-graph';
import { MapToIterable } from '../pipes/map-to-iterable.pipe';
import { SafePipe } from '../pipes/safe';
import { UnderScoreToUpperCasePipe } from '../pipes/underscore-to-upper-case';
import { PropensityComponentOpacityPipe } from '../pipes/propensity-component-opacity';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AuthenticationService } from '../services/authentication.service';
import { LogoutComponent } from './logout/logout.component';
import { CommunicationService } from '../services/communication.service';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { CookieModule } from 'ngx-cookie';
import { GlobalSearchService } from '../services/global-search.service';
import { CamelCaseToProperPipe } from '../pipes/camel-case-to-proper-case';
import { XbrlOrOcrAccountsLinkPipe } from '../pipes/xbrl-or-ocr-accounts-link.pipe';
import { TaggingComponent } from './tagging/tagging.component';
import { TaggingService } from '../services/tagging.service';
import { DataSharingService } from '../services/data-sharing.service';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadService } from '../services/fileupload.service';
import { AccountComponent } from './account/account.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { DownloadService } from '../services/download.service';
import { AccountService } from '../services/account.service';
import { CompanyDataPipe } from '../pipes/company-data.pipe';
import { SpeakToSalesPopoverComponent } from './html-snippet-components/speak-to-sales-popover/speak-to-sales-popover.component';
import { SearchParametersComponent } from './html-snippet-components/search-parameters/search-parameters.component';
import { CrossSellComponent } from './cross-sell/cross-sell.component';
import { CrossSellService } from '../services/cross-sell.service';
import { UploadCompaniesComponent } from './upload-companies/upload-companies.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { OrganisationManagementService } from '../services/organisation-management.service';
import { ComboChartComponent } from './html-snippet-components/combo-chart/combo-chart.component';
import { ComboSeriesVerticalComponent } from './html-snippet-components/combo-chart/combo-series-vertical.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomColumnService } from '../services/custom-column.service';
import { CustomColumnDisplayPipe } from '../pipes/custom-column-display';
import { CustomColumnEditComponent } from './html-snippet-components/custom-column-edit/custom-column-edit.component';
import { CustomColumnBulkEditComponent } from './custom-column-bulk-edit/custom-column-bulk-edit.component';
import { ImportExportDetailsComponent } from './html-snippet-components/import-export-details/import-export-details.component';
import { LearningPortalComponent } from './learning-portal/learning-portal.component';
import { LearningPortalService } from '../services/learning-portal.service';
import { PercentageBadgeComponent } from './html-snippet-components/percentage-badge/percentage-badge.component';
import { FilterLoopPipe } from '../pipes/filter-loop.pipe';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertsService } from '../services/alerts.service';
import { MarkAsReadModalComponent } from './alerts/mark-as-read-modal/mark-as-read-modal.component';
import { SimilarCompanyCardComponent } from './html-snippet-components/similar-company-card/similar-company-card.component';
import { TagEditingComponent } from './tag-editing/tag-editing.component';
import { ZintPropensityScoreService } from '../services/zint-propensity-score.service';
import { PropensityScoreComponent } from './propensity-score/propensity-score.component';
import { CustomColumnFilterComponent } from './html-snippet-components/custom-column-filter/custom-column-filter.component';
import { CrmIntegrationsComponent } from './crm-integrations/crm-integrations.component';
import { CRMIntegrationsService } from '../services/crm-integrations.service';
import { SalesforceComponent } from './html-snippet-components/salesforce/salesforce.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MarketMappingReportComponent } from './market-mapping-report/market-mapping-report.component';
import { MarketMappingReportService } from '../services/market-mapping-report.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ManageTeamsComponent } from './manage-teams/manage-teams.component';
import { ManageTeamsService } from '../services/manage-teams.service';
import { SalesforceImporterComponent } from './salesforce/salesforce-importer/salesforce-importer.component';
import { SalesforceImporterService } from '../services/salesforce-importer.service';
import { SalesforceSettingsPageComponent } from './salesforce/salesforce-settings-page/salesforce-settings-page.component';
import { PreviousNextButtonsComponent } from './salesforce/html-snippets/previous-next-buttons/previous-next-buttons.component';
import { CompanyMatcherComponent } from './company-matcher/company-matcher.component';
import { CompanyMatcherV2Service } from '../services/company-matcher-v2.service';
import { CrmQueryBuilderComponent } from './crm-query-builder/crm-query-builder.component';
import { CRMQueryBuilderService } from '../services/crm-query-builder.service';
import { ExportModalComponent } from './salesforce/export-modal/export-modal.component';
import { SalesforceExporterService } from '../services/salesforce-exporter.service';
import { AdminConfigComponent } from './salesforce/admin-config/admin-config.component';
import { SalesforceConfigService } from '../services/salesforce-config.service';
import { AddRemoveTeamComponent } from './html-snippet-components/permissions-snippets/add-remove-team/add-remove-team.component';
import { AddRemoveIndividualComponent } from './html-snippet-components/permissions-snippets/add-remove-individual/add-remove-individual.component';
import { SalesforceOauthComponent } from './salesforce/salesforce-oauth/salesforce-oauth.component';
import { ExportConfigComponent } from './salesforce/export-config/export-config.component';
import { SalesforceSyncDetailsComponent } from './salesforce/salesforce-sync-details/salesforce-sync-details.component';
import { UploadCompaniesV2Component } from './upload-companies-v2/upload-companies-v2.component';
import { BasicCompanyMatcherService } from '../services/basic-company-matcher.service';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { ZendeskAuthComponent } from './zendesk-auth/zendesk-auth.component';
import { ZendeskSsoService } from '../services/zendesk-sso.service';
import { ResultsFormatEditorComponent } from './html-snippet-components/results-format-editor/results-format-editor.component';
import { ResultsFormatEditorService } from '../services/results-format-editor.service';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { StripePurchaseService } from '../services/stripe-purchase.service';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { PlanFeaturesEnabledComponent } from './plan-features-enabled/plan-features-enabled.component';
import { RemoveTrailingKwh } from '../pipes/remove-trailing-kwh';
import { RemoveTrailingKgCo2e } from '../pipes/remove-trailing-kg-co2e';
import { RemoveTrailingEventType } from '../pipes/remove-trailing-event-type';
import { GovernmentContractComponent } from './html-snippet-components/government-contract/government-contract.component';
import { ZintGrowComponent } from './zint-grow/zint-grow.component';
import { EventSourceTypeComponentComponent } from './zint-grow/event-source-type-component/event-source-type-component.component';
import { ZintGrowService } from '../services/zint-grow.service';
import { NewsSearchComponent } from './zint-grow/event-source-type-component/news-search/news-search.component';
import { ZintGrowSubscriptionsComponent } from './zint-grow-subscriptions/zint-grow-subscriptions.component';
import { ZintGrowSubscriptionsService } from '../services/zint-grow-subscriptions.service';
import { OfficerComponent } from './zint-grow/event-source-type-component/officer/officer.component';
import { PSCComponent } from './zint-grow/event-source-type-component/psc/psc.component';
import { RightImageComponent } from './zint-grow/event-source-type-component/sub-components/right-image/right-image.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { AiPitchBuilderComponent } from './ai-pitch-builder/ai-pitch-builder.component';
import { AiPitchBuilderService } from '../services/ai-pitch-builder.service';
import { NotificationSearchTermComponent } from './zint-grow/event-source-type-component/sub-components/notification-search-term/notification-search-term.component';
import { HeatScoreComponent } from './company-detail/heat-score/heat-score.component';
import { EventSourceTypeRulesComponent } from './event-souce-type-rules/event-souce-type-rules.component';
import { AccountsSearchComponent } from './zint-grow/event-source-type-component/accounts-search/accounts-search.component';
import { AccountsFilingComponent } from './zint-grow/event-source-type-component/accounts-filing/accounts-filing.component';
import { WebsiteSearchComponent } from './zint-grow/event-source-type-component/website-search/website-search.component';
import { ImportExportComponent } from './zint-grow/event-source-type-component/import-export/import-export.component';
import { HiringComponent } from './zint-grow/event-source-type-component/hiring/hiring.component';
import { NameOnlyDisplayPipe } from '../pipes/name-only-display-pipe';
import { ManageTerritoriesComponent } from './manage-territories/manage-territories.component';
import { TerritoryService } from '../services/territory.service';
import { RelatedOfficerComponent } from './zint-grow/event-source-type-component/related-officer/related-officer.component';
import { OpensearchTermConfigComponent } from './zint-grow-subscriptions/grow-config-form/opensearch-term-config/opensearch-term-config.component';
import { OpenaiTermsModalComponent } from './html-snippet-components/openai-terms-modal/openai-terms-modal.component';
import { RoundResultCountPipe } from '../pipes/round-result-count.pipe';
import { TemplateLog } from '../pipes/log-in-template';
import { CorporateStructureComponent } from './zint-grow/event-source-type-component/corporate-structure/corporate-structure.component';
import { PermissionsComponent } from './html-snippet-components/permissions/permissions.component';
import { PermissionsService } from '../services/permissions.service';
import { CalendarIntegrationComponent } from './calendar-integration/calendar-integration.component';
import { ManageEmailsComponent } from './manage-emails/manage-emails.component';
import { RelatedPscComponent } from './zint-grow/event-source-type-component/related-psc/related-psc.component';
import { PriorityDotsComponent } from './html-snippet-components/priority-dots/priority-dots.component';
import { NotificationsPageComponent } from './zint-grow/notifications-page/notifications-page.component';
import { EventDetailsComponent } from './zint-grow/event-details/event-details.component';
import { EventActionsComponent } from './zint-grow/event-actions/event-actions.component';
import { PushMessagesComponent } from './messages/push-messages/push-messages.component';
import { PushMessagesService } from '../services/push-messages.service';
import { ZintGrowModalComponent } from './html-snippet-components/upsell-modals/zint-grow-modal/zint-grow-modal.component';
import { ColumnNameToIconPipe } from '../pipes/column-name-to-icon.pipe';
import { HubspotComponent } from './hubspot/hubspot/hubspot.component';
import { DownloadLinkComponent } from './download-link/download-link.component';
import { VisibilityService } from '../services/visibility.service';
import { ZintLoaderComponent } from './html-snippet-components/zint-loader/zint-loader.component';
import { AiAccountsSummariesComponent } from './ai-accounts-summaries/ai-accounts-summaries.component';
import { ZintNgxSliderComponent } from './zint-ngx-slider/zint-ngx-slider.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { AiInsightsModalComponent } from './ai-insights-modal/ai-insights-modal.component';
import { CompanyProfilePageComponent } from './company-profile-page/company-profile-page.component';
import { AiResearchEngineComponent } from './ai-research-engine/ai-research-engine.component';
import { HubspotExportComponent } from './hubspot/hubspot-export/hubspot-export.component';
import { HubspotExporterService } from '../services/hubspot-export.service';
import { KeyPeopleComponent } from './html-snippet-components/key-people/key-people.component';
import { NewsCarouselComponent } from './html-snippet-components/news-carousel/news-carousel.component';
import { TruncateTextComponent } from './html-snippet-components/truncate-text/truncate-text.component';
import { CorporateStructureGraphComponent } from './corporate-structure-graph/corporate-structure-graph.component';
import { CrossSellGraphComponent } from './cross-sell-graph/cross-sell-graph.component';
import { OpensearchModalComponent } from './html-snippet-components/opensearch-modal/opensearch-modal.component';
import { CustomInsightsComponent } from './custom-insights/custom-insights.component';
import { BackendTableComponent } from './html-snippet-components/backend-table/backend-table.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SparklineComponent } from './html-snippet-components/sparkline/sparkline.component';

@NgModule({
  declarations: [
    AppComponent,
    CompanyClustersComponent,
    CompanyDetailComponent,
    DashboardComponent,
    MessagesComponent,
    MapToIterable,
    SafePipe,
    CompanyDataPipe,
    UnderScoreToUpperCasePipe,
    PropensityComponentOpacityPipe,
    CamelCaseToProperPipe,
    RemoveTrailingKwh,
    RemoveTrailingKgCo2e,
    RemoveTrailingEventType,
    CustomColumnDisplayPipe,
    FilterLoopPipe,
    NameOnlyDisplayPipe,
    RoundResultCountPipe,
    TemplateLog,
    ColumnNameToIconPipe,
    XbrlOrOcrAccountsLinkPipe,
    AuthenticationComponent,
    LogoutComponent,
    LoginRegisterComponent,
    TaggingComponent,
    FileUploadComponent,
    AccountComponent,
    DownloadsComponent,
    SpeakToSalesPopoverComponent,
    SearchParametersComponent,
    CrossSellComponent,
    UploadCompaniesComponent,
    OrganisationManagementComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    CustomColumnEditComponent,
    CustomColumnBulkEditComponent,
    ImportExportDetailsComponent,
    LearningPortalComponent,
    PercentageBadgeComponent,
    AlertsComponent,
    MarkAsReadModalComponent,
    SimilarCompanyCardComponent,
    TagEditingComponent,
    PropensityScoreComponent,
    CustomColumnFilterComponent,
    CrmIntegrationsComponent,
    SalesforceComponent,
    MarketMappingReportComponent,
    PrivacyPolicyComponent,
    ManageTeamsComponent,
    SalesforceSettingsPageComponent,
    SalesforceImporterComponent,
    PreviousNextButtonsComponent,
    CompanyMatcherComponent,
    CrmQueryBuilderComponent,
    ExportModalComponent,
    AdminConfigComponent,
    AddRemoveTeamComponent,
    AddRemoveIndividualComponent,
    SalesforceOauthComponent,
    ExportConfigComponent,
    SalesforceSyncDetailsComponent,
    UploadCompaniesV2Component,
    SavedSearchesComponent,
    ZendeskAuthComponent,
    ResultsFormatEditorComponent,
    PurchasePlanComponent,
    PlanDetailsComponent,
    PlanFeaturesEnabledComponent,
    GovernmentContractComponent,
    ZintGrowComponent,
    ZintGrowSubscriptionsComponent,
    NewsSearchComponent,
    AccountsSearchComponent,
    AccountsFilingComponent,
    ImportExportComponent,
    WebsiteSearchComponent,
    EventSourceTypeComponentComponent,
    OfficerComponent,
    PSCComponent,
    RightImageComponent,
    AiPitchBuilderComponent,
    NotificationSearchTermComponent,
    HeatScoreComponent,
    AiPitchBuilderComponent,
    EventSourceTypeRulesComponent,
    HiringComponent,
    ManageTerritoriesComponent,
    RelatedOfficerComponent,
    OpenaiTermsModalComponent,
    OpensearchTermConfigComponent,
    CorporateStructureComponent,
    PermissionsComponent,
    CalendarIntegrationComponent,
    ManageEmailsComponent,
    RelatedPscComponent,
    PriorityDotsComponent,
    NotificationsPageComponent,
    EventDetailsComponent,
    EventActionsComponent,
    PushMessagesComponent,
    ZintGrowModalComponent,
    HubspotComponent,
    DownloadLinkComponent,
    ZintLoaderComponent,
    AiAccountsSummariesComponent,
    ZintNgxSliderComponent,
    ChatbotComponent,
    AiInsightsModalComponent,
    CompanyProfilePageComponent,
    AiResearchEngineComponent,
    HubspotExportComponent,
    KeyPeopleComponent,
    NewsCarouselComponent,
    TruncateTextComponent,
    CorporateStructureGraphComponent,
    CrossSellGraphComponent,
    OpensearchModalComponent,
    CustomInsightsComponent,
    BackendTableComponent,
    SparklineComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularOpenlayersModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxGraphModule,
    CookieModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatExpansionModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    NgxSliderModule,
    DragDropModule,
    NgApexchartsModule,
  ],
  providers: [
    CompanyService,
    MessageService,
    DashboardService,
    TerritoryService,
    TaggingService,
    publicMethods,
    AuthenticationService,
    CommunicationService,
    GlobalSearchService,
    DataSharingService,
    FileUploadService,
    DownloadService,
    AccountService,
    CrossSellService,
    OrganisationManagementService,
    BasicCompanyMatcherService,
    CustomColumnService,
    LearningPortalService,
    AlertsService,
    ZintPropensityScoreService,
    CRMIntegrationsService,
    MarketMappingReportService,
    ManageTeamsService,
    SalesforceImporterService,
    CompanyMatcherV2Service,
    CRMQueryBuilderService,
    SalesforceExporterService,
    SalesforceConfigService,
    HubspotExporterService,
    ZendeskSsoService,
    ResultsFormatEditorService,
    StripePurchaseService,
    ZintGrowService,
    ZintGrowSubscriptionsService,
    DatePipe,
    TitleCasePipe,
    AiPitchBuilderService,
    PermissionsService,
    PushMessagesService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    VisibilityService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
