<table class="table table-striped table-bordered">
    <tbody>
        <tr *ngIf="contract.title">
            <td>Title:</td>
            <td style="font-style: italic">{{ contract.title }}</td>
        </tr>
        <tr *ngIf="contract.provided_to">
            <td>Provided to:</td>
            <td>{{ contract.provided_to }}</td>
        </tr>
        <tr *ngIf="contract.description">
            <td>Description:</td>
            <td><span style="display: inline-block; overflow-y: scroll; height: 4.3em;">{{ contract.description }}</span></td>
        </tr>
        <tr *ngIf="contract.date_awarded">
            <td>Date Awarded:</td>
            <td>{{ contract.date_awarded | date:'d MMM y' }}</td>
        </tr>
        <tr *ngIf="contract.contract_value">
            <td>Contract Value:</td>
            <td>{{ contract.contract_value | companyDataPipe:'Revenue':'' }}</td>
        </tr>
        <tr *ngIf="contract.start && contract.end">
            <td style="width: 9em;">Contract Period:</td>
            <td>{{ contract.start | date:'d MMM y' }}  -  {{ contract.end | date:'d MMM y' }}</td>
        </tr>
        <tr *ngIf="contract.guid">
            <td>Further Details:</td>
            <td><a href="https://www.contractsfinder.service.gov.uk/notice/{{ contract.guid }}" target="_blank">More <i class="fal fa-external-link"></i></a><br></td>
        </tr>
    </tbody>
</table>
