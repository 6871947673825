import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CompanyPageRedirectGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const fromProfile = route.queryParams['fromProfile'];
    // Redirect to 'profile/:id' if 'fromProfile' is not set
    if (!fromProfile) {
      const companyNumber = route.params['id'];
      this.router.navigate([`/profile/${companyNumber}`]);
      return false;
    }

    return true;
  }
}
