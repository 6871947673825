<div class="container">
    <div class="row">

        <div class="col-md-12" *ngIf="isLoading.loadingInitialData">
            <div class="d-flex justify-content-center">
                <zint-loader></zint-loader>
            </div>
        </div>


        <ng-container *ngIf="!isLoading.loadingInitialData && teams && teams.length > 0">

            <div class="col-md-12 medium-margin-bottom">
                <h5>Plan Details</h5>

                <table class="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <td>Plan Name</td>
                            <td>{{topLevelPlanDetails.plan_name}}</td>
                        </tr>
                        <tr>
                            <td>Cycle Began</td>
                            <td>{{topLevelPlanDetails.period_started | date:'d MMM y'}}</td>
                        </tr>
                        <tr>
                            <td>User Quota</td>
                            <td>{{topLevelPlanDetails.users_allocated}} / {{topLevelPlanDetails.users_limit}} users created</td>
                        </tr>
                        <tr>
                            <td>Download Quota</td>
                            <td>{{topLevelPlanDetails.downloads_this_period}} / {{topLevelPlanDetails.download_limit}} monthly downloads used</td>
                        </tr>
                        <tr>
                            <td>Propensity Model Quota</td>
                            <td>{{topLevelPlanDetails.propensity_models_used}} / {{topLevelPlanDetails.number_of_propensity_score_models_limit}} models built</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ng-container *ngFor="let team of teams">

                <div [ngClass]="{'col-md-12': team.id == parentOrg.id, 'col-md-6': team.id != parentOrg.id}" class="medium-margin-bottom">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <h5 class="card-title col-6">
                                    <ng-container *ngIf="team.id == parentOrg.id">Parent Organisation - </ng-container>
                                    {{team.name}}
                                </h5>
                                <ng-container *ngIf="team.id != parentOrg.id" class="col-6">
                                    <a class="basic-button-base button-dark"  type="button" href="/manage-team?org_id={{team.id}}"> <i class="fa fa-users"></i> Manage Team</a>
                                </ng-container>
                            </div>

                            <table class="table table-bordered small-margin-top">
                                <tbody>
                                    <tr>
                                        <td>Download Quota</td>
                                        <td>
                                            <div class="d-flex flex-row">
                                                <div>
                                                    <p style="margin-top:6px">{{team.downloads_used}} / &nbsp;&nbsp;</p>
                                                </div>
                                                <div>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" [(ngModel)]="team.total_downloads_between_all_accounts">
                                                        <div class="input-group-append">
                                                            <button class="btn basic-button-base button-dark" type="button" (click)="updateTeamAssignedDownloads(team.id, team.total_downloads_between_all_accounts)" [attr.disabled]="isLoading.isUpdatingAssignedDownloads ? 'disabled': null">
                                                                <i class="fal fa-save" *ngIf="!isLoading.isUpdatingAssignedDownloads"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAssignedDownloads"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Propensity Models</td>
                                        <td>
                                            <div class="d-flex flex-row">
                                                <div>
                                                    <p style="margin-top:6px">{{team.propensity_models_used}} / &nbsp;&nbsp;</p>
                                                </div>
                                                <div>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" [(ngModel)]="team.propensity_models_allocated">
                                                        <div class="input-group-append">
                                                            <button class="btn basic-button-base button-dark" type="button" (click)="updateTeamAssignedPropensityModels(team.id, team.propensity_models_allocated)" [attr.disabled]="isLoading.isUpdatingAssignedPropensityModels ? 'disabled': null">
                                                                <i class="fal fa-save" *ngIf="!isLoading.isUpdatingAssignedPropensityModels"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAssignedPropensityModels"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Number of Users</td>
                                        <td>
                                            <div class="d-flex flex-row">
                                                <div>
                                                    <p style="margin-top:6px">{{team.team_members.length}} / &nbsp;&nbsp;</p>
                                                </div>
                                                <div>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" [(ngModel)]="team.max_number_of_team_members">
                                                        <div class="input-group-append">
                                                            <button class="btn basic-button-base button-dark" type="button" (click)="updateTeamAssignedUsers(team.id, team.max_number_of_team_members)" [attr.disabled]="isLoading.isUpdatingAssignedUsersModels ? 'disabled': null">
                                                                <i class="fal fa-save" *ngIf="!isLoading.isUpdatingAssignedUsersModels"></i> <i class="fal fa-spinner fa-spin" *ngIf="isLoading.isUpdatingAssignedUsersModels"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <p class="medium-margin-top">Users in team:</p>
                            <table class="table table-striped">
                                <tr *ngFor="let teamMember of team.team_members">
                                    <td>
                                        {{teamMember.value}}
                                        <ng-container *ngIf="teamMember.id == team.superuser_id">
                                            &nbsp;&nbsp;<i class="fal fa-user-circle"></i>
                                        </ng-container>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="team.id == parentOrg.id">
                    <h4>Teams within your organisation:</h4>
                </div>

            </ng-container>




            <div class="col-md-12 medium-margin-top">
                <h4>Add a new team:</h4>
            </div>

            <div class="col-md-6 small-margin-bottom">
                <div class="card">
                    <div class="card-body">
                        <br>
                        <div class="form-group">
                            <label>New Team Name:</label>
                            <input type="email" class="form-control" name="newTeamName" placeholder="e.g. Team 1" [(ngModel)]="newTeamName">
                        </div>
                        <div class="form-group">
                            <label>Email Address of team administrator:</label>
                            <input type="email" class="form-control" name="newTeamEmailOfSuperuser" [(ngModel)]="newTeamEmailOfSuperuser">
                            <small class="form-text text-muted">Please note that if you enter the email of a user in a different team, they will be migrated out of their current team and into this team.</small>
                        </div>
                        <button class="basic-button-base button-dark" type="button" (click)="createNewTeam()" [attr.disabled]="isLoading.addingNewTeam ? 'disabled': null">Add</button>
                    </div>
                </div>
            </div>


        </ng-container>




    </div>
</div>
