<div class="card similar-org-card">
    <img *ngIf="inputParams.similarOrg.annotated_screenshot_url" src="{{ inputParams.similarOrg.annotated_screenshot_url }}" class="card-img-top similar-org-card-img-top">
    <div class="card-body similar-org-card-body">
        <h5 class="card-title">
            <img *ngIf="inputParams.similarOrg.url" src="https://www.google.com/s2/favicons?domain={{ inputParams.similarOrg.url }}">
            {{ inputParams.similarOrg.company_name }}
        </h5>
        <hr class="similar-org-hr">
        <div class="card-text">
            <span *ngIf="inputParams.similarOrg.company_revenue">Revenue: {{ inputParams.similarOrg.company_revenue | companyDataPipe:'Revenue':'' }}<br></span>
            <span *ngIf="inputParams.similarOrg.url"><a href="{{ inputParams.similarOrg.url }}" target="_blank">{{ inputParams.similarOrg.domain }}</a><br></span>
            <span *ngIf="inputParams.similarOrg.number_of_employees_banding">Estimated Headcount: {{ inputParams.similarOrg.number_of_employees_banding }}<br></span>
            <span *ngIf="inputParams.similarOrg.summary" class="similar-org-summary"><br>{{ inputParams.similarOrg.summary }}<br></span>
            <span *ngIf="inputParams.similarOrg.primary_sic_description" class="similar-org-summary"><br>- {{ inputParams.similarOrg.primary_sic_description }}<br></span>
            <br>
        </div>
        <a href="{{ inputParams.similarOrg.zint_link }}" target="_blank" class="btn basic-button-base button-dark similar-org-view-profile-button">View Profile</a>
    </div>
</div>
