import { Component, OnInit } from '@angular/core';
import { Company } from '../../classes/company';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { Title } from '@angular/platform-browser';
import { publicMethods, baseUrl } from '../../globals';
declare var $: any;
import {
  forceCollide,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';
//noinspection TypeScriptCheckImport
import * as shape from 'd3-shape';
import { DataSharingService } from '../../services/data-sharing.service';
import { MessageService } from '../../services/message.service';
import { TaggingService } from '../../services/tagging.service';
import { CommunicationService } from '../../services/communication.service';
import { ZintGrowSubscriptionsService } from '../../services/zint-grow-subscriptions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CorporateGraphType } from '../../classes/types';
declare var zE: any; // Zendesk SDK variable

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css'],
})
export class CompanyDetailComponent implements OnInit {
  isLoading = true;
  isRestricted = true;
  hasViewed = true;
  isSuperUser = false;
  userId: number;
  emailOnAccount = '';
  hasZintGrowAccess = false;
  hasColumnEditAccess = false;

  yAxisLabelImports = 'Number of Imports';
  yAxisLabelExports = 'Number of Exports';

  combinationLineChartTheme = {
    name: 'singleRedLine',
    selectable: false,
    group: 'Ordinal',
    domain: ['red'],
  };

  combinationBarChartTheme = {
    name: 'singleDarkPurple',
    selectable: true,
    group: 'Ordinal',
    domain: ['#000a36'],
  };

  lineChartColorSchemeImports = {
    domain: ['#000A36'],
  };
  lineChartColorSchemeExports = {
    domain: ['#ce8184'],
  };

  //noinspection TypeScriptUnresolvedVariable
  curve = shape.curveLinear;

  //noinspection TypeScriptUnresolvedVariable
  curveRelatedPeopleGraph = shape.curveLinear; //curveCardinal;

  force = forceSimulation<any>()
    .force('charge', forceManyBody().strength(-10000)) // The strength of the repulsion. Default is -150
    .force('collide', forceCollide(25)) // The radius distance of each repel "bubble" put around each node.
    .alphaDecay(0.1); // How fast it calculates. 1 is fast but may not be an optimum layout. Default is 0.028 I think.
  forceLink = forceLink<any, any>()
    .id(node => node.id)
    .distance(() => 400); // The length of the links. Default is 100

  companyId: string;
  accountsSearchText: string;
  accountsDateSelected: string = 'any';
  accountsResults: Record<string, any> = {};

  fullWebsiteSearchTerm: string;
  fullWebsiteResults: any[];

  companyNewsSearch: string;
  companyNewsArticleUrlsMatchingSearch: Array<any> = [];
  newsArticlesToSummaries = {};
  filterNewsResultsFromSearch: boolean = false;

  selfPublishedCompanyNewsSearchText: string;
  selfPublishedCompanyNewsArticleUrlsMatchingSearch: Array<any> = [];
  selfPublishedNewsArticlesToSummaries = {};
  filterSelfPublishedNewsResultsFromSearch: boolean = false;

  jobSearchText: string;
  jobsMatchingSearch: Array<any> = [];
  filterJobResultsFromSearch: boolean = false;

  companyTags: Array<any>;
  teamMemberTags: Array<any>;
  propensityTags: Array<any>;
  organisationTags: Array<any>;
  crossSellReason: string;
  customColumnDetails: Array<any>;
  zintGrowColumns: Array<any>;
  zintGrowColumnsFollowing: Array<any>;
  heatScore: any;
  teamMembers: Array<any>;
  salesforceLinks: Array<any>;
  customAttrsUpdated: boolean;
  isLoadingCorporateStructureGraph: boolean;
  isLoadingRelatedPeopleGraph: boolean;
  relatedPeopleGraph: any;
  corporateStructureGraph: any;
  corporateGraphType: CorporateGraphType = 'structure';
  showFullClustersGraph: boolean = false;
  clustersPageLink: string = '';

  isLoadingSupplierGraph: boolean;
  supplierGraph: any = null;
  viewSupplierDataDisplayAsTable = true;
  supplierDataPageNumber = 1;
  hasSupplierPreviousPage = false;
  hasSupplierNextPage = false;

  allGovernmentContracts: any;
  filteredGovernmentContracts: any;
  governmentContractsSearchTerm: string;

  companyAIPitch: string = '';

  isRelatedPeopleGraphExpandedView = false;
  shouldAutoCentreRelatedPeopleGraph = true;

  isCorporateStructureGraphExpandedView = false;

  isBrowserSafari: boolean;
  hasSalesforceAccess: boolean;
  hasSalesforceV2Access: boolean;
  hasHubspotAccess: boolean;
  hasEnergyAndEmissionsAccess: boolean;
  hasPatentsAccess: boolean;
  hasAIPitchBuilder: boolean;
  hasSupplierDataAccess: boolean;
  hasAccessToAISummary: boolean;
  hasAIResearchAssistantAccess: boolean;
  hasAICompanyAssistantAccess: boolean;
  hasCustomInsightsAccess: boolean;

  // prettier-ignore
  aiAccountSummariesStatuses = {
    'hasGeneric': [],
  };
  aiAccountsSummaries: any = {};
  yearAccIDWithLatestSummaries: number | string = null;

  company: Company;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private pubMethods: publicMethods,
    private titleService: Title,
    private data: DataSharingService,
    private messageService: MessageService,
    private taggingService: TaggingService,
    private communicationService: CommunicationService,
    private zintGrowSubscriptionsService: ZintGrowSubscriptionsService,
    private snackbar: MatSnackBar
  ) {}

  loadingStatus = this.companyService.isLoading;

  ngOnInit() {
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService.getUserAccountId().subscribe(userId => {
      this.userId = userId;
    });
    this.communicationService.getEmailMessage().subscribe(accountEmail => {
      this.emailOnAccount = accountEmail;
    });
    this.communicationService
      .getHasZintGrowAccessMessage()
      .subscribe(hasZintGrowAccess => {
        this.hasZintGrowAccess = hasZintGrowAccess;
      });
    this.getCompany();
    this.getCompanyTags();
    this.communicationService
      .getHasSalesforceAccessMessage()
      .subscribe(hasSalesforceAccess => {
        this.hasSalesforceAccess = hasSalesforceAccess;
      });
    this.communicationService
      .getHasSalesforceV2AccessMessage()
      .subscribe(hasSalesforceV2Access => {
        this.hasSalesforceV2Access = hasSalesforceV2Access;
      });
    this.communicationService.getHubspotAccess().subscribe(hasHubspotAccess => {
      this.hasHubspotAccess = hasHubspotAccess;
    });
    this.communicationService
      .getHasEnergyAndEmissionsAccessMessage()
      .subscribe(hasEnergyAndEmissionsAccess => {
        this.hasEnergyAndEmissionsAccess = hasEnergyAndEmissionsAccess;
      });
    this.communicationService
      .getHasPatentsAccessMessage()
      .subscribe(hasPatentsAccess => {
        this.hasPatentsAccess = hasPatentsAccess;
      });
    this.communicationService
      .getHasAIPitchBuilderAccessMessage()
      .subscribe(hasAIPitchBuilder => {
        this.hasAIPitchBuilder = hasAIPitchBuilder;
      });
    this.communicationService
      .getHasSupplierDataAccessMessage()
      .subscribe(hasSupplierDataAccess => {
        this.hasSupplierDataAccess = hasSupplierDataAccess;
      });
    this.communicationService
      .getHasAIResearchAssistantAccessMessage()
      .subscribe(hasAIResearchAssistantAccess => {
        this.hasAIResearchAssistantAccess = hasAIResearchAssistantAccess;
      });
    this.communicationService
      .getHasAICompanyAssistantAccessMessage()
      .subscribe(hasAICompanyAssistantAccess => {
        this.hasAICompanyAssistantAccess = hasAICompanyAssistantAccess;
      });
    this.communicationService
      .getHasCustomInsightsAccessMessage()
      .subscribe(hasCustomInsightsAccess => {
        this.hasCustomInsightsAccess = hasCustomInsightsAccess;
      });
    this.getCompanySummaries();
    this.isBrowserSafari = this.pubMethods.isSafari();
  }

  ngAfterViewInit() {
    this.scrollToAnchor();
  }

  filterIndexAccounts() {
    return this.company.financials.filter(x => x.has_been_indexed);
  }

  isDate(val) {
    return val.toString().indexOf('-') >= 1;
  }

  updateCompanyInfoAndInitUI(data) {
    this.pubMethods.showIEWarningIfRequired();
    this.isRestricted = data.is_restricted;
    this.hasViewed = data.has_viewed;
    this.company = data.company;
    if (this.company) {
      this.titleService.setTitle('Zint - ' + this.company.name);
      this.pubMethods.addJavascript();
      this.listenToLoadGraph();
      this.data.changeDataSourceCurrentCompanyIds([this.company['id']]);
      this.getLatestYearAccounts(this.company.financials);
    }
    this.isLoading = false;
    this.openSearchFromQueryParams();

    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  }

  getLatestYearAccounts(financialsData: Record<string, any>[]): void {
    const latestYearAccounts = financialsData.sort(
      (a, b) => a.made_up_to - b.made_up_to
    )[0];
    this.yearAccIDWithLatestSummaries = latestYearAccounts?.id;
  }

  openSearchFromQueryParams() {
    const params = this.route.snapshot.queryParams;
    if (params.accountsSearch) {
      if (params.accountsDate) {
        this.accountsDateSelected = params.accountsDate;
      }
      this.accountsSearchText = params.accountsSearch;
      $(function () {
        $('#accountsSearchModal').modal();
      });
      this.accountsSearch();
    } else if (params.fullWebsiteSearch) {
      this.fullWebsiteSearchTerm = params.fullWebsiteSearch;
      $(function () {
        $('#fullWebsiteSearchModal').modal();
      });
      this.fullWebsiteSearch();
    } else if (params.companyNewsSearch) {
      if (this.company.news && this.company.news.length > 0) {
        this.companyService.scrollToSelectorOnceExists('companyNews');
        this.companyNewsSearch = params.companyNewsSearch;
        this.newsSearch();
      }
    } else if (params.selfPublishedCompanyNewsSearch) {
      if (
        this.company.self_published_news &&
        this.company.self_published_news.length > 0
      ) {
        this.companyService.scrollToSelectorOnceExists(
          'selfPublishedCompanyNews'
        );
        this.selfPublishedCompanyNewsSearchText =
          params.selfPublishedCompanyNewsSearch;
        this.selfPublishedCompanyNewsSearch();
      }
    }
  }

  updateCompanyUserDetails(data): void {
    this.companyTags = data.tags;
    this.teamMemberTags = data.team_member_tags;
    this.propensityTags = data.propensity_score_tags;
    this.organisationTags = data.organisation_tags;
    this.crossSellReason = data.cross_sell_reason;
    this.customColumnDetails = data.custom_columns;
    this.heatScore = data.heat_score;
    // get User column options in right format to display in dropdowns.
    this.teamMembers = data.team_members;
    if (this.customColumnDetails) {
      this.customColumnDetails.forEach(col => {
        if (col.column_type === 'User') {
          col.options = this.teamMembers;
        }
      });
      this.refreshZintGrowColumns();
    }
    this.salesforceLinks = data.salesforce_links;
  }

  refreshZintGrowColumns(): void {
    this.zintGrowColumns = this.customColumnDetails.filter(
      col => col.is_zint_grow_column === true
    );
    this.zintGrowColumnsFollowing = this.customColumnDetails.filter(
      col =>
        col.is_zint_grow_column === true && col.value === this.emailOnAccount
    );
  }

  getCompanyTags(): void {
    var id = this.route.snapshot.paramMap.get('id');
    this.companyService
      .getCompanyUserDetails(<string>id)
      .subscribe(tags => this.updateCompanyUserDetails(tags));
  }

  getCompany(): void {
    var id = this.route.snapshot.paramMap.get('id');
    this.companyId = id;
    this.companyService.getCompany(<string>id).subscribe(data => {
      return this.updateCompanyInfoAndInitUI(data);
    });
  }

  listenToLoadGraph(): void {
    var self = this;
    var checkerFunction = window.setInterval(function () {
      var elementTop = $('#first-graph').offset().top;
      var elementBottom = elementTop + $('#first-graph').outerHeight();
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
      if (elementBottom > viewportTop && elementTop < viewportBottom) {
        self.getCompanyGraphInformation();
        clearInterval(checkerFunction);
      }
    }, 500);
  }

  getSupplierGraph() {
    if (this.hasSupplierDataAccess && !this.isLoadingSupplierGraph) {
      const id = this.route.snapshot.paramMap.get('id');
      this.isLoadingSupplierGraph = true;
      this.companyService
        .getSupplierGraph(<string>id, this.supplierDataPageNumber)
        .subscribe(graphInfo => this.updateSupplierGraph(graphInfo));
    }
  }

  updateSupplierGraph(graphInfo) {
    this.supplierGraph = graphInfo['company_mentions_graph'];
    this.supplierDataPageNumber = graphInfo['page_number'];
    this.hasSupplierPreviousPage = graphInfo['has_previous'];
    this.hasSupplierNextPage = graphInfo['has_next'];
    this.isLoadingSupplierGraph = false;
  }

  // TODO Delete this method when we replace with the <corporate-structure-graph> component ???
  updateCorporateStructureGraph(graphInfo) {
    this.corporateStructureGraph = graphInfo['corporate_structure_graph'];
    this.isLoadingCorporateStructureGraph = false;
  }

  updateRelatedPeopleGraphs(graphInfo) {
    this.relatedPeopleGraph = graphInfo['related_people_graph'];
    this.isLoadingRelatedPeopleGraph = false;
    setTimeout(() => {
      this.shouldAutoCentreRelatedPeopleGraph = false;
    }, 100);
  }

  // TODO Delete this method when we replace with the <corporate-structure-graph> component
  collapseCorporateStructureGraph(nodeId) {
    this.corporateStructureGraph['links'] = [];
    this.corporateStructureGraph['nodes'] = this.corporateStructureGraph[
      'nodes'
    ].filter(node => node['id'] == nodeId);
  }

  // TODO Delete this method when we replace with the <corporate-structure-graph> component
  addNodesAndLinksToCorporateStructure(graphInfo) {
    const graph = graphInfo['corporate_structure_graph'];
    if (graph == null) {
      this.isLoadingCorporateStructureGraph = false;
      return;
    }
    const currentLinkIds = this.corporateStructureGraph['links'].map(
      link => link.id
    );
    const currentNodeIds = this.corporateStructureGraph['nodes'].map(
      node => node.id
    );
    const newLinksToAdd = graph['links'].filter(
      link => currentLinkIds.indexOf(link['id']) === -1
    );
    const newNodesToAdd = graph['nodes'].filter(
      node => currentNodeIds.indexOf(node['id']) === -1
    );
    // We need to create a new instance of the array in order to trigger change detection in the component:
    this.corporateStructureGraph['links'] =
      this.corporateStructureGraph['links'].concat(newLinksToAdd);
    this.corporateStructureGraph['nodes'] =
      this.corporateStructureGraph['nodes'].concat(newNodesToAdd);
    this.isLoadingCorporateStructureGraph = false;
  }

  // TODO Delete this method when we replace with the <corporate-structure-graph> component
  expandCorporateStructureUp(nodeId) {
    this.isLoadingCorporateStructureGraph = true;
    this.companyService
      .getExpandedCorporateStructure(<string>nodeId, 'ancestors')
      .subscribe(graphInfo =>
        this.addNodesAndLinksToCorporateStructure(graphInfo)
      );
  }

  // TODO Delete this method when we replace with the <corporate-structure-graph> component
  expandCorporateStructureDown(nodeId) {
    this.isLoadingCorporateStructureGraph = true;
    this.companyService
      .getExpandedCorporateStructure(<string>nodeId, 'descendants')
      .subscribe(graphInfo =>
        this.addNodesAndLinksToCorporateStructure(graphInfo)
      );
  }

  fitAndCentreRelatedPeopleGraph() {
    this.isRelatedPeopleGraphExpandedView =
      !this.isRelatedPeopleGraphExpandedView;
  }

  switchCorporateGraphType(value) {
    this.corporateGraphType = value;
    this.clustersPageLink = 'profile/' + this.companyId + '/clusters';
  }

  getCompanyGraphInformation(): void {
    if (this.pubMethods.isInternetExplorer() || this.pubMethods.isSafari()) {
      this.messageService.show(
        'Your browser is not able to display corporate structure graphs. Please upgrade to Chrome.'
      );
    } else {
      var id = this.route.snapshot.paramMap.get('id');
      if (
        !this.relatedPeopleGraph &&
        !this.corporateStructureGraph &&
        !this.isLoadingCorporateStructureGraph &&
        !this.isLoadingRelatedPeopleGraph
      ) {
        this.isLoadingCorporateStructureGraph = true;
        this.isLoadingRelatedPeopleGraph = true;
        this.companyId = id;
        this.companyService
          .getCorporateStructureGraph(<string>id)
          .subscribe(graphInfo =>
            this.updateCorporateStructureGraph(graphInfo)
          );
        this.companyService
          .getRelatedPeopleGraph(<string>id)
          .subscribe(graphInfo => this.updateRelatedPeopleGraphs(graphInfo));
      }
      if (this.supplierGraph === null) {
        // only get this on the first load.
        this.getSupplierGraph();
      }
    }
  }

  fullWebsiteSearch(): void {
    this.companyService
      .runFullWebsiteSearch(
        <string>this.companyId,
        <string>this.fullWebsiteSearchTerm
      )
      .subscribe(data => (this.fullWebsiteResults = data.matching_webpages));
  }

  accountsSearch(): void {
    this.companyService
      .searchCompanyAccounts(
        <string>this.companyId,
        <string>this.accountsSearchText,
        <string>this.accountsDateSelected
      )
      .subscribe(data => {
        this.accountsResults = data;
        this.accountsResults['accounts_results'].forEach(
          (account: Record<string, any>) => {
            account.processedPageNumber =
              this.processAccountsResultPageNumber(account);
          }
        );
      });
  }

  processAccountsResultPageNumber(
    accountObj: Record<string, any>
  ): string | number {
    if (!accountObj) return '';
    const { accounts_type, page_number } = accountObj;

    if (!accounts_type) return page_number;

    if (accounts_type === 'ocr') {
      const pageNumberPlusOne = Number(page_number) + 1;
      return pageNumberPlusOne;
    } else {
      return page_number;
    }
  }

  resetNewsArticles(): void {
    this.companyNewsSearch = '';
    this.filterNewsResultsFromSearch = false;
  }

  updateFromNewsSearch(data): void {
    this.companyNewsArticleUrlsMatchingSearch = data.article_urls;
    this.newsArticlesToSummaries = data.article_urls_to_summaries;
    this.filterNewsResultsFromSearch = true;
  }

  newsSearch(): void {
    this.companyService
      .searchCompanyNews(<string>this.companyId, <string>this.companyNewsSearch)
      .subscribe(data => this.updateFromNewsSearch(data));
  }

  resetSelfPublishedNewsArticles(): void {
    this.selfPublishedCompanyNewsSearchText = '';
    this.filterSelfPublishedNewsResultsFromSearch = false;
  }

  updateSelfPublishedNewsArticlesFromSearch(data): void {
    this.selfPublishedCompanyNewsArticleUrlsMatchingSearch = data.article_urls;
    this.selfPublishedNewsArticlesToSummaries = data.article_urls_to_summaries;
    this.filterSelfPublishedNewsResultsFromSearch = true;
  }

  selfPublishedCompanyNewsSearch(): void {
    this.companyService
      .searchSelfPublishedCompanyNews(
        <string>this.companyId,
        <string>this.selfPublishedCompanyNewsSearchText
      )
      .subscribe(data => this.updateSelfPublishedNewsArticlesFromSearch(data));
  }

  updateFromJobsSearch(data): void {
    this.jobsMatchingSearch = data.matching_jobs;
    this.filterJobResultsFromSearch = true;
  }

  resetJobSearch(): void {
    this.jobSearchText = '';
    this.jobsMatchingSearch = [];
    this.filterJobResultsFromSearch = false;
  }

  jobTextSearch(): void {
    this.companyService
      .searchCompanyJobs(<string>this.companyId, <string>this.jobSearchText)
      .subscribe(data => this.updateFromJobsSearch(data));
  }

  indvidualTagRemovedCheck(output) {
    if (output.total == 1) {
      this.getCompanyTags();
    } else {
      this.messageService.show('Could not remove tag.');
    }
  }

  removeTag(tagId): void {
    this.taggingService
      .tagOrUntagCompanies(tagId, [this.company['id']], null, false)
      .subscribe(output => this.indvidualTagRemovedCheck(output));
  }

  showUpdatedCustomAttrsMessage(): void {
    this.customAttrsUpdated = true;
    setTimeout(() => {
      this.customAttrsUpdated = false;
    }, 1500);
  }

  getAllGovernmentContracts(): void {
    this.companyService
      .getAllGovernmentContracts(<string>this.companyId)
      .subscribe(data => this.updateGovernmentContracts(data));
  }

  searchGovernmentContractsForCompany(): void {
    if (this.governmentContractsSearchTerm.length >= 3) {
      var searchTerm = this.governmentContractsSearchTerm.toLowerCase();
      this.filteredGovernmentContracts = this.allGovernmentContracts.filter(
        contract =>
          contract.title.toLowerCase().indexOf(searchTerm) >= 0 ||
          contract.description.toLowerCase().indexOf(searchTerm) >= 0
      );
    }
    if (this.governmentContractsSearchTerm.length === 0) {
      this.filteredGovernmentContracts = this.allGovernmentContracts;
    }
  }

  updateGovernmentContracts(data): void {
    this.allGovernmentContracts = data;
    this.filteredGovernmentContracts = this.allGovernmentContracts;
  }

  downloadAllGovernmentContracts(): void {
    window.open(
      baseUrl + '/download_government_contracts?companyNumber=' + this.companyId
    );
  }

  showPropensitySearchLink(propensityTag): boolean {
    const params = propensityTag.propensity_component_params;
    if (
      params.homepageSearchTerm ||
      params.fullWebsiteSearchTerm ||
      params.accountsSearchTerm ||
      params.newsContaining
    ) {
      return true;
    } else {
      return false;
    }
  }

  openSearchPreview(propensityTag): void {
    const params = propensityTag.propensity_component_params;

    if (params.homepageSearchTerm || params.fullWebsiteSearchTerm) {
      if (params.homepageSearchTerm) {
        this.fullWebsiteSearchTerm = params.homepageSearchTerm;
      }
      if (params.fullWebsiteSearchTerm) {
        this.fullWebsiteSearchTerm = params.fullWebsiteSearchTerm;
      }
      $(function () {
        $('#fullWebsiteSearchModal').modal();
      });
      this.fullWebsiteSearch();
    }

    if (params.accountsSearchTerm) {
      this.accountsSearchText = params.accountsSearchTerm;
      $(function () {
        $('#accountsSearchModal').modal();
      });
      this.accountsSearch();
    }

    if (params.newsContaining) {
      if (params.newsSource === 'selfPublished') {
        this.companyService.scrollToSelectorOnceExists(
          'selfPublishedCompanyNews'
        );
        this.selfPublishedCompanyNewsSearchText = params.newsContaining;
        this.selfPublishedCompanyNewsSearch();
      } else {
        this.companyService.scrollToSelectorOnceExists('companyNews');
        this.companyNewsSearch = params.newsContaining;
        this.newsSearch();
      }
    }
  }

  generateAiPitch(): void {
    this.companyService.validateAIPitchBuilderSettings().subscribe(data => {
      if (data.settings_configured === true) {
        if (data.counter === 0) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'You do not have any AI credits on your account. Please contact customer success to add more.'
          );
          zE('messenger', 'open');
        } else {
          this.getAiPitch();
        }
      } else {
        if (this.isSuperUser) {
          this.pubMethods.showWarningMessageWithoutAutoDisappear(
            'We were not able to auto-generate your AI configuration. Please navigate <a href="/pitch-builder">here</a> to configure.'
          );
        } else {
          this.pubMethods.showInfoMessage(
            'Please ask your superuser to configure your AI settings or contact customer success through chat for assistance.'
          );
        }
      }
    });
  }

  getAiPitch() {
    const source = new EventSource(
      baseUrl + '/get_ai_pitch/' + this.companyId,
      { withCredentials: true }
    );
    this.loadingStatus['loadingAIPitch'] = true;
    source.addEventListener('message', message => {
      if (message.data) {
        const data = JSON.parse(message.data);
        if (data.content) {
          this.companyAIPitch += data.content;
        }
      } else {
        this.loadingStatus['loadingAIPitch'] = false;
      }
    });

    source.onerror = err => {
      console.log('EventSource failed:', err);
      source.close();
      this.loadingStatus['loadingAIPitch'] = false;
    };
  }

  generateNewAccountSummary(yearAccountsId: string): void {
    this.companyService.requestAISummary(yearAccountsId).subscribe(data => {
      this.postSummarySubmission(data, yearAccountsId);
    });
  }

  postSummarySubmission(data, yearAccountsId): void {
    if (data['success']) {
      this.messageService.show(
        'Summary requested. You will receive an email when our analysis is complete.'
      );
      this.getCompanySummaries();

      this.aiAccountSummariesStatuses['hasGeneric'].push(
        parseInt(yearAccountsId)
      );
    }
  }

  getCompanySummaries(): void {
    /**
     * check the ai summary access
     * if has access, get the existing summaries
     * if summaries array has length, show the view summaries button
     * else show the request summaries button
     * */
    this.communicationService
      .getHasAIAccountsSummaryAccess()
      .subscribe(hasAIsummaryAccess => {
        this.hasAccessToAISummary = hasAIsummaryAccess;
        if (hasAIsummaryAccess) {
          this.companyService
            .getExistingAISummaries(this.companyId)
            .subscribe(data => {
              this.aiAccountsSummaries = data;
              this.getAISummariesStates();
            });
        }
      });
  }

  getAISummariesStates(): void {
    for (const yearAccountsId in this.aiAccountsSummaries) {
      if (
        this.aiAccountsSummaries[yearAccountsId].filter(
          summary => summary.type === 'generic'
        ).length > 0
      ) {
        this.aiAccountSummariesStatuses['hasGeneric'].push(
          parseInt(yearAccountsId)
        );
      }
    }
  }

  scrollToAnchor(): void {
    this.route.fragment.subscribe(anchor => {
      if (anchor) {
        this.companyService.scrollToSelectorOnceExists(anchor);
      }
    });
  }

  // todo show if user is already tracking in Zint Grow!
  followCompanyInZintGrow(): void {
    // todo allow user to select which one?
    if (this.zintGrowColumns.length) {
      const zintGrowColumnAdding = this.zintGrowColumns[0];
      this.hasColumnEditAccess = zintGrowColumnAdding.can_edit;
      if (!this.hasColumnEditAccess) {
        this.requestColumnEditAccessForZintGrow();
        return;
      }
      this.zintGrowSubscriptionsService
        .updateUserColumnCompany(
          zintGrowColumnAdding.id,
          this.company['id'],
          this.userId
        )
        .subscribe(data => {
          if (data.value === this.userId) {
            this.snackbar.open('Company added to Zint Grow!', 'X', {
              duration: 2200,
            });
            this.zintGrowColumnsFollowing.push(zintGrowColumnAdding);
          }
        });
    } else {
      // todo open Zendesk dialogue?!
    }
  }

  unfollowCompanyInZintGrow(): void {
    // todo allow user to select which one?
    if (this.zintGrowColumnsFollowing) {
      const zintGrowColumnRemoving = this.zintGrowColumnsFollowing[0];
      this.hasColumnEditAccess = zintGrowColumnRemoving.can_edit;
      if (!this.hasColumnEditAccess) {
        this.requestColumnEditAccessForZintGrow();
        return;
      }
      this.companyService
        .updateCustomOptionColumn(
          zintGrowColumnRemoving.id,
          this.company['id'],
          ''
        )
        .subscribe(data => {
          this.zintGrowColumnsFollowing = this.zintGrowColumnsFollowing.filter(
            col => col.id !== zintGrowColumnRemoving.id
          );
        });
    }
  }

  requestColumnEditAccessForZintGrow(): void {
    this.messageService.show(
      'You do not have permission to do this. Please ask your team superuser or contact customer success via chat.'
    );

    zE('messenger', 'open');
  }

  getObjectKeys(obj: Record<string, any>) {
    if (this.pubMethods.isEmptyObjectOrArray(obj)) return false;
    return Object.keys(obj);
  }
}
