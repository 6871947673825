import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'similar-company-card',
  templateUrl: './similar-company-card.component.html',
  styleUrls: ['./similar-company-card.component.css'],
})
export class SimilarCompanyCardComponent implements OnInit {
  constructor() {}

  @Input()
  inputParams: any = {
    similarOrg: Array,
  };

  ngOnInit() {}
}
