import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { HttpParams } from '@angular/common/http';
import { publicMethods } from '../../globals';
import { CommunicationService } from '../../services/communication.service';
import { DataSharingService } from '../../services/data-sharing.service';
import { TaggingService } from '../../services/tagging.service';
import { ActivatedRoute } from '@angular/router';
import { CustomColumnService } from '../../services/custom-column.service';
import { AlertsService } from '../../services/alerts.service';
import { ZintPropensityScoreService } from '../../services/zint-propensity-score.service';
import { SalesforceConfigService } from '../../services/salesforce-config.service';
import { ResultsFormatEditorService } from '../../services/results-format-editor.service';
import { TerritoryService } from '../../services/territory.service';
import { tangibleAssets, intangibleAssets } from '../../classes/asset-classes';
import {
  heatScore,
  ebitda,
  fxLossGain,
  corporationTax,
  taxCredit,
  wages,
} from '../../classes/zint-slider-configs';
import { IZintSliderElementUpdate } from '../../classes/types';
import { ZintNgxSliderComponent } from '../zint-ngx-slider/zint-ngx-slider.component';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private territoryService: TerritoryService,
    private communicationService: CommunicationService,
    private pubMethods: publicMethods,
    private data: DataSharingService,
    private taggingService: TaggingService,
    private customColumnService: CustomColumnService,
    private alertsService: AlertsService,
    private zintPropensityScoreService: ZintPropensityScoreService,
    private salesforceConfigService: SalesforceConfigService,
    private resultsFormatEditorService: ResultsFormatEditorService,
    private route: ActivatedRoute
  ) {}

  companies: Array<any> = [];
  numberUBOsRange: Array<any>;
  countriesOfUBOsOptions: Array<any>;
  countriesOfOriginOptions: Array<any>;
  countriesOfOfficesOptions: Array<any>;
  countriesOfGeographicalRevenueOptions: Array<any>;
  territoriesOfGeographicalRevenueOptions: Array<any>;
  geographicalRevenueOptions: Array<any>;
  ukRegions: Array<any>;
  companyCategoryOptions: Array<any>;
  // selected and excluded properties will be set and used to build selectedSICCodes and excludedSICCodes arrays
  companySICCodeOptions: Array<any>;
  companyStatusOptions: Array<any>;
  employeeRangeFigures: Array<any>;
  headcountGrowthPercentageRange: Array<number>;
  functionalDepartmentOptions: Array<any>;
  companyAgeRange: Array<any>;
  numberOfKnownOfficeLocationsRange: Array<any>;
  LEIStatusOptions: Array<any>;
  importExportCategories: Array<any>;
  importingExportingBothOptions: Array<any>;
  importExportMonthRange: Array<any>;
  importExportFrequencyRange: Array<any>;
  importExportFrequencyMonthCountRange: Array<any>;
  webTechnologyOptions: Array<any>;
  webTrafficOptions: Array<any>;
  averageDirectorAgeRange: Array<any>;
  numberOfDirectorsRange: Array<any>;
  numberOfPSCsRange: Array<any>;
  recentDirectorshipMonthRange: Array<any>;
  nationalitiesOfDirectorsOptions: Array<any>;
  nationalitiesOfPSCsOptions: Array<any>;
  daysUntilFinancialYearEndLimits: Array<any>;
  financialYearEndsOptions: Array<any>;
  daysUntilAnniversaryLimits: Array<any>;
  hiringDayLimits: Array<any>;
  numberOfRolesHiringForLimits: Array<any>;
  numberOfRolesHiringForDayLimits: Array<any>;
  investmentRaisedOptions: Array<any>;
  shareFilingEventWithinDaysOptions: Array<any>;
  shareFilingCapitalValueOptions: Array<any>;
  revenueOptions: Array<any>;
  netProfitOptions: Array<any>;
  cashAtBankOptions: Array<any>;
  debtorsOptions: Array<any>;
  creditorsOptions: Array<any>;
  currentAssetsOptions: Array<any>;
  fixedAssetsOptions: Array<any>;
  netAssetsOptions: Array<any>;
  accountsDueInRange: Array<any>;
  latestAccountsSubmittedRange: Array<any>;
  grossMarginLimits: Array<any>;
  assetsGrowthLimits: Array<any>;
  fixedAssetsGrowthLimits: Array<any>;
  revenueGrowthLimits: Array<any>;
  grossProfitGrowthLimits: Array<any>;
  grantRangeOptions: Array<any>;
  grantMonthsUntilProjectEndRange: Array<any>;
  mileRadiusRegisteredAddressRange: Array<any>;
  mileRadiusEventsRange: Array<any>;
  eventDaysRange: Array<any>;
  addressOptions: Array<any>;
  newsDaysRange: Array<any>;
  accountsSearchDayRange: Array<any>;
  trademarkClassOptions: Array<any>;
  trademarkStatusOptions: Array<any>;
  companyColumnsNotAllowedToFilter: Array<any>;
  tagsPositiveOptions: Array<any>;
  tagsNegativeOptions: Array<any>;
  crossSellTagOptions: Array<any>;
  alertOptions: Array<any>;
  teamMembers: Array<any> = [];
  teamMemberIdToCreateAlertFor: number = null;
  totalEnergyKwhOptions: Array<any>;
  gasKwhOptions: Array<any>;
  electricKwhOptions: Array<any>;
  scope1KwhOptions: Array<any>;
  scope2KwhOptions: Array<any>;
  scope3KwhOptions: Array<any>;
  totalEmissionsKgCo2eOptions: Array<any>;
  scope1KgCo2eOptions: Array<any>;
  scope2KgCo2eOptions: Array<any>;
  scope3KgCo2eOptions: Array<any>;
  kgCo2ePerMillionRevenueOptions: Array<any>;
  kgCo2ePerEmployeeOptions: Array<any>;
  patentFiledWithinDaysOptions: Array<any>;
  patentAwardedWithinDaysOptions: Array<any>;
  contractAwardedWithinMonthsOptions: Array<any>;
  contractStartMonthsOptions: Array<any>;
  contractEndMonthsOptions: Array<any>;
  contractValueOptions: Array<any>;
  chargeCreatedMonthLimits: Array<any>;
  companyNameContains = '';
  accountantNameContains = '';
  bankerNameContains = '';
  sicCode: string = ''; // are we using this?
  selectedCountries: Array<any> = [];
  selectedCompanyCategories: Array<any> = [];
  selectedSICCodes: Array<any> = [];
  excludedSICCodes: Array<any> = [];
  websiteKeywords: Array<any> = [];
  selectedCompanyStatuses: Array<any> = [];
  corporateStructureIsTopLevelParentCompany = false;
  corporateStructureHasParent = false;
  corporateStructureDoesNotHaveParent = false;
  corporateStructureHasChild = false;
  corporateStructureDoesNotHaveChild = false;
  lowerNumberOfUBOs: number = null;
  upperNumberOfUBOs: number = null;
  selectedUBOCountries: Array<any> = [];
  corporateStructureRelatedCompanies = '';
  hasWebsite = false;
  employeeLowerCount: number = null;
  employeeUpperCount: number = null;
  excludeEstimatedHeadcountFigures = false;
  departmentHeadCountLowerCount: number = null;
  departmentHeadCountUpperCount: number = null;
  lowerHeadCountGrowthPercentage: number = null;
  upperHeadCountGrowthPercentage: number = null;
  selectedFunctionalDepartments: Array<any> = [];
  lowerAge: number = null;
  upperAge: number = null;
  postcodeBeginsWith = '';
  postcodeWithinRadius: string = '';
  postcodeDistrict = '';
  ukRegion: string = '';
  selectedTownId = '';
  mileRadiusOfPostcode: number = null;
  selectedAddressTypeOption: string = '';
  hasOverseasParent = false;
  hasOfficesOverseas = false;
  selectedOfficeCountries: Array<any> = [];
  selectedGeographicalRevenueCountries: Array<any> = [];
  selectedGeographicalRevenueTerritories: Array<any> = [];
  lowerGeographicalRevenueSelected: number = null;
  upperGeographicalRevenueSelected: number = null;
  lowerNumberOfKnownOfficeLocations: number = null;
  upperNumberOfKnownOfficeLocations: number = null;
  hasLEINumber = false;
  selectedLEIStatus = '';
  selectedImportExportCategories: Array<any> = [];
  selectedImportingOrExportingOption = '';
  importExportTimeScale: number = null;
  importExportFrequencyImportingOrExporting = '';
  importExportFrequency: number = null;
  importExportFrequencyMonthCount: number = null;
  selectedWebTechnologies: Array<any> = [];
  webTrafficLow: number = null;
  webTrafficHigh: number = null;
  lowerAverageDirectorAgeRange: number = null;
  upperAverageDirectorAgeRange: number = null;
  lowerNumberOfDirectors: number = null;
  upperNumberOfDirectors: number = null;
  lowerNumberOfPSCs: number = null;
  upperNumberOfPSCs: number = null;
  recentDirectorshipTitleContains: string = '';
  lowerRecentDirectorshipMonth: number = null;
  upperRecentDirectorshipMonth: number = null;
  PSCsRealPersonsOnly = false;
  nationalitiesOfDirectorsSelected: Array<any> = [];
  nationalitiesOfPSCsSelected: Array<any> = [];
  directorJobTitleContains = '';
  lowerDaysUntilFinancialYearEnd: number = null;
  upperDaysUntilFinancialYearEnd: number = null;
  selectedFinancialYearEnds: Array<any> = [];
  lowerDaysUntilAnniversary: number = null;
  upperDaysUntilAnniversary: number = null;
  isHiring = false;
  hiringJobTitleContains = '';
  hiringDescriptionContains = '';
  selectedHiringDayLimit: number = null;
  lowerNumberOfRolesHiringFor: number = null;
  upperNumberOfRolesHiringFor: number = null;
  selectedNumberOfRolesHiringForDayLimit: number = null;
  includeRecruiters = false;
  amazonSeller = false;
  listedOnGCloud = false;
  hasPhoneNumber = false;
  hasEmailAddress = false;
  hasRaisedInvestment = false;
  hasShareFilingEvent = false;
  shareFilingEventWithinDays: number = null;
  lowerShareFilingCapitalValue: number = null;
  upperShareFilingCapitalValue: number = null;
  lowerInvestmentAmount: number = null;
  upperInvestmentAmount: number = null;
  ignoreRevenueEstimates = false;
  lowerRevenueSelected: number = null;
  upperRevenueSelected: number = null;
  lowerNetProfitSelected: number = null;
  upperNetProfitSelected: number = null;
  lowerCashAtBankSelected: number = null;
  upperCashAtBankSelected: number = null;
  lowerDebtorsSelected: number = null;
  upperDebtorsSelected: number = null;
  lowerCreditorsSelected: number = null;
  upperCreditorsSelected: number = null;
  lowerCurrentAssetsSelected: number = null;
  upperCurrentAssetsSelected: number = null;
  lowerFixedAssetsSelected: number = null;
  upperFixedAssetsSelected: number = null;
  lowerNetAssetsSelected: number = null;
  upperNetAssetsSelected: number = null;
  lowerAccountsDueSelected: number = null;
  upperAccountsDueSelected: number = null;
  lowerLatestAccountsSubmittedSelected: number = null;
  upperLatestAccountsSubmittedSelected: number = null;
  accountsAreOverdue = false;
  lowerGrossMargin: number = null;
  upperGrossMargin: number = null;
  lowerAnnualAssetsGrowthPercentage: number = null;
  upperAnnualAssetsGrowthPercentage: number = null;
  lowerAnnualFixedAssetsGrowthPercentage: number = null;
  upperAnnualFixedAssetsGrowthPercentage: number = null;
  lowerAnnualRevenueGrowthPercentage: number = null;
  upperAnnualRevenueGrowthPercentage: number = null;
  lowerAnnualGrossProfitGrowthPercentage: number = null;
  upperAnnualGrossProfitGrowthPercentage: number = null;
  accountsSearchTerm = '';
  accountsSearchWithinDays: number = null;
  issuedAGrant = false;
  chargeRegistered = false;
  outstandingChargeRegistered = false;
  lowerGrantAmount: number = null;
  upperGrantAmount: number = null;
  grantLowerMonthsUntilProjectEnd: number = null;
  grantUpperMonthsUntilProjectEnd: number = null;
  homepageSearchTerm = '';
  fullWebsiteSearchTerm = '';
  selectedEvents: Array<any> = [];
  mileRadiusOfEventsSelected: number = null;
  postcodeEventsWithinRadius = '';
  eventsWithinDaysLower: number = null;
  eventsWithinDaysUpper: number = null;
  newsWithinDays: number = null;
  newsContaining = '';
  newsSource = '';
  trademarkClassIdsSelected: Array<any> = [];
  trademarkDescriptionContains = '';
  trademarkNameContains = '';
  selectedTrademarkStatuses: Array<any> = [];
  excludeCompaniesTaggedByTeam = false;
  tagNegativeIdsSelected: Array<any> = [];
  tagPositiveIdsSelected: Array<any> = [];
  crossSellTagIdsSelected: Array<any> = [];
  crossSellLinkedThroughPeople = false;
  crossSellLinkedThroughCorporateStructure = false;
  selectedCustomColumns: Array<any> = [];
  selectedSalesforceObjects: Array<any> = [];
  unreadAlertsOnly = false;
  lowerAlertsDayRange: number = null;
  upperAlertsDayRange: number = null;
  alertIdsSelected: Array<any> = [];
  isLowEnergy = false;
  lowerTotalEnergyKwhSelected: number = null;
  upperTotalEnergyKwhSelected: number = null;
  lowerGasKwhSelected: number = null;
  upperGasKwhSelected: number = null;
  lowerElectricKwhSelected: number = null;
  upperElectricKwhSelected: number = null;
  lowerScope1KwhSelected: number = null;
  upperScope1KwhSelected: number = null;
  lowerScope2KwhSelected: number = null;
  upperScope2KwhSelected: number = null;
  lowerScope3KwhSelected: number = null;
  upperScope3KwhSelected: number = null;
  lowerTotalEmissionsKgCo2eSelected: number = null;
  upperTotalEmissionsKgCo2eSelected: number = null;
  lowerScope1KgCo2eSelected: number = null;
  upperScope1KgCo2eSelected: number = null;
  lowerScope2KgCo2eSelected: number = null;
  upperScope2KgCo2eSelected: number = null;
  lowerScope3KgCo2eSelected: number = null;
  upperScope3KgCo2eSelected: number = null;
  lowerKgCo2ePerMillionRevenueSelected: number = null;
  upperKgCo2ePerMillionRevenueSelected: number = null;
  lowerKgCo2ePerEmployeeSelected: number = null;
  upperKgCo2ePerEmployeeSelected: number = null;

  hasActivePatent = false;
  hasAnyPatentRecord = false;
  patentFiledWithinDays: number = null;
  lowerPatentAwardedDays: number = null;
  upperPatentAwardedDays: number = null;

  lowerContractValueSelected: number = null;
  upperContractValueSelected: number = null;
  contractAwardedWithinMonths: number = null;
  lowerContractStartMonthsSelected: number = null;
  upperContractStartMonthsSelected: number = null;
  lowerContractEndMonthsSelected: number = null;
  upperContractEndMonthsSelected: number = null;
  hasContract = false;
  contractTitleContains = '';
  contractDescriptionContains = '';

  chargeHolderNameContains = '';
  ignoreSatisfiedCharges = false;
  chargeCreatedMonthsAgoLower: number = null;
  chargeCreatedMonthsAgoUpper: number = null;

  supplierTagSelected: number = null;
  associateWithOneSupplierOnly = false;
  customerTagSelected: number = null;

  similarCompanyUrl = '';

  sortKey = '';
  sortDirectionIsAscending = false;

  companyCount: any = {};
  sicCodeTextSearch = '';
  isSearchingSicCodes = false;
  searchTags: Array<any> = [];
  importExportTextSearch = '';

  webTechsTextSearch = '';
  isSearchingWebTechs = false;

  checkboxSelectAllCompanies: boolean;
  hasSelectedTotalNumberMatchingFilter = false;
  showSelectedNumbersSection = false;
  totalCompaniesSelected: number = null;

  showSaveSearchForm = false;
  savedSearchName = '';
  makeSavedSearchShared = false;

  showSavePropensityScoreForm = false;
  propensityScoreComponentDescription = '';
  propensityWeightingRange: Array<any> = [];
  propensityTAMLimitCount: number = null;
  propensityScoreWeighting: number = null;
  propensityModels: Array<any> = [];
  editablePropensityModels: Array<any> = [];
  selectedPropensityModelId: number = null;
  propensityScoreTAMName = '';
  stringToTriggerNewPropensityModel = 'STRINGTOTRIGGERNEWPROPENSITYMODEL';
  canEditTAM = false;
  selectedPropensityModelIdToUpdateTAM: number = null;

  showCreateAlertForm = false;
  savedAlertName = '';
  selectedAlertFrequency = '';
  alertFrequencyOptions: Array<any> = [];
  jsonParamKeysNotAllowedForAlertsOrPropensityScore: Array<any> = [];
  alertsDaysRange: Array<any>;

  showTerritoryForm = false;
  territoryName = '';

  hasSemanticSearchAccess: boolean = false;
  summarySemanticSearchTerm: string = '';
  summarySemanticSearchExclude: string = '';

  hasAISearchAccess: boolean = false;

  columnsToHide: Array<any> = [
    'id',
    'number',
    'isPlaceholderCompany',
    'hasViewed',
  ];

  pageNumber = 1;
  companiesPerPage = 50;
  maxPageNumber: number = null;

  isSuperUser: boolean;
  emailOnAccount: any;
  isReadOnlyUser: any;
  hasPropensityScoreAccess: any;
  hasDeepCrawlAccess: any;
  hasEnergyAndEmissionsAccess: any;
  hasPatentsAccess: any;
  hasShareFilingsAccess: any;
  hasGovernmentContractsAccess: any;
  hasCompanyChargesAccess: any;
  hasCrossSellAccess: any;
  hasRecentAccountsAccess: any;
  hasSupplierDataAccessMessage: any;
  hasTerritoryAssignmentAccessMessage: any;
  hasSalesforceAccessMessage: any;
  hasSalesforceV2AccessMessage: any;
  hasHubspotAccess: any;
  hasCustomInsightsAccess: any;
  salesforceObjects: any;

  isSidebarCollapsed = false;

  townName: string = '';
  possibleTownNames: Array<any>;

  websiteKeywordInput: string = '';
  possibleWebsiteKeywords: Array<any>;

  eventNameSearchInput: string = '';
  possibleMatchingEvents: Array<any>;

  customColumns: Array<any>;
  customColumnNamesToObjects: any = {};

  currentHoverColumnName = '';
  currentHoverRowName = '';
  currentlyEditingColumnName = '';
  currentlyEditingRowName = '';

  tableHeaderColumnNumberCurrentlyClicked: number = null;
  tableColumnNumberCurrentlyHovered: number = null;
  wrappedTableColumnNumbers: Array<number> =
    JSON.parse(localStorage.getItem('zintWrappedColumns')) || [];
  teamMembersForCustomColumns: Array<any> = [];
  columnNamesToWidths: any = {};
  clickedRow: number = null;
  clickedColumn: number = null;

  geographyPostcodeBeginsWithIsEditing = false;
  geographyPostcodeDistrictIsEditing = false;
  geographyWithinRadiusOfPostcodeIsEditing = false;
  geographyWithinRadiusOfTownIsEditing = false;

  selectAllVisibleFunctionalDepartments = false;
  functionalDepartmentTextSearch = '';
  isSearchingFunctionalDepartments = false;
  hasSearchedFunctionalDepartment = false;

  geographicalRevenueTextSearch = '';

  individualViews: Array<any> = [];
  organisationViews: Array<any> = [];
  selectedView: Record<string, any> = null;
  loadResultsView = false;

  availableTerritories: Array<any> = [];
  areTerritoriesEnforced: false;
  selectedTerritory: Record<string, any> = null;

  sliderValues: Record<string, any> = {};
  savedSearchParamName: string = '';

  @ViewChild('numberUBOsSliderElement')
  numberUBOsSliderElement: ZintNgxSliderComponent;
  @ViewChild('companyAgeSliderElement')
  companyAgeSliderElement: ZintNgxSliderComponent;
  @ViewChild('mileRadiusSliderElement')
  mileRadiusSliderElement: ZintNgxSliderComponent;
  @ViewChild('numberOfKnownOfficeLocationsSliderElement')
  numberOfKnownOfficeLocationsSliderElement: ZintNgxSliderComponent;
  @ViewChild('geographicalRevenueSliderElement')
  geographicalRevenueSliderElement: ZintNgxSliderComponent;
  @ViewChild('revenueSliderElement')
  revenueSliderElement: ZintNgxSliderComponent;
  @ViewChild('netProfitSliderElement')
  netProfitSliderElement: ZintNgxSliderComponent;
  @ViewChild('cashAtBankSliderElement')
  cashAtBankSliderElement: ZintNgxSliderComponent;
  @ViewChild('debtorsSliderElement')
  debtorsSliderElement: ZintNgxSliderComponent;
  @ViewChild('creditorsSliderElement')
  creditorsSliderElement: ZintNgxSliderComponent;
  @ViewChild('currentAssetsSliderElement')
  currentAssetsSliderElement: ZintNgxSliderComponent;
  @ViewChild('fixedAssetsSliderElement')
  fixedAssetsSliderElement: ZintNgxSliderComponent;
  @ViewChild('netAssetsSliderElement')
  netAssetsSliderElement: ZintNgxSliderComponent;
  @ViewChild('accountsDueSliderElement')
  accountsDueSliderElement: ZintNgxSliderComponent;
  @ViewChild('latestAccountsSubmittedSliderElement')
  latestAccountsSubmittedSliderElement: ZintNgxSliderComponent;
  @ViewChild('grossMarginSliderElement')
  grossMarginSliderElement: ZintNgxSliderComponent;
  @ViewChild('assetsGrowthSliderElement')
  assetsGrowthSliderElement: ZintNgxSliderComponent;
  @ViewChild('fixedAssetsGrowthSliderElement')
  fixedAssetsGrowthSliderElement: ZintNgxSliderComponent;
  @ViewChild('revenueGrowthSliderElement')
  revenueGrowthSliderElement: ZintNgxSliderComponent;
  @ViewChild('grossProfitGrowthSliderElement')
  grossProfitGrowthSliderElement: ZintNgxSliderComponent;
  @ViewChild('investmentRaisedSliderElement')
  investmentRaisedSliderElement: ZintNgxSliderComponent;
  @ViewChild('shareFilingEventWithinDaysSliderElement')
  shareFilingEventWithinDaysSliderElement: ZintNgxSliderComponent;
  @ViewChild('shareFilingCapitalValueSliderElement')
  shareFilingCapitalValueSliderElement: ZintNgxSliderComponent;
  @ViewChild('grantAmountSliderElement')
  grantAmountSliderElement: ZintNgxSliderComponent;
  @ViewChild('grantMonthsUntilProjectEndSliderElement')
  grantMonthsUntilProjectEndSliderElement: ZintNgxSliderComponent;
  @ViewChild('headcountSliderElement')
  headcountSliderElement: ZintNgxSliderComponent;
  @ViewChild('headcountGrowthSliderElement')
  headcountGrowthSliderElement: ZintNgxSliderComponent;
  @ViewChild('departmentHeadcountSliderElement')
  departmentHeadcountSliderElement: ZintNgxSliderComponent;
  @ViewChild('averageDirectorAgeSliderElement')
  averageDirectorAgeSliderElement: ZintNgxSliderComponent;
  @ViewChild('numberOfDirectorsSliderElement')
  numberOfDirectorsSliderElement: ZintNgxSliderComponent;
  @ViewChild('numberOfPSCsSliderElement')
  numberOfPSCsSliderElement: ZintNgxSliderComponent;
  @ViewChild('recentDirectorshipMonthRangeSliderElement')
  recentDirectorshipMonthRangeSliderElement: ZintNgxSliderComponent;
  @ViewChild('importExportSliderElement')
  importExportSliderElement: ZintNgxSliderComponent;
  @ViewChild('importExportFrequencySliderElement')
  importExportFrequencySliderElement: ZintNgxSliderComponent;
  @ViewChild('importExportFrequencyMonthCountSliderElement')
  importExportFrequencyMonthCountSliderElement: ZintNgxSliderComponent;
  @ViewChild('websiteVisitorsSliderElement')
  websiteVisitorsSliderElement: ZintNgxSliderComponent;
  @ViewChild('hiringDayLimitSliderElement')
  hiringDayLimitSliderElement: ZintNgxSliderComponent;
  @ViewChild('numberOfRolesHiringForSliderElement')
  numberOfRolesHiringForSliderElement: ZintNgxSliderComponent;
  @ViewChild('numberOfRolesHiringForDayLimitSliderElement')
  numberOfRolesHiringForDayLimitSliderElement: ZintNgxSliderComponent;
  @ViewChild('daysUntilFinancialYearEndSliderElement')
  daysUntilFinancialYearEndSliderElement: ZintNgxSliderComponent;
  @ViewChild('daysUntilAnniversarySliderElement')
  daysUntilAnniversarySliderElement: ZintNgxSliderComponent;
  @ViewChild('eventsMileRadiusSliderElement')
  eventsMileRadiusSliderElement: ZintNgxSliderComponent;
  @ViewChild('eventsDayRangeSliderElement')
  eventsDayRangeSliderElement: ZintNgxSliderComponent;
  @ViewChild('newsDayRangeSliderElement')
  newsDayRangeSliderElement: ZintNgxSliderComponent;
  @ViewChild('accountsSearchDayRangeSliderElement')
  accountsSearchDayRangeSliderElement: ZintNgxSliderComponent;
  @ViewChild('alertsDayRangeSliderElement')
  alertsDayRangeSliderElement: ZintNgxSliderComponent;

  @ViewChild('totalEnergyKwhSliderElement')
  totalEnergyKwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('gasKwhSliderElement')
  gasKwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('electricKwhSliderElement')
  electricKwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope1KwhSliderElement')
  scope1KwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope2KwhSliderElement')
  scope2KwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope3KwhSliderElement')
  scope3KwhSliderElement: ZintNgxSliderComponent;
  @ViewChild('totalEmissionsKgCo2eSliderElement')
  totalEmissionsKgCo2eSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope1KgCo2eSliderElement')
  scope1KgCo2eSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope2KgCo2eSliderElement')
  scope2KgCo2eSliderElement: ZintNgxSliderComponent;
  @ViewChild('scope3KgCo2eSliderElement')
  scope3KgCo2eSliderElement: ZintNgxSliderComponent;
  @ViewChild('kgCo2ePerMillionRevenueSliderElement')
  kgCo2ePerMillionRevenueSliderElement: ZintNgxSliderComponent;
  @ViewChild('kgCo2ePerEmployeeSliderElement')
  kgCo2ePerEmployeeSliderElement: ZintNgxSliderComponent;
  @ViewChild('patentFiledWithinDaysSliderElement')
  patentFiledWithinDaysSliderElement: ZintNgxSliderComponent;
  @ViewChild('patentAwardedWithinDaysSliderElement')
  patentAwardedWithinDaysSliderElement: ZintNgxSliderComponent;
  @ViewChild('contractValueSliderElement')
  contractValueSliderElement: ZintNgxSliderComponent;
  @ViewChild('contractAwardedWithinMonthsSliderElement')
  contractAwardedWithinMonthsSliderElement: ZintNgxSliderComponent;
  @ViewChild('contractStartMonthsSliderElement')
  contractStartMonthsSliderElement: ZintNgxSliderComponent;
  @ViewChild('contractEndMonthsSliderElement')
  contractEndMonthsSliderElement: ZintNgxSliderComponent;
  @ViewChild('chargeCreatedSliderElement')
  chargeCreatedSliderElement: ZintNgxSliderComponent;

  @ViewChildren('heatScoreSliders')
  heatScoreSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('ebitdaSliders')
  ebitdaSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('fxLossGainSliders')
  fxLossGainSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('corporationTaxSliders')
  corporationTaxSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('taxCreditSliders')
  taxCreditSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('wagesSliders')
  wagesSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('tanAssetsSliders')
  tanAssetsSliders: QueryList<ZintNgxSliderComponent>;
  @ViewChildren('intanAssetsSliders')
  intanAssetsSliders: QueryList<ZintNgxSliderComponent>;

  allConfigSlidersChildren: ZintNgxSliderComponent[];

  isLoading = this.dashboardService.isLoading;
  isLoadingZintPropensityScore = this.zintPropensityScoreService.isLoading;

  hasLoadedUIData = false;
  hasLoadedUserTags = false;
  hasLoadedUserCrossSellTags = false;
  hasLoadedCustomColumns = false;
  hasLoadedAlertOptions = false;
  hasLoadedSalesforceOptions = false;

  freezeHeadersIsActive = false;
  companyResultsTableHeaderRowMarginLeft = 0;
  isCompanyResultsTableHeaderRowFixed = false;
  resultTableScrollLeft: number = 0;
  showFakeCompanyNameAndCheckboxSection = false;
  horizontalScrollCutoffToShowFakeHeaders = 14;
  lastTableHorizontalPosition = 0;
  startingHeaderRowOffsetTop: number = null;

  zintSlidersConfigs: Record<string, any> = {};
  sliderFieldLabels: string[] = [];

  keyToSliderElementMap: Record<string, any> = {};

  ngOnInit() {
    this.zintSlidersConfigs = {
      tangibleAssets,
      intangibleAssets,
      heatScore,
      ebitda,
      fxLossGain,
      corporationTax,
      wages,
      taxCredit,
    };
    this.sliderFieldLabels = Object.values(this.zintSlidersConfigs)
      .flat()
      .map(asset => asset.label);

    this.bindScrollBars();
    this.updateScrollBarDimensions();
    this.getUIDataAndUpdateCompanies(); // this is the master function. updates all UI components and updates companies.
    this.taggingService
      .getUserTags(false)
      .subscribe(output => this.updateTagOptions(output));
    this.taggingService
      .getUserCrossSellTags(false)
      .subscribe(output => this.updateCrossSellTagOptions(output));
    this.customColumnService
      .getCustomColumns()
      .subscribe(output => this.updateCustomColumnsAndColumnWidths(output));
    this.alertsService
      .getBasicAlertDetails()
      .subscribe(output => this.updateAlerts(output));
    this.zintPropensityScoreService
      .getUserPropensityScoreDetails(false)
      .subscribe(output => this.updateZintPropensityScoreDetails(output));
    this.resultsFormatEditorService
      .getBasicUserResultsFormat()
      .subscribe(data => this.updateAvailableUserViews(data));
    this.companyCount['count'] = 5100000;
    this.companyCount['exact'] = false;
    this.getCompanyCount(true);
    this.communicationService.getIsSuperUserMessage().subscribe(isSuperUser => {
      this.isSuperUser = isSuperUser;
    });
    this.communicationService.getEmailMessage().subscribe(accountEmail => {
      this.emailOnAccount = accountEmail;
    });
    this.communicationService
      .getIsReadOnlyUserMessage()
      .subscribe(isReadOnlyUser => {
        this.isReadOnlyUser = isReadOnlyUser;
      });
    this.communicationService
      .getHasPropensityScoreAccessMessage()
      .subscribe(hasPropensityScoreAccess => {
        this.hasPropensityScoreAccess = hasPropensityScoreAccess;
      });
    this.communicationService
      .getHasDeepCrawlAccessMessage()
      .subscribe(hasDeepCrawlAccess => {
        this.hasDeepCrawlAccess = hasDeepCrawlAccess;
      });
    this.communicationService
      .getHasEnergyAndEmissionsAccessMessage()
      .subscribe(hasEnergyAndEmissionsAccess => {
        this.hasEnergyAndEmissionsAccess = hasEnergyAndEmissionsAccess;
      });
    this.communicationService
      .getHasPatentsAccessMessage()
      .subscribe(hasPatentsAccess => {
        this.hasPatentsAccess = hasPatentsAccess;
      });
    this.communicationService
      .getHasShareFilingsAccessMessage()
      .subscribe(hasShareFilingsAccess => {
        this.hasShareFilingsAccess = hasShareFilingsAccess;
      });
    this.communicationService
      .getHasGovernmentContractsAccessMessage()
      .subscribe(hasGovernmentContractsAccess => {
        this.hasGovernmentContractsAccess = hasGovernmentContractsAccess;
      });
    this.communicationService
      .getHasCompanyChargesAccessAccessMessage()
      .subscribe(hasCompanyChargesAccess => {
        this.hasCompanyChargesAccess = hasCompanyChargesAccess;
      });
    this.communicationService
      .getHasCrossSellAccessMessage()
      .subscribe(hasCrossSellAccess => {
        this.hasCrossSellAccess = hasCrossSellAccess;
      });
    this.communicationService
      .getHasRecentAccountsAccessMessage()
      .subscribe(hasRecentAccountsAccess => {
        this.hasRecentAccountsAccess = hasRecentAccountsAccess;
      });
    this.communicationService
      .getHasSupplierDataAccessMessage()
      .subscribe(hasSupplierDataAccessMessage => {
        this.hasSupplierDataAccessMessage = hasSupplierDataAccessMessage;
      });
    this.communicationService
      .getHasSalesforceAccessMessage()
      .subscribe(hasSalesforceAccessMessage => {
        this.hasSalesforceAccessMessage = hasSalesforceAccessMessage;
      });
    this.communicationService
      .getHasSalesforceV2AccessMessage()
      .subscribe(hasSalesforceV2AccessMessage => {
        this.updateHasSalesforceV2AccessMessageAndGetSalesforceObjects(
          hasSalesforceV2AccessMessage
        );
      });
    this.communicationService.getHubspotAccess().subscribe(hasHubspotAccess => {
      this.hasHubspotAccess = hasHubspotAccess;
    });
    this.communicationService
      .getHasCustomInsightsAccessMessage()
      .subscribe(hasCustomInsightsAccess => {
        this.hasCustomInsightsAccess = hasCustomInsightsAccess;
      });
    this.communicationService
      .getHasTerritoryAssignmentAccessMessage()
      .subscribe(hasTerritoryAssignmentAccessMessage => {
        this.updateTerritoryAssignmentDetails(
          hasTerritoryAssignmentAccessMessage
        );
      });
    this.communicationService
      .getHasSemanticSearchAccessMessage()
      .subscribe(hasSemanticSearchAccessMessage => {
        this.hasSemanticSearchAccess = hasSemanticSearchAccessMessage;
      });
    this.communicationService
      .getAISearchAccess()
      .subscribe(hasAISearchAccess => {
        this.hasAISearchAccess = hasAISearchAccess;
      });
    this.pubMethods.showIEWarningIfRequired();
  }

  updateAvailableUserViews(data) {
    this.individualViews = data.individual;
    this.organisationViews = data.organisation;
  }

  toggleFixedHeaders() {
    if (this.freezeHeadersIsActive) {
      this.disableFixedHeaders();
    } else {
      this.enableFixedHeaders();
    }
    this.freezeHeadersIsActive = !this.freezeHeadersIsActive;
  }

  enableFixedHeaders() {
    const self = this;
    $(window).on('scroll', function () {
      self.wholeWindowScrollEvent(self);
    });
    $('#table-results-section').on('scroll', function () {
      self.tableWithinWindowScrollEvent(self);
    });
  }

  disableFixedHeaders() {
    $(window).off('scroll');
    $('#table-results-section').off('scroll');
  }

  isTheWindowScrolledLowEnoughToWarrantFixedHeaderRow(): boolean {
    if (this.startingHeaderRowOffsetTop == null) {
      this.startingHeaderRowOffsetTop = $(
        '#company-results-table-header'
      ).offset().top;
    }
    return $(window).scrollTop() > this.startingHeaderRowOffsetTop;
  }

  // VERTICAL SCROLL.
  wholeWindowScrollEvent(self) {
    if (self.isTheWindowScrolledLowEnoughToWarrantFixedHeaderRow()) {
      // we have scrolled vertically down to the point at which we should show the header fixed.
      self.isCompanyResultsTableHeaderRowFixed = true;
      self.resultTableScrollLeft = $('#table-results-section').scrollLeft();
      self.companyResultsTableHeaderRowMarginLeft =
        -1 * self.resultTableScrollLeft;
      if (
        self.resultTableScrollLeft >
        self.horizontalScrollCutoffToShowFakeHeaders
      ) {
        // if our horizontal scroll is across far enough, we need to ensure we show the fake company name and checkbox header.
        self.showFakeCompanyNameAndCheckboxSection = true;
      }
    } else {
      // we are scrolling vertically but above the table.
      self.isCompanyResultsTableHeaderRowFixed = false;
      self.showFakeCompanyNameAndCheckboxSection = false;
      self.companyResultsTableHeaderRowMarginLeft = 0;
    }
  }
  // HORIZONTAL SCROLL.
  tableWithinWindowScrollEvent(self) {
    self.resultTableScrollLeft = $('#table-results-section').scrollLeft();
    if (self.lastTableHorizontalPosition != self.resultTableScrollLeft) {
      if (self.isTheWindowScrolledLowEnoughToWarrantFixedHeaderRow()) {
        // we are scrolling horizontally and the header is fixed.
        self.showFakeCompanyNameAndCheckboxSection =
          self.resultTableScrollLeft >
          self.horizontalScrollCutoffToShowFakeHeaders;
        self.companyResultsTableHeaderRowMarginLeft =
          -1 * self.resultTableScrollLeft;
        self.lastTableHorizontalPosition = self.resultTableScrollLeft;
      }
    }
  }

  bindScrollBars() {
    const self = this;
    $(document).ready(function () {
      $(window).resize(function () {
        self.updateScrollBarDimensions();
      });
    });
    $(function () {
      $('#table-results-section').scroll(function () {
        $('.dummy-top-scrollbar-wrapper').scrollLeft(
          $('#table-results-section').scrollLeft()
        );
      });
      $('.dummy-top-scrollbar-wrapper').mouseup(function () {
        $('#table-results-section').scrollLeft(
          $('.dummy-top-scrollbar-wrapper').scrollLeft()
        );
      });
    });
  }

  mapKeyToSliderElement(): void {
    // this map is to keep the seeIfNeedToUpdateSlider method DRY
    this.keyToSliderElementMap = {
      lowerNumberOfUBOs: this.numberUBOsSliderElement,
      upperNumberOfUBOs: this.numberUBOsSliderElement,
      lowerAge: this.companyAgeSliderElement,
      upperAge: this.companyAgeSliderElement,
      employeeLowerCount: this.headcountSliderElement,
      employeeUpperCount: this.headcountSliderElement,
      lowerHeadCountGrowthPercentage: this.headcountGrowthSliderElement,
      upperHeadCountGrowthPercentage: this.headcountGrowthSliderElement,
      departmentHeadCountLowerCount: this.departmentHeadcountSliderElement,
      departmentHeadCountUpperCount: this.departmentHeadcountSliderElement,
      mileRadiusOfPostcode: this.mileRadiusSliderElement,
      lowerNumberOfKnownOfficeLocations:
        this.numberOfKnownOfficeLocationsSliderElement,
      upperNumberOfKnownOfficeLocations:
        this.numberOfKnownOfficeLocationsSliderElement,
      lowerGeographicalRevenueSelected: this.geographicalRevenueSliderElement,
      upperGeographicalRevenueSelected: this.geographicalRevenueSliderElement,
      importExportTimeScale: this.importExportSliderElement,
      importExportFrequency: this.importExportFrequencySliderElement,
      importExportFrequencyMonthCount:
        this.importExportFrequencyMonthCountSliderElement,
      webTrafficLow: this.websiteVisitorsSliderElement,
      webTrafficHigh: this.websiteVisitorsSliderElement,
      lowerAverageDirectorAgeRange: this.averageDirectorAgeSliderElement,
      upperAverageDirectorAgeRange: this.averageDirectorAgeSliderElement,
      lowerNumberOfDirectors: this.numberOfDirectorsSliderElement,
      upperNumberOfDirectors: this.numberOfDirectorsSliderElement,
      lowerNumberOfPSCs: this.numberOfPSCsSliderElement,
      upperNumberOfPSCs: this.numberOfPSCsSliderElement,
      lowerRecentDirectorshipMonth:
        this.recentDirectorshipMonthRangeSliderElement,
      upperRecentDirectorshipMonth:
        this.recentDirectorshipMonthRangeSliderElement,
      lowerDaysUntilFinancialYearEnd:
        this.daysUntilFinancialYearEndSliderElement,
      upperDaysUntilFinancialYearEnd:
        this.daysUntilFinancialYearEndSliderElement,
      lowerDaysUntilAnniversary: this.daysUntilAnniversarySliderElement,
      upperDaysUntilAnniversary: this.daysUntilAnniversarySliderElement,
      selectedHiringDayLimit: this.hiringDayLimitSliderElement,
      lowerNumberOfRolesHiringFor: this.numberOfRolesHiringForSliderElement,
      upperNumberOfRolesHiringFor: this.numberOfRolesHiringForSliderElement,
      selectedNumberOfRolesHiringForDayLimit:
        this.numberOfRolesHiringForDayLimitSliderElement,
      lowerInvestmentAmount: this.investmentRaisedSliderElement,
      upperInvestmentAmount: this.investmentRaisedSliderElement,
      shareFilingEventWithinDays: this.shareFilingEventWithinDaysSliderElement,
      lowerShareFilingCapitalValue: this.shareFilingCapitalValueSliderElement,
      upperShareFilingCapitalValue: this.shareFilingCapitalValueSliderElement,
      lowerRevenueSelected: this.revenueSliderElement,
      upperRevenueSelected: this.revenueSliderElement,
      lowerNetProfitSelected: this.netProfitSliderElement,
      upperNetProfitSelected: this.netProfitSliderElement,
      lowerCashAtBankSelected: this.cashAtBankSliderElement,
      upperCashAtBankSelected: this.cashAtBankSliderElement,
      lowerDebtorsSelected: this.debtorsSliderElement,
      upperDebtorsSelected: this.debtorsSliderElement,
      lowerCreditorsSelected: this.creditorsSliderElement,
      upperCreditorsSelected: this.creditorsSliderElement,
      lowerCurrentAssetsSelected: this.currentAssetsSliderElement,
      upperCurrentAssetsSelected: this.currentAssetsSliderElement,
      lowerFixedAssetsSelected: this.fixedAssetsSliderElement,
      upperFixedAssetsSelected: this.fixedAssetsSliderElement,
      lowerNetAssetsSelected: this.netAssetsSliderElement,
      upperNetAssetsSelected: this.netAssetsSliderElement,
      lowerAccountsDueSelected: this.accountsDueSliderElement,
      upperAccountsDueSelected: this.accountsDueSliderElement,
      lowerLatestAccountsSubmittedSelected:
        this.latestAccountsSubmittedSliderElement,
      upperLatestAccountsSubmittedSelected:
        this.latestAccountsSubmittedSliderElement,
      lowerGrossMargin: this.grossMarginSliderElement,
      upperGrossMargin: this.grossMarginSliderElement,
      lowerAnnualAssetsGrowthPercentage: this.assetsGrowthSliderElement,
      upperAnnualAssetsGrowthPercentage: this.assetsGrowthSliderElement,
      lowerAnnualFixedAssetsGrowthPercentage:
        this.fixedAssetsGrowthSliderElement,
      upperAnnualFixedAssetsGrowthPercentage:
        this.fixedAssetsGrowthSliderElement,
      lowerAnnualRevenueGrowthPercentage: this.revenueGrowthSliderElement,
      upperAnnualRevenueGrowthPercentage: this.revenueGrowthSliderElement,
      lowerAnnualGrossProfitGrowthPercentage:
        this.grossProfitGrowthSliderElement,
      upperAnnualGrossProfitGrowthPercentage:
        this.grossProfitGrowthSliderElement,
      lowerGrantAmount: this.grantAmountSliderElement,
      upperGrantAmount: this.grantAmountSliderElement,
      grantLowerMonthsUntilProjectEnd:
        this.grantMonthsUntilProjectEndSliderElement,
      grantUpperMonthsUntilProjectEnd:
        this.grantMonthsUntilProjectEndSliderElement,
      mileRadiusOfEventsSelected: this.eventsMileRadiusSliderElement,
      eventsWithinDaysLower: this.eventsDayRangeSliderElement,
      eventsWithinDaysUpper: this.eventsDayRangeSliderElement,
      newsWithinDays: this.newsDayRangeSliderElement,
      accountsSearchWithinDays: this.accountsSearchDayRangeSliderElement,
      lowerAlertsDayRange: this.alertsDayRangeSliderElement,
      upperAlertsDayRange: this.alertsDayRangeSliderElement,
      lowerTotalEnergyKwhSelected: this.totalEnergyKwhSliderElement,
      upperTotalEnergyKwhSelected: this.totalEnergyKwhSliderElement,
      lowerGasKwhSelected: this.gasKwhSliderElement,
      upperGasKwhSelected: this.gasKwhSliderElement,
      lowerElectricKwhSelected: this.electricKwhSliderElement,
      upperElectricKwhSelected: this.electricKwhSliderElement,
      lowerScope1KwhSelected: this.scope1KwhSliderElement,
      upperScope1KwhSelected: this.scope1KwhSliderElement,
      lowerScope2KwhSelected: this.scope2KwhSliderElement,
      upperScope2KwhSelected: this.scope2KwhSliderElement,
      lowerScope3KwhSelected: this.scope3KwhSliderElement,
      upperScope3KwhSelected: this.scope3KwhSliderElement,
      lowerTotalEmissionsKgCo2eSelected: this.totalEmissionsKgCo2eSliderElement,
      upperTotalEmissionsKgCo2eSelected: this.totalEmissionsKgCo2eSliderElement,
      lowerScope1KgCo2eSelected: this.scope1KgCo2eSliderElement,
      upperScope1KgCo2eSelected: this.scope1KgCo2eSliderElement,
      lowerScope2KgCo2eSelected: this.scope2KgCo2eSliderElement,
      upperScope2KgCo2eSelected: this.scope2KgCo2eSliderElement,
      lowerScope3KgCo2eSelected: this.scope3KgCo2eSliderElement,
      upperScope3KgCo2eSelected: this.scope3KgCo2eSliderElement,
      lowerKgCo2ePerMillionRevenueSelected:
        this.kgCo2ePerMillionRevenueSliderElement,
      upperKgCo2ePerMillionRevenueSelected:
        this.kgCo2ePerMillionRevenueSliderElement,
      lowerKgCo2ePerEmployeeSelected: this.kgCo2ePerEmployeeSliderElement,
      upperKgCo2ePerEmployeeSelected: this.kgCo2ePerEmployeeSliderElement,
      patentFiledWithinDays: this.patentFiledWithinDaysSliderElement,
      lowerPatentAwardedDays: this.patentAwardedWithinDaysSliderElement,
      upperPatentAwardedDays: this.patentAwardedWithinDaysSliderElement,
      lowerContractValueSelected: this.contractValueSliderElement,
      upperContractValueSelected: this.contractValueSliderElement,
      contractAwardedWithinMonths:
        this.contractAwardedWithinMonthsSliderElement,
      lowerContractStartMonthsSelected: this.contractStartMonthsSliderElement,
      upperContractStartMonthsSelected: this.contractStartMonthsSliderElement,
      lowerContractEndMonthsSelected: this.contractEndMonthsSliderElement,
      upperContractEndMonthsSelected: this.contractEndMonthsSliderElement,
      chargeCreatedMonthsAgoLower: this.chargeCreatedSliderElement,
      chargeCreatedMonthsAgoUpper: this.chargeCreatedSliderElement,
      // ... add any other mappings that are required
    };
  }

  isUpperSlider(key: string): boolean {
    /* check upper as the single sliders now use upperValue */
    const lowerCaseKey = key.toLowerCase();
    if (lowerCaseKey.includes('webtraffic')) {
      // for webTrafficLow and High case
      return !lowerCaseKey.includes('low');
    } else {
      // all other cases
      return !lowerCaseKey.includes('lower');
    }
  }

  updateZintSliderElement(sliderToUpdate: IZintSliderElementUpdate): void {
    const { value, key } = sliderToUpdate;
    const isUpper = this.isUpperSlider(key);
    const sliderElement = this.keyToSliderElementMap[key];
    if (!sliderElement) return;
    if (isUpper) {
      sliderElement.updateSliderValues({
        upperUpdate: value,
      });
    } else {
      sliderElement.updateSliderValues({
        lowerUpdate: value,
      });
    }
  }

  updateReusableSlider(key: string, value: string) {
    const propsKey = key.slice(0, 5); // upper or lower
    const updateKey = propsKey === 'lower' ? 'lowerUpdate' : 'upperUpdate';
    const sliderLabel = key.slice(5);

    // bail out if not one of asset classes
    if (!this.sliderFieldLabels.includes(sliderLabel)) return;

    // update all the sliders in question
    const sliderToUpdate = this.allConfigSlidersChildren.find(component => {
      return component.sliderConfig.label === sliderLabel;
    });

    if (sliderToUpdate) {
      sliderToUpdate.updateSliderValues({
        [updateKey]: Number(value),
      });
    }
  }

  seeIfNeedToUpdateSlider(key: string, value) {
    value = parseInt(value);
    this.updateZintSliderElement({ key, value });
    if (key !== 'pageNumber') {
      this.updateReusableSlider(key, value);
    }
  }

  checkThatBox(option) {
    setTimeout(() => (option.shouldShow = true), 1);
    setTimeout(() => (option.checked = true), 1);
  }

  getAllOptionsDict() {
    return {
      selectedCompanyStatuses: {
        options: this.companyStatusOptions,
        selector: 'name',
      },
      selectedUBOCountries: {
        options: this.countriesOfUBOsOptions,
        selector: 'code',
      },
      selectedCompanyCategories: {
        options: this.companyCategoryOptions,
        selector: 'name',
      },
      selectedSICCodes: {
        options: this.companySICCodeOptions,
        selector: 'identifier',
      },
      excludedSICCodes: {
        options: this.companySICCodeOptions,
        selector: 'identifier',
        attribute: 'excluded',
      },
      selectedOfficeCountries: {
        options: this.countriesOfOfficesOptions,
        selector: 'code',
      },
      selectedGeographicalRevenueCountries: {
        options: this.countriesOfGeographicalRevenueOptions,
        selector: 'code',
      },
      selectedGeographicalRevenueTerritories: {
        options: this.territoriesOfGeographicalRevenueOptions,
        selector: 'code',
      },
      selectedCountries: {
        options: this.countriesOfOriginOptions,
        selector: 'code',
      },
      selectedFunctionalDepartments: {
        options: this.functionalDepartmentOptions,
        selector: 'name',
      },
      nationalitiesOfDirectorsSelected: {
        options: this.nationalitiesOfDirectorsOptions,
        selector: 'code',
      },
      nationalitiesOfPSCsSelected: {
        options: this.nationalitiesOfPSCsOptions,
        selector: 'code',
      },
      selectedImportExportCategories: {
        options: this.importExportCategories,
        selector: 'unique_identifier',
      },
      selectedWebTechnologies: {
        options: this.webTechnologyOptions,
        selector: 'name',
      },
      selectedFinancialYearEnds: {
        options: this.financialYearEndsOptions,
        selector: 'value',
      },
      trademarkClassIdsSelected: {
        options: this.trademarkClassOptions,
        selector: 'id',
      },
      selectedTrademarkStatuses: {
        options: this.trademarkStatusOptions,
        selector: 'id',
      },
      tagNegativeIdsSelected: {
        options: this.tagsNegativeOptions,
        selector: 'id',
      },
      tagPositiveIdsSelected: {
        options: this.tagsPositiveOptions,
        selector: 'id',
      },
      crossSellTagIdsSelected: {
        options: this.crossSellTagOptions,
        selector: 'id',
      },
      alertIdsSelected: { options: this.alertOptions, selector: 'id' },
    };
  }

  selectActiveCompaniesInUI() {
    this.companyStatusOptions
      .filter(option => option.status_clean == 'Active Companies')
      .map(option => (option.checked = true));
  }

  getOptions(selectedKeyString) {
    return this.getAllOptionsDict()[selectedKeyString];
  }

  checkRequiredBoxes(key, listOfOptionsThatShouldBeSelected) {
    const parsedOptionsThatShouldBeSelected = JSON.parse(
      listOfOptionsThatShouldBeSelected
    );
    const optionsInfo = this.getOptions(key);
    const options = optionsInfo['options'];
    const selector = optionsInfo['selector'];
    options
      .filter(
        option =>
          parsedOptionsThatShouldBeSelected.indexOf(option[selector]) >= 0
      )
      .forEach(option => this.checkThatBox(option));
  }

  updateUIBasedUponDictionaryValuesReturnShouldRunAgain(queryParams): boolean {
    let shouldRunSearch = false;
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        if (key == 'selectedCustomColumns') {
          this.updateCustomColumnsIfRequired(JSON.parse(queryParams[key]));
        } else if (key == 'selectedSalesforceObjects') {
          this.updateSalesforceObjectsIfRequired(JSON.parse(queryParams[key]));
        } else if (key == 'selectedView') {
          this.selectedView = JSON.parse(queryParams[key]);
        } else if (key == 'websiteKeywords') {
          this.websiteKeywords = JSON.parse(queryParams[key]);
        } else if (key == 'pageNumber') {
          this.pageNumber = parseInt(queryParams[key]);
        } else if (key == 'editPropensityModelTAM') {
          this.canEditTAM = true;
        } else if (key == 'selectedEvents') {
          this.selectedEvents = JSON.parse(queryParams[key]);
        } else if (key == 'selectedTerritory') {
          this.selectedTerritory = JSON.parse(queryParams[key]);
          // Because we reuse companySICCodeOptions for selectedSICCodes and excludedSICCodes we need to handle them separately
        } else if (key === 'selectedSICCodes') {
          // for each selected SIC code, find the corresponding companySICCodeOption and set checked to true
          const sicCodes = JSON.parse(queryParams[key]);
          sicCodes.forEach(sicCode => {
            const sicCodeOption = this.companySICCodeOptions.find(
              option => option.identifier === sicCode
            );
            if (sicCodeOption) {
              sicCodeOption.checked = true;
              sicCodeOption.shouldShow = true;
            }
          });
        } else if (key === 'excludedSICCodes') {
          // for each excluded SIC code, find the corresponding companySICCodeOption and set excluded to true
          const sicCodes = JSON.parse(queryParams[key]);
          sicCodes.forEach(sicCode => {
            const sicCodeOption = this.companySICCodeOptions.find(
              option => option.identifier === sicCode
            );
            if (sicCodeOption) {
              sicCodeOption.excluded = true;
              sicCodeOption.shouldShow = true;
            }
          });
        } else if (key === 'savedSearchName') {
          this.savedSearchParamName = queryParams[key];
        } else {
          if (this[key] && Array.isArray(this[key])) {
            if (queryParams[key]) {
              this.checkRequiredBoxes(key, queryParams[key]);
            }
          } else if (this[key] !== undefined) {
            this[key] = queryParams[key];
          }
        }
        this.seeIfNeedToUpdateSlider(key, queryParams[key]);
        shouldRunSearch = true;
      }
    }
    return shouldRunSearch;
  }

  initialiseBasedUponGetParamsIfAllLoaded() {
    if (
      this.hasLoadedUIData &&
      this.hasLoadedUserTags &&
      this.hasLoadedUserCrossSellTags &&
      this.hasLoadedCustomColumns &&
      this.hasLoadedAlertOptions &&
      this.hasLoadedSalesforceOptions
    ) {
      /*
      set the keyToSliderMap here after UIData loaded
      need to call this before updateUIBasedUpon...
      */
      this.mapKeyToSliderElement();

      const queryParams = this.route.snapshot.queryParams;

      const shouldRunSearchOnceAllParamsLoaded =
        this.updateUIBasedUponDictionaryValuesReturnShouldRunAgain(queryParams);

      if (shouldRunSearchOnceAllParamsLoaded) {
        setTimeout(() => this.searchCompaniesAfterFilters(false), 1);
      } else {
        setTimeout(() => this.getInitialCompanies(), 1);
        this.selectActiveCompaniesInUI();
      }
    }
  }

  updateAlerts(data): void {
    this.alertOptions = data.alerts;
    this.teamMembers = data.team_members;
    if (data.team_members && data.team_members.length > 0) {
      this.teamMemberIdToCreateAlertFor = data.team_members[0].id;
    }
    this.hasLoadedAlertOptions = true;
    this.initialiseBasedUponGetParamsIfAllLoaded();
  }

  updateCustomColumnsIfRequired(newCustomColumns): void {
    // Here we need to update the custom columns as selected, e.g. specific values etc.
    // We need to pull the options from the options that are shared in the UI, not through the URL though as if from a saved
    // search then it will likely be out of date.
    let output = [];
    this.customColumns.forEach(col => {
      let matchingColumns = newCustomColumns.filter(_col => col.id == _col.id);
      if (matchingColumns && matchingColumns.length > 0) {
        let matchingColumn = matchingColumns[0];
        if (col.options) {
          let optionsOutput = [];
          col.options.forEach(col_option => {
            let matchingOptions = matchingColumn.options.filter(
              _option => col_option.id == _option.id
            );
            if (matchingOptions && matchingOptions.length > 0) {
              let matchingOption = matchingOptions[0];
              optionsOutput.push(matchingOption);
            } else {
              optionsOutput.push(col_option);
            }
          });
          matchingColumn.options = optionsOutput;
        }
        // Update permissions from current columns
        matchingColumn.can_edit = col.can_edit;
        matchingColumn.can_view = col.can_view;
        output.push(matchingColumn);
      } else {
        output.push(col);
      }
    });
    this.customColumns = output;
  }

  updateCustomColumnsAndColumnWidths(output): void {
    this.customColumnNamesToObjects = {};
    this.customColumns = output.custom_columns;
    this.teamMembersForCustomColumns = output.team_members;
    if (this.customColumns) {
      this.customColumns.forEach(col => {
        this.customColumnNamesToObjects[col.name] = col;
        if (col.column_type == 'User') {
          let copyOfTeamMembersToAssign = JSON.parse(
            JSON.stringify(this.teamMembersForCustomColumns)
          );
          col.options = copyOfTeamMembersToAssign;
        }
      });
    }
    this.hasLoadedCustomColumns = true;
    if (output.column_widths) {
      this.columnNamesToWidths = output.column_widths;
    } else {
      this.columnNamesToWidths = { 'Company Name': 350 };
    }
    this.initialiseBasedUponGetParamsIfAllLoaded();
  }

  clearAllCurrentlyEditingAndUpdateValue(updatedValue, company_k_v): void {
    company_k_v.val = updatedValue;
    this.currentlyEditingRowName = '';
    this.currentlyEditingColumnName = '';
  }

  updateCurrentlyEditingCols(row_id, col_name): void {
    if (this.customColumnNamesToObjects[col_name]) {
      if (this.customColumnNamesToObjects[col_name]['can_edit']) {
        this.currentlyEditingRowName = row_id;
        this.currentlyEditingColumnName = col_name;
      }
    }
  }

  updateCrossSellTagOptions(output): void {
    this.crossSellTagOptions = output;
    this.hasLoadedUserCrossSellTags = true;
    this.initialiseBasedUponGetParamsIfAllLoaded();
  }

  updateTagOptions(output): void {
    if (output.user_tags) {
      this.tagsPositiveOptions = output.user_tags;
      const copy = JSON.parse(JSON.stringify(output.user_tags));
      // otherwise we end up with the same data binding!
      this.tagsNegativeOptions = copy;
      this.hasLoadedUserTags = true;
      this.initialiseBasedUponGetParamsIfAllLoaded();
    }
  }

  updateScrollBarDimensions() {
    $(document).ready(function () {
      $('.dummy-top-scrollbar').width(
        $('#table-results-section').find('table').width()
      );
      $('.dummy-top-scrollbar-wrapper').width(
        $('#table-results-section').width() + 15
      );
    });
  }

  updateCompaniesAndRenderInfo(output, isInitial) {
    this.companies = output;
    this.pubMethods.allowDraggingOftable('.company-results-table');
    this.updateScrollBarDimensions();
    if (!isInitial) {
      this.updateCompanyIfInaccurateEstimate();
    }
  }
  getInitialCompanies(): void {
    this.dashboardService
      .getInitialCompanies()
      .subscribe(output => this.updateCompaniesAndRenderInfo(output, true));
  }

  getUIDataAndUpdateCompanies(): void {
    this.dashboardService
      .getUIData()
      .subscribe(data => this.updateUIComponents(data));
  }

  /// ------------- Salesforce Filtering -------------
  updateHasSalesforceV2AccessMessageAndGetSalesforceObjects(
    hasSalesforceV2AccessMessage
  ) {
    this.hasSalesforceV2AccessMessage = hasSalesforceV2AccessMessage;
    if (this.hasSalesforceV2AccessMessage) {
      this.salesforceConfigService
        .getSalesforceObjectsForDashboard()
        .subscribe(output =>
          this.updateSalesforceObjectsAndSetHasLoadedTrue(output)
        );
    } else {
      this.hasLoadedSalesforceOptions = true;
      this.initialiseBasedUponGetParamsIfAllLoaded();
    }
  }

  updateSalesforceObjectsAndSetHasLoadedTrue(output) {
    this.salesforceObjects = output;
    this.hasLoadedSalesforceOptions = true;
    this.initialiseBasedUponGetParamsIfAllLoaded();
  }

  updateSalesforceObjectsIfRequired(inputtedSalesforceObjectDetails) {
    if (this.salesforceObjects) {
      inputtedSalesforceObjectDetails.forEach(
        inputtedSalesforceObjectDetail => {
          this.salesforceObjects
            .filter(sfObj => sfObj.id === inputtedSalesforceObjectDetail['id'])
            .map(
              sfObj =>
                (sfObj.dropdownInput =
                  inputtedSalesforceObjectDetail['dropdownInput'])
            );
        }
      );
    }
  }

  getSelectedSalesforceObjects(): Array<any> {
    if (this.salesforceObjects) {
      // make a copy of the nested object.
      const outputSalesforceObjects = JSON.parse(
        JSON.stringify(
          this.salesforceObjects.filter(sobject => sobject.dropdownInput)
        )
      );
      // remove the attributes that we do not need that clog up the URL params
      outputSalesforceObjects.forEach(col => delete col.name);
      return outputSalesforceObjects;
    } else {
      return [];
    }
  }
  /// ------------- Salesforce Filtering -------------

  searchWebTechs(): void {
    if (this.webTechsTextSearch && this.webTechsTextSearch.length > 2) {
      this.isSearchingWebTechs = true;
      this.selectedWebTechnologies = this.webTechnologyOptions
        .filter(option => (option.shouldShow = true))
        .map(option => (option.shouldShow = false));
      this.selectedWebTechnologies = this.webTechnologyOptions
        .filter(
          option =>
            option.description
              .toLowerCase()
              .indexOf(this.webTechsTextSearch.toLowerCase()) >= 0
        )
        .map(option => (option.shouldShow = true));
    }
  }

  resetWebTechs(): void {
    this.selectedWebTechnologies = this.webTechnologyOptions.map(
      option => (option.shouldShow = false)
    );
    this.selectedWebTechnologies = this.webTechnologyOptions
      .filter(option => option.level === 0)
      .map(option => (option.shouldShow = true));
    this.isSearchingWebTechs = false;
  }

  searchSicCodes(): void {
    if (this.sicCodeTextSearch && this.sicCodeTextSearch.length > 2) {
      this.isSearchingSicCodes = true;
      this.selectedSICCodes = this.companySICCodeOptions
        .filter(option => (option.shouldShow = true))
        .map(option => (option.shouldShow = false));
      this.selectedSICCodes = this.companySICCodeOptions
        .filter(
          option =>
            option.description
              .toLowerCase()
              .indexOf(this.sicCodeTextSearch.toLowerCase()) >= 0
        )
        .map(option => (option.shouldShow = true));
    }
  }

  resetSicSearch(): void {
    this.selectedSICCodes = this.companySICCodeOptions
      .filter(option => (option.shouldShow = true))
      .map(option => (option.shouldShow = false));
    this.isSearchingSicCodes = false;
  }

  updateFilters(): void {
    //TODO: check if we can early return without optional chain everything
    this.selectedUBOCountries = this.countriesOfUBOsOptions
      .filter(option => option.checked)
      .map(option => option.code);

    this.nationalitiesOfDirectorsSelected = this.nationalitiesOfDirectorsOptions
      .filter(option => option.checked)
      .map(option => option.code);

    this.nationalitiesOfPSCsSelected = this.nationalitiesOfPSCsOptions
      .filter(option => option.checked)
      .map(option => option.code);

    this.selectedWebTechnologies = this.webTechnologyOptions
      .filter(option => option.checked)
      .map(option => option.name);

    this.selectedImportExportCategories = this.importExportCategories
      .filter(option => option.checked)
      .map(option => option.unique_identifier);

    this.selectedCompanyStatuses = this.companyStatusOptions
      .filter(option => option.checked)
      .map(option => option.name);

    this.selectedCountries = this.countriesOfOriginOptions
      .filter(option => option.checked)
      .map(option => option.code);

    this.selectedOfficeCountries = this.countriesOfOfficesOptions
      .filter(option => option.checked)
      .map(option => option.code);

    this.selectedGeographicalRevenueCountries =
      this.countriesOfGeographicalRevenueOptions
        .filter(option => option.checked)
        .map(option => option.code);

    this.selectedGeographicalRevenueTerritories =
      this.territoriesOfGeographicalRevenueOptions
        .filter(option => option.checked)
        .map(option => option.code);

    this.selectedFunctionalDepartments = this.functionalDepartmentOptions
      .filter(option => option.checked)
      .map(option => option.name);

    this.selectedSICCodes = this.companySICCodeOptions
      .filter(option => option.checked)
      .map(option => option.identifier);

    this.excludedSICCodes = this.companySICCodeOptions
      .filter(option => option.excluded)
      .map(option => option.identifier);

    //noinspection TypeScriptUnresolvedVariable
    this.selectedCompanyCategories = this.companyCategoryOptions
      .filter(option => option.checked)
      .map(option => option.value);

    //noinspection TypeScriptUnresolvedFunction
    this.selectedFinancialYearEnds = this.financialYearEndsOptions
      .filter(option => option.checked)
      .map(option => option.value);

    this.tagPositiveIdsSelected = this.tagsPositiveOptions
      .filter(option => option.checked)
      .map(option => option.id);

    this.tagNegativeIdsSelected = this.tagsNegativeOptions
      .filter(option => option.checked)
      .map(option => option.id);

    this.crossSellTagIdsSelected = this.crossSellTagOptions
      .filter(option => option.checked)
      .map(option => option.id);

    this.trademarkClassIdsSelected = this.trademarkClassOptions
      .filter(option => option.checked)
      .map(option => option.id);

    this.selectedTrademarkStatuses = this.trademarkStatusOptions
      .filter(option => option.checked)
      .map(option => option.name);

    this.alertIdsSelected = this.alertOptions
      .filter(option => option.checked)
      .map(option => option.id);

    this.selectedCustomColumns = this.getSelectedCustomColumns();
    this.selectedSalesforceObjects = this.getSelectedSalesforceObjects();
  }

  getSelectedCustomColumns(): Array<any> {
    const outPutColumns = this.customColumns.filter(
      column =>
        (column.dropdownInput &&
          (column.dropdownInput == 'isSet' ||
            column.dropdownInput == 'isNotSet' ||
            column.dropdownInput == 'isMe' ||
            column.dropdownInput == 'lockState' ||
            column.boxInput ||
            column.boxInput == 0 ||
            column.boxInputLower ||
            column.boxInputLower == 0 ||
            column.boxInputUpper ||
            column.boxInputUpper == 0)) ||
        column.options.filter(option => option.checked).length > 0
    );
    // remove the attributes that we do not need that clog up the URL params
    outPutColumns.forEach(col => delete col.edit_users);
    outPutColumns.forEach(col => delete col.view_users);
    return outPutColumns;
  }

  // Create HttpParams from current state of filters
  allFilterParams(): HttpParams {
    return this.dashboardService.getFilterParameters(
      this.selectedTerritory,
      this.companyNameContains,
      this.accountantNameContains,
      this.bankerNameContains,
      this.selectedCompanyStatuses,
      this.lowerNumberOfUBOs,
      this.upperNumberOfUBOs,
      this.selectedUBOCountries,
      this.sicCode,
      this.selectedCountries,
      this.selectedCompanyCategories,
      this.selectedSICCodes,
      this.excludedSICCodes,
      this.websiteKeywords,
      this.corporateStructureIsTopLevelParentCompany,
      this.corporateStructureHasParent,
      this.corporateStructureDoesNotHaveParent,
      this.corporateStructureHasChild,
      this.corporateStructureDoesNotHaveChild,
      this.corporateStructureRelatedCompanies,
      this.hasWebsite,
      this.employeeLowerCount,
      this.employeeUpperCount,
      this.excludeEstimatedHeadcountFigures,
      this.lowerHeadCountGrowthPercentage,
      this.upperHeadCountGrowthPercentage,
      this.departmentHeadCountLowerCount,
      this.departmentHeadCountUpperCount,
      this.selectedFunctionalDepartments,
      this.lowerAge,
      this.upperAge,
      this.selectedAddressTypeOption,
      this.postcodeBeginsWith,
      this.postcodeDistrict,
      this.ukRegion,
      this.postcodeWithinRadius,
      this.selectedTownId,
      this.mileRadiusOfPostcode,
      this.hasOverseasParent,
      this.hasOfficesOverseas,
      this.selectedOfficeCountries,
      this.lowerNumberOfKnownOfficeLocations,
      this.upperNumberOfKnownOfficeLocations,
      this.selectedGeographicalRevenueCountries,
      this.selectedGeographicalRevenueTerritories,
      this.lowerGeographicalRevenueSelected,
      this.upperGeographicalRevenueSelected,
      this.hasLEINumber,
      this.selectedLEIStatus,
      this.selectedImportExportCategories,
      this.selectedImportingOrExportingOption,
      this.importExportTimeScale,
      this.importExportFrequencyImportingOrExporting,
      this.importExportFrequency,
      this.importExportFrequencyMonthCount,
      this.selectedWebTechnologies,
      this.webTrafficLow,
      this.webTrafficHigh,
      this.homepageSearchTerm,
      this.fullWebsiteSearchTerm,
      this.lowerAverageDirectorAgeRange,
      this.upperAverageDirectorAgeRange,
      this.lowerNumberOfDirectors,
      this.upperNumberOfDirectors,
      this.lowerNumberOfPSCs,
      this.upperNumberOfPSCs,
      this.PSCsRealPersonsOnly,
      this.recentDirectorshipTitleContains,
      this.lowerRecentDirectorshipMonth,
      this.upperRecentDirectorshipMonth,
      this.nationalitiesOfDirectorsSelected,
      this.nationalitiesOfPSCsSelected,
      this.lowerDaysUntilFinancialYearEnd,
      this.upperDaysUntilFinancialYearEnd,
      this.selectedFinancialYearEnds,
      this.lowerDaysUntilAnniversary,
      this.upperDaysUntilAnniversary,
      this.isHiring,
      this.hiringJobTitleContains,
      this.hiringDescriptionContains,
      this.selectedHiringDayLimit,
      this.lowerNumberOfRolesHiringFor,
      this.upperNumberOfRolesHiringFor,
      this.selectedNumberOfRolesHiringForDayLimit,
      this.includeRecruiters,
      this.directorJobTitleContains,
      this.amazonSeller,
      this.listedOnGCloud,
      this.hasPhoneNumber,
      this.hasEmailAddress,
      this.hasRaisedInvestment,
      this.hasShareFilingEvent,
      this.shareFilingEventWithinDays,
      this.lowerShareFilingCapitalValue,
      this.upperShareFilingCapitalValue,
      this.lowerInvestmentAmount,
      this.upperInvestmentAmount,
      this.ignoreRevenueEstimates,
      this.lowerRevenueSelected,
      this.upperRevenueSelected,
      this.lowerNetProfitSelected,
      this.upperNetProfitSelected,
      this.lowerCashAtBankSelected,
      this.upperCashAtBankSelected,
      this.lowerDebtorsSelected,
      this.upperDebtorsSelected,
      this.lowerCreditorsSelected,
      this.upperCreditorsSelected,
      this.lowerCurrentAssetsSelected,
      this.upperCurrentAssetsSelected,
      this.lowerFixedAssetsSelected,
      this.upperFixedAssetsSelected,
      this.lowerNetAssetsSelected,
      this.upperNetAssetsSelected,
      this.lowerAccountsDueSelected,
      this.upperAccountsDueSelected,
      this.lowerLatestAccountsSubmittedSelected,
      this.upperLatestAccountsSubmittedSelected,
      this.accountsAreOverdue,
      this.lowerGrossMargin,
      this.upperGrossMargin,
      this.lowerAnnualAssetsGrowthPercentage,
      this.upperAnnualAssetsGrowthPercentage,
      this.lowerAnnualFixedAssetsGrowthPercentage,
      this.upperAnnualFixedAssetsGrowthPercentage,
      this.lowerAnnualRevenueGrowthPercentage,
      this.upperAnnualRevenueGrowthPercentage,
      this.lowerAnnualGrossProfitGrowthPercentage,
      this.upperAnnualGrossProfitGrowthPercentage,
      this.accountsSearchTerm,
      this.accountsSearchWithinDays,
      this.issuedAGrant,
      this.lowerGrantAmount,
      this.upperGrantAmount,
      this.grantLowerMonthsUntilProjectEnd,
      this.grantUpperMonthsUntilProjectEnd,
      this.chargeRegistered,
      this.outstandingChargeRegistered,
      this.selectedEvents,
      this.postcodeEventsWithinRadius,
      this.mileRadiusOfEventsSelected,
      this.eventsWithinDaysLower,
      this.eventsWithinDaysUpper,
      this.newsWithinDays,
      this.newsContaining,
      this.newsSource,
      this.trademarkClassIdsSelected,
      this.trademarkDescriptionContains,
      this.trademarkNameContains,
      this.selectedTrademarkStatuses,
      this.excludeCompaniesTaggedByTeam,
      this.tagPositiveIdsSelected,
      this.tagNegativeIdsSelected,
      this.crossSellTagIdsSelected,
      this.crossSellLinkedThroughPeople,
      this.crossSellLinkedThroughCorporateStructure,
      this.similarCompanyUrl,
      this.pageNumber,
      this.sortKey,
      this.sortDirectionIsAscending,
      this.selectedCustomColumns,
      this.unreadAlertsOnly,
      this.lowerAlertsDayRange,
      this.upperAlertsDayRange,
      this.alertIdsSelected,
      this.selectedSalesforceObjects,
      this.selectedView,
      this.isLowEnergy,
      this.lowerTotalEnergyKwhSelected,
      this.upperTotalEnergyKwhSelected,
      this.lowerGasKwhSelected,
      this.upperGasKwhSelected,
      this.lowerElectricKwhSelected,
      this.upperElectricKwhSelected,
      this.lowerScope1KwhSelected,
      this.upperScope1KwhSelected,
      this.lowerScope2KwhSelected,
      this.upperScope2KwhSelected,
      this.lowerScope3KwhSelected,
      this.upperScope3KwhSelected,
      this.lowerTotalEmissionsKgCo2eSelected,
      this.upperTotalEmissionsKgCo2eSelected,
      this.lowerScope1KgCo2eSelected,
      this.upperScope1KgCo2eSelected,
      this.lowerScope2KgCo2eSelected,
      this.upperScope2KgCo2eSelected,
      this.lowerScope3KgCo2eSelected,
      this.upperScope3KgCo2eSelected,
      this.lowerKgCo2ePerMillionRevenueSelected,
      this.upperKgCo2ePerMillionRevenueSelected,
      this.lowerKgCo2ePerEmployeeSelected,
      this.upperKgCo2ePerEmployeeSelected,
      this.hasActivePatent,
      this.hasAnyPatentRecord,
      this.patentFiledWithinDays,
      this.lowerPatentAwardedDays,
      this.upperPatentAwardedDays,
      this.hasContract,
      this.lowerContractValueSelected,
      this.upperContractValueSelected,
      this.contractAwardedWithinMonths,
      this.lowerContractStartMonthsSelected,
      this.upperContractStartMonthsSelected,
      this.lowerContractEndMonthsSelected,
      this.upperContractEndMonthsSelected,
      this.contractTitleContains,
      this.contractDescriptionContains,
      this.ignoreSatisfiedCharges,
      this.chargeHolderNameContains,
      this.chargeCreatedMonthsAgoLower,
      this.chargeCreatedMonthsAgoUpper,
      this.supplierTagSelected,
      this.associateWithOneSupplierOnly,
      this.customerTagSelected,
      this.summarySemanticSearchTerm,
      this.summarySemanticSearchExclude,
      this.sliderValues
    );
  }

  definedPage(page): void {
    if (page <= this.maxPageNumber) {
      this.pageNumber = page;
      this.searchCompaniesAfterFiltersNoCount();
    }
  }

  nextPage(): void {
    if (this.pageNumber != this.maxPageNumber) {
      this.pageNumber += 1;
      this.searchCompaniesAfterFiltersNoCount();
    }
  }

  previousPage(): void {
    if (this.pageNumber > 1) {
      this.pageNumber -= 1;
      this.searchCompaniesAfterFiltersNoCount();
    }
  }

  sortBy(sortKey): void {
    if (this.sortKey == sortKey) {
      if (!this.sortDirectionIsAscending) {
        this.sortDirectionIsAscending = true;
      } else {
        this.sortKey = null;
        this.sortDirectionIsAscending = false;
      }
    } else {
      this.sortKey = sortKey;
      this.sortDirectionIsAscending = false;
    }
    this.searchCompaniesAfterFilters(true);
  }

  clearSearchParam(paramObj): void {
    if (this[paramObj['param']] !== undefined) {
      this[paramObj['param']] = null;
    } else {
      // for config sliders
      const propsKey = paramObj.param.slice(0, 5); // upper or lower

      const sliderLabel = paramObj.param.slice(5);
      this.sliderValues[sliderLabel][propsKey] = null;
    }

    if (paramObj['param'] === 'selectedCustomColumns') {
      this.customColumns.map(column =>
        column.options.map(option => (option.checked = false))
      );
      this.customColumns.map(column => (column.dropdownInput = ''));
      this.customColumns.map(column => (column.lockFilter = ''));
    }
    const oldParams = this.allFilterParams()['updates'];

    const getFormatOldParams = {};
    for (const oldParam of oldParams) {
      if (
        oldParam.value !== '' &&
        oldParam.value !== 'null' &&
        oldParam.value !== 'false' &&
        oldParam.value !== null
      ) {
        getFormatOldParams[oldParam.param] = oldParam.value;
      }
    }

    this.resetAllFilters();

    this.updateUIBasedUponDictionaryValuesReturnShouldRunAgain(
      getFormatOldParams
    );
    this.searchTags = JSON.parse(JSON.stringify(oldParams)); // seem to need to do this otherwise it references the old filter parameters?
  }

  searchCompaniesAfterFiltersNoCount(): void {
    this.updateFilters();
    const params = this.allFilterParams();
    this.searchTags = JSON.parse(JSON.stringify(params)).updates;
    this.checkboxSelectAllCompanies = false;
    this.showOrHideInfoOnAllSelectedCompanies();
    this.dashboardService
      .searchCompaniesNew(params)
      .subscribe(output => this.updateCompaniesAndRenderInfo(output, false));
  }

  searchCompaniesAfterFilters(initialisePageCounter: boolean): void {
    if (initialisePageCounter) {
      this.pageNumber = 1;
    }
    this.searchCompaniesAfterFiltersNoCount();
    this.getCompanyCount(false);
  }

  testIfSearchCompaniesOnEnterPress(event): void {
    if (!this.isLoading.loadingCompanies) {
      this.searchCompaniesAfterFilters(true);
    }
  }

  // When you have accts/website terms, the estimated number is sometimes v low, but you may get back a higher number than that.
  // Sometimes it is too high (but still less than 1 full page), and then you need to chop it down to the smaller number.
  // Update to that number.
  updateCompanyIfInaccurateEstimate(): void {
    if (
      !this.isLoading.loadingCompanies &&
      !this.isLoading.loadingCompanyCount &&
      this.pageNumber == 1 &&
      this.companyCount['exact'] == false
    ) {
      if (
        this.companies.length > this.companyCount['count'] ||
        (this.companies.length < this.companyCount['count'] &&
          this.companiesPerPage != this.companies.length)
      ) {
        this.companyCount['count'] = this.companies.length;
      }
    }
  }

  updateCompanyCountAndPaginationLimits(data, isInitial): void {
    this.companyCount = data;
    this.companiesPerPage = data['companies_per_page'];
    this.maxPageNumber = Math.ceil(
      this.companyCount['count'] / this.companiesPerPage
    );
    if (!isInitial) {
      this.updateCompanyIfInaccurateEstimate();
    }
  }

  getCompanyCount(isInitial): void {
    this.showCreateAlertForm = false;
    if (isInitial) {
      this.dashboardService
        .getInitialCompanyCount()
        .subscribe(data =>
          this.updateCompanyCountAndPaginationLimits(data, isInitial)
        );
    } else {
      this.dashboardService
        .getCompanyCount(this.allFilterParams())
        .subscribe(data =>
          this.updateCompanyCountAndPaginationLimits(data, isInitial)
        );
    }
  }

  updateUIComponents(data): void {
    this.countriesOfUBOsOptions = data.countries_of_ubos;
    this.numberUBOsRange = data.number_ubos_range;
    this.countriesOfOriginOptions = data.countries_of_origin;
    this.countriesOfOfficesOptions = data.countries_of_offices;
    this.countriesOfGeographicalRevenueOptions =
      data.countries_of_geographical_revenue;
    this.territoriesOfGeographicalRevenueOptions =
      data.territories_of_geographical_revenue;
    this.geographicalRevenueOptions = data.geographical_revenue_options;
    this.ukRegions = data.uk_regions;
    this.companyCategoryOptions = data.company_categories;
    this.companySICCodeOptions = data.sic_codes;
    this.companyStatusOptions = data.company_statuses;
    this.numberOfKnownOfficeLocationsRange =
      data.number_of_known_locations_range;
    this.employeeRangeFigures = data.employee_bandings;
    this.headcountGrowthPercentageRange = data.headcount_growth_limits;
    this.functionalDepartmentOptions = data.functional_departments;
    this.companyAgeRange = data.company_age_range;
    this.importExportCategories = data.import_export_sections;
    this.LEIStatusOptions = data.lei_status_options;
    this.importingExportingBothOptions = data.importing_or_exporting;
    this.importExportMonthRange = data.import_export_month_range;
    this.importExportFrequencyRange = data.import_export_frequency_range;
    this.importExportFrequencyMonthCountRange =
      data.import_export_frequency_month_count_range;
    this.webTechnologyOptions = data.web_technologies;
    this.webTrafficOptions = data.web_traffic_intervals;
    this.averageDirectorAgeRange = data.average_director_age_range;
    this.numberOfDirectorsRange = data.number_of_directors_range;
    this.numberOfPSCsRange = data.number_of_pscs_range;
    this.recentDirectorshipMonthRange = data.recent_directorship_month_range;
    this.nationalitiesOfDirectorsOptions = data.nationalities_of_directors;
    this.nationalitiesOfPSCsOptions = data.nationalities_of_pscs;
    this.daysUntilFinancialYearEndLimits = data.days_until_financial_year_end;
    this.financialYearEndsOptions = data.financial_year_month_options;
    this.daysUntilAnniversaryLimits = data.days_until_company_anniversary;
    this.hiringDayLimits = data.hiring_day_limits;
    this.numberOfRolesHiringForLimits = data.number_of_roles_hiring_for_limits;
    this.numberOfRolesHiringForDayLimits =
      data.number_of_roles_hiring_for_day_limits;
    this.investmentRaisedOptions = data.investment_raised_intervals;
    this.shareFilingEventWithinDaysOptions =
      data.share_filing_event_within_days_options;
    this.shareFilingCapitalValueOptions =
      data.share_filing_capital_value_options;
    this.revenueOptions = data.revenue_options;
    this.netProfitOptions = data.net_profit_options;
    this.cashAtBankOptions = data.cash_at_bank_options;
    this.debtorsOptions = data.debtors_options;
    this.creditorsOptions = data.creditors_options;
    this.currentAssetsOptions = data.current_assets_options;
    this.fixedAssetsOptions = data.fixed_assets_options;
    this.netAssetsOptions = data.net_assets_options;
    this.accountsDueInRange = data.accounts_due_in_range;
    this.latestAccountsSubmittedRange = data.latest_accounts_submitted_range;
    this.grossMarginLimits = data.gross_margin_limits;
    this.assetsGrowthLimits = data.assets_growth_limits;
    this.fixedAssetsGrowthLimits = data.fixed_assets_growth_limits;
    this.revenueGrowthLimits = data.revenue_growth_limits;
    this.grossProfitGrowthLimits = data.gross_profit_growth_limits;
    this.grantRangeOptions = data.grant_amount_range;
    this.grantMonthsUntilProjectEndRange =
      data.grant_months_until_project_end_range;
    this.mileRadiusRegisteredAddressRange =
      data.mile_radius_registered_address_range;
    this.mileRadiusEventsRange = data.mile_radius_events_range;
    this.eventDaysRange = data.events_days_range;
    this.addressOptions = data.address_options;
    this.newsDaysRange = data.news_events_day_range;
    this.accountsSearchDayRange = data.accounts_search_day_range;
    this.trademarkClassOptions = data.trademark_class_options;
    this.trademarkStatusOptions = data.trademark_status_options;
    this.totalEnergyKwhOptions = data.total_energy_kwh_options;
    this.gasKwhOptions = data.gas_kwh_options;
    this.electricKwhOptions = data.electric_kwh_options;
    this.scope1KwhOptions = data.scope_1_kwh_options;
    this.scope2KwhOptions = data.scope_2_kwh_options;
    this.scope3KwhOptions = data.scope_3_kwh_options;
    this.totalEmissionsKgCo2eOptions = data.total_emissions_kg_co2e_options;
    this.scope1KgCo2eOptions = data.scope_1_kg_co2e_options;
    this.scope2KgCo2eOptions = data.scope_2_kg_co2e_options;
    this.scope3KgCo2eOptions = data.scope_3_kg_co2e_options;
    this.kgCo2ePerMillionRevenueOptions =
      data.kg_co2e_per_million_revenue_options;
    this.kgCo2ePerEmployeeOptions = data.kg_co2e_per_employee_options;
    this.patentFiledWithinDaysOptions = data.patent_filed_within_days_options;
    this.patentAwardedWithinDaysOptions = data.patent_filed_within_days_options;
    this.contractValueOptions = data.contract_value_options;
    this.contractAwardedWithinMonthsOptions =
      data.contract_awarded_within_months_options;
    this.contractStartMonthsOptions = data.contract_start_months_options;
    this.contractEndMonthsOptions = data.contract_end_months_options;
    this.chargeCreatedMonthLimits = data.charge_created_month_limits;

    this.alertFrequencyOptions = data.alert_frequency_options;
    this.propensityWeightingRange = data.propensity_weighting_range;
    this.propensityTAMLimitCount = data.propensity_tam_org_limit;
    this.jsonParamKeysNotAllowedForAlertsOrPropensityScore =
      data.json_param_keys_not_allowed_for_alerts;
    if (this.alertFrequencyOptions && this.alertFrequencyOptions.length) {
      this.selectedAlertFrequency = this.alertFrequencyOptions[0].identifier;
    }
    this.alertsDaysRange = data.alerts_days_range;
    this.companyColumnsNotAllowedToFilter =
      data.company_columns_not_allowed_to_filter;
    this.hasLoadedUIData = true;
    this.initialiseBasedUponGetParamsIfAllLoaded();
    this.allConfigSlidersChildren = [
      ...this.tanAssetsSliders,
      ...this.intanAssetsSliders,
      ...this.heatScoreSliders,
      ...this.ebitdaSliders,
      ...this.fxLossGainSliders,
      ...this.corporationTaxSliders,
      ...this.taxCreditSliders,
      ...this.wagesSliders,
    ];
  }

  updateToDefaultImportingOrExporting(): void {
    if (!this.selectedImportingOrExportingOption) {
      this.selectedImportingOrExportingOption =
        this.importingExportingBothOptions[
          this.importingExportingBothOptions.length - 1
        ]['identifier'];
    }
  }

  updateImportExportFrequencyToDefaultImportingOrExporting(): void {
    if (!this.importExportFrequencyImportingOrExporting) {
      this.importExportFrequencyImportingOrExporting =
        this.importingExportingBothOptions[
          this.importingExportingBothOptions.length - 1
        ]['identifier'];
    }
  }

  getJSONParamsToSave(hideOrderingAndPageNumbers) {
    const paramsToSave = this.searchTags.filter(
      param =>
        param.value != '' &&
        param.value != null &&
        param.value != 'false' &&
        param.value != 'null'
    );
    const requiredOutput = {};
    for (const theParam of paramsToSave) {
      if (
        (hideOrderingAndPageNumbers &&
          this.jsonParamKeysNotAllowedForAlertsOrPropensityScore.indexOf(
            theParam.param
          ) < 0) ||
        !hideOrderingAndPageNumbers
      ) {
        requiredOutput[theParam.param] = theParam.value;
      }
    }
    return JSON.stringify(requiredOutput);
  }

  // --------------- SAVED SEARCHES ---------------
  updateAfterSearchHasBeenSaved(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'This search has been saved to your account.'
      );
    }
    this.savedSearchName = '';
    this.makeSavedSearchShared = false;
    this.showSaveSearchForm = false;
  }

  saveSearch(): void {
    const requiredOutput = this.getJSONParamsToSave(false);
    this.dashboardService
      .saveUserSearch(
        this.savedSearchName,
        requiredOutput,
        this.makeSavedSearchShared
      )
      .subscribe(data => this.updateAfterSearchHasBeenSaved(data));
  }
  // --------------- SAVED SEARCHES ---------------

  // --------------- ALERTS ---------------

  chooseToAllowCreateAlertForm(): void {
    const maxCompaniesToCreateAlertFor = 200000;
    if (
      this.isLoading.loadingCompanyCount ||
      this.companyCount.count > maxCompaniesToCreateAlertFor
    ) {
      if (this.isLoading.loadingCompanyCount) {
        this.pubMethods.log(
          'Please wait until the search has loaded to create an alert.'
        );
      } else if (this.companyCount.count > maxCompaniesToCreateAlertFor) {
        this.pubMethods.log(
          'You can only create an alert for a search with fewer than ' +
            maxCompaniesToCreateAlertFor +
            ' initial organisations.'
        );
      }
    } else {
      this.showCreateAlertForm = true;
    }
  }

  updateAfterAlertHadBeenSaved(data): void {
    if (data.success) {
      if (this.isSuperUser) {
        const optionalWarning = data.warning_message
          ? data.warning_message
          : '';
        this.pubMethods.showInfoMessage(
          'This user will be alerted on a ' +
            this.selectedAlertFrequency +
            ' basis of new organisations matching these criteria. ' +
            optionalWarning
        );
      } else {
        this.pubMethods.showInfoMessage(
          'You will be alerted on a ' +
            this.selectedAlertFrequency +
            ' basis of new organisations matching these criteria.'
        );
      }
    }
    this.savedAlertName = '';
    this.showCreateAlertForm = false;
  }

  createAlert(): void {
    const requiredOutput = this.getJSONParamsToSave(true);
    this.dashboardService
      .saveUserAlert(
        this.savedAlertName,
        this.selectedAlertFrequency,
        this.teamMemberIdToCreateAlertFor,
        requiredOutput
      )
      .subscribe(data => this.updateAfterAlertHadBeenSaved(data));
  }
  // --------------- ALERTS ---------------

  // --------------- PROPENSITY SCORE ---------------

  updatePropensityModelsForUI(): void {
    if (this.propensityModels) {
      this.editablePropensityModels = this.propensityModels.filter(
        model => model.can_edit
      );
    }
  }

  updateZintPropensityScoreDetails(data): void {
    this.propensityModels = data.propensity_models;
    if (this.propensityModels && this.propensityModels.length > 0) {
      this.selectedPropensityModelId = this.propensityModels[0].id;
    }
    this.updatePropensityModelsForUI();
  }

  newPropensityScoreTAMCreated(data): void {
    if (data.success) {
      this.propensityModels = data.propensity_models;
      if (this.propensityModels && this.propensityModels.length > 0) {
        this.selectedPropensityModelId = data.new_propensity_model_id;
      }
      $('#propensityScoreTAMDefinition').modal('hide');
      this.updatePropensityModelsForUI();
      this.pubMethods.showInfoMessage(
        'Your Propensity Model has been created. Scores will begin to update in the next 1-2 hours.'
      );
    }
  }

  checkIfAddingNewPropensityScoreModel(
    possibleStringToTriggerNewPropensityModel
  ): void {
    if (
      possibleStringToTriggerNewPropensityModel ==
      this.stringToTriggerNewPropensityModel
    ) {
      $('#propensityScoreTAMDefinition').modal('show');
    }
  }

  definePropensityScoreTAM(): void {
    const jsonParams = this.getJSONParamsToSave(true);
    this.zintPropensityScoreService
      .savePropensityScoreTAM(this.propensityScoreTAMName, jsonParams)
      .subscribe(data => this.newPropensityScoreTAMCreated(data));
  }

  updatePropensityScoreComponentAfterSaved(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'This Propensity Score Component was saved.'
      );
      this.propensityScoreComponentDescription = '';
      this.propensityScoreWeighting = null;
    }
  }

  savePropensityScoreComponent(): void {
    const jsonParams = this.getJSONParamsToSave(true);
    this.zintPropensityScoreService
      .savePropensityScoreComponent(
        this.selectedPropensityModelId,
        this.propensityScoreComponentDescription,
        this.propensityScoreWeighting,
        jsonParams
      )
      .subscribe(data => this.updatePropensityScoreComponentAfterSaved(data));
  }

  validatepropensityScoreWeightingValue(): void {
    if (
      this.propensityWeightingRange &&
      this.propensityWeightingRange.length == 2
    ) {
      if (this.propensityScoreWeighting < this.propensityWeightingRange[0]) {
        this.propensityScoreWeighting = this.propensityWeightingRange[0];
      }
      if (this.propensityScoreWeighting > this.propensityWeightingRange[1]) {
        this.propensityScoreWeighting = this.propensityWeightingRange[1];
      }
    }
  }

  updateAfterTAMOverwritten(data): void {
    if (data.success) {
      this.pubMethods.showInfoMessage(
        'Your TAM has been overwritten. Scores will begin to update in the next 1-2 hours.'
      );
      $('#editPropensityModelTAM').modal('hide');
    }
  }

  updatePropensityModelTAM(): void {
    const jsonParams = this.getJSONParamsToSave(true);
    this.zintPropensityScoreService
      .overwritePropensityScoreTAM(
        this.selectedPropensityModelIdToUpdateTAM,
        jsonParams
      )
      .subscribe(data => this.updateAfterTAMOverwritten(data));
  }

  // --------------- PROPENSITY SCORE ---------------

  // --------------- TERRITORY ---------------

  updateTerritoryAssignmentDetails(hasTerritoryAssignmentAccessMessage) {
    this.hasTerritoryAssignmentAccessMessage =
      hasTerritoryAssignmentAccessMessage;
    this.territoryService.listTerritories().subscribe(data => {
      this.availableTerritories = data;
      this.areTerritoriesEnforced = data.some(
        territory => territory['is_enforced']
      );
    });
  }

  searchCompaniesWithTerritoryFilter(territory: Record<string, any> | null) {
    this.selectedTerritory = territory;

    this.searchCompaniesAfterFilters(true);
  }

  saveTerritory(): void {
    const jsonParams = this.getJSONParamsToSave(true);
    this.territoryService
      .saveTerritory(this.territoryName, jsonParams)
      .subscribe(data => this.updateAfterTerritoryHasBeenSaved(data));
  }

  updateAfterTerritoryHasBeenSaved(data): void {
    if (data.name) {
      this.pubMethods.showInfoMessage(
        `Territory ${data.name} has been saved to your organisation.`
      );
    }
    this.territoryName = '';
    this.showTerritoryForm = false;
  }

  // --------------- TERRITORY ---------------

  // RESETTING

  resetAllFilters(): void {
    this.resetInputsThroughJquery();
    this.resetAllMultiCheckboxOptions();
    this.resetAllZintSliders();
    this.sortKey = '';
    this.sortDirectionIsAscending = false;
    this.selectedAddressTypeOption = '';
  }

  resetAllFiltersAndUpdateFilters(): void {
    this.resetAllFilters();
    this.updateFilters();
    const params = this.allFilterParams();
    this.searchTags = JSON.parse(JSON.stringify(params)).updates;
  }

  resetAllMultiCheckboxOptions() {
    const optionsDict = this.getAllOptionsDict();
    for (const key in optionsDict) {
      // Check 'attribute' property of the option - default to 'checked'
      const attribute = optionsDict[key]?.attribute || 'checked';

      const options = optionsDict[key].options;
      options.forEach(option => {
        option[attribute] = false;
      });
    }
  }

  resetInputsThroughJquery(): void {
    $('#search-area').find('input:checkbox').prop('checked', false);
    $('#search-area').find('input:text').not('.slider-input').val('');
    $('#search-area').find('select').val('');
  }

  resetAllZintSliders(): void {
    const doubleSliderElements = [
      this.numberUBOsSliderElement,
      this.companyAgeSliderElement,
      this.revenueSliderElement,
      this.netProfitSliderElement,
      this.cashAtBankSliderElement,
      this.debtorsSliderElement,
      this.creditorsSliderElement,
      this.currentAssetsSliderElement,
      this.fixedAssetsSliderElement,
      this.netAssetsSliderElement,
      this.accountsDueSliderElement,
      this.latestAccountsSubmittedSliderElement,
      this.grossMarginSliderElement,
      this.assetsGrowthSliderElement,
      this.fixedAssetsGrowthSliderElement,
      this.revenueGrowthSliderElement,
      this.grossProfitGrowthSliderElement,
      this.investmentRaisedSliderElement,
      this.shareFilingEventWithinDaysSliderElement,
      this.shareFilingCapitalValueSliderElement,
      this.grantAmountSliderElement,
      this.grantMonthsUntilProjectEndSliderElement,
      this.headcountSliderElement,
      this.headcountGrowthSliderElement,
      this.departmentHeadcountSliderElement,
      this.averageDirectorAgeSliderElement,
      this.numberOfDirectorsSliderElement,
      this.numberOfPSCsSliderElement,
      this.recentDirectorshipMonthRangeSliderElement,
      this.websiteVisitorsSliderElement,
      this.eventsDayRangeSliderElement,
      this.numberOfRolesHiringForSliderElement,
      this.daysUntilFinancialYearEndSliderElement,
      this.daysUntilAnniversarySliderElement,
      this.alertsDayRangeSliderElement,
      this.numberOfKnownOfficeLocationsSliderElement,
      this.geographicalRevenueSliderElement,
      this.totalEnergyKwhSliderElement,
      this.gasKwhSliderElement,
      this.electricKwhSliderElement,
      this.scope1KwhSliderElement,
      this.scope2KwhSliderElement,
      this.scope3KwhSliderElement,
      this.totalEmissionsKgCo2eSliderElement,
      this.scope1KgCo2eSliderElement,
      this.scope2KgCo2eSliderElement,
      this.scope3KgCo2eSliderElement,
      this.kgCo2ePerMillionRevenueSliderElement,
      this.kgCo2ePerEmployeeSliderElement,
      this.patentFiledWithinDaysSliderElement,
      this.patentAwardedWithinDaysSliderElement,
      this.contractValueSliderElement,
      this.contractStartMonthsSliderElement,
      this.contractEndMonthsSliderElement,
      this.chargeCreatedSliderElement,
    ];

    const singleSliderElements = [
      this.mileRadiusSliderElement,
      this.importExportSliderElement,
      this.importExportFrequencySliderElement,
      this.importExportFrequencyMonthCountSliderElement,
      this.numberOfRolesHiringForDayLimitSliderElement,
      this.hiringDayLimitSliderElement,
      this.newsDayRangeSliderElement,
      this.accountsSearchDayRangeSliderElement,
      this.eventsMileRadiusSliderElement,
      this.contractAwardedWithinMonthsSliderElement,
    ];

    const allManualSliders = [...doubleSliderElements, ...singleSliderElements];

    allManualSliders.forEach(slider => {
      if (slider) {
        slider.reset();
      }
    });

    this.allConfigSlidersChildren.forEach(slider => {
      if (slider) {
        slider.reset();
      }
    });

    // this is to clear searchTags for configSliders
    this.sliderValues = {};

    this.setZintSliderLimitsToNull();
  }

  setZintSliderLimitsToNull(): void {
    this.employeeLowerCount = null;
    this.employeeUpperCount = null;
    this.departmentHeadCountLowerCount = null;
    this.departmentHeadCountUpperCount = null;
    this.lowerHeadCountGrowthPercentage = null;
    this.upperHeadCountGrowthPercentage = null;
    this.lowerAge = null;
    this.upperAge = null;
    this.mileRadiusOfPostcode = null;
    this.lowerNumberOfKnownOfficeLocations = null;
    this.upperNumberOfKnownOfficeLocations = null;
    this.lowerGeographicalRevenueSelected = null;
    this.upperGeographicalRevenueSelected = null;
    this.importExportTimeScale = null;
    this.webTrafficLow = null;
    this.webTrafficHigh = null;
    this.lowerAverageDirectorAgeRange = null;
    this.upperAverageDirectorAgeRange = null;
    this.lowerNumberOfDirectors = null;
    this.upperNumberOfDirectors = null;
    this.lowerNumberOfPSCs = null;
    this.upperNumberOfPSCs = null;
    this.lowerRecentDirectorshipMonth = null;
    this.upperRecentDirectorshipMonth = null;
    this.lowerDaysUntilFinancialYearEnd = null;
    this.upperDaysUntilFinancialYearEnd = null;
    this.lowerDaysUntilAnniversary = null;
    this.upperDaysUntilAnniversary = null;
    this.selectedHiringDayLimit = null;
    this.lowerNumberOfRolesHiringFor = null;
    this.upperNumberOfRolesHiringFor = null;
    this.selectedNumberOfRolesHiringForDayLimit = null;
    this.lowerInvestmentAmount = null;
    this.upperInvestmentAmount = null;
    this.shareFilingEventWithinDays = null;
    this.lowerShareFilingCapitalValue = null;
    this.upperShareFilingCapitalValue = null;
    this.lowerRevenueSelected = null;
    this.upperRevenueSelected = null;
    this.lowerNetProfitSelected = null;
    this.upperNetProfitSelected = null;
    this.lowerCashAtBankSelected = null;
    this.upperCashAtBankSelected = null;
    this.lowerDebtorsSelected = null;
    this.upperDebtorsSelected = null;
    this.lowerCreditorsSelected = null;
    this.upperCreditorsSelected = null;
    this.lowerCurrentAssetsSelected = null;
    this.upperCurrentAssetsSelected = null;
    this.lowerFixedAssetsSelected = null;
    this.upperFixedAssetsSelected = null;
    this.lowerNetAssetsSelected = null;
    this.upperNetAssetsSelected = null;
    this.lowerAccountsDueSelected = null;
    this.upperAccountsDueSelected = null;
    this.lowerLatestAccountsSubmittedSelected = null;
    this.upperLatestAccountsSubmittedSelected = null;
    this.lowerGrossMargin = null;
    this.upperGrossMargin = null;
    this.lowerAnnualAssetsGrowthPercentage = null;
    this.upperAnnualAssetsGrowthPercentage = null;
    this.lowerAnnualFixedAssetsGrowthPercentage = null;
    this.upperAnnualFixedAssetsGrowthPercentage = null;
    this.lowerAnnualRevenueGrowthPercentage = null;
    this.upperAnnualRevenueGrowthPercentage = null;
    this.lowerAnnualGrossProfitGrowthPercentage = null;
    this.upperAnnualGrossProfitGrowthPercentage = null;
    this.lowerGrantAmount = null;
    this.upperGrantAmount = null;
    this.grantLowerMonthsUntilProjectEnd = null;
    this.grantUpperMonthsUntilProjectEnd = null;
    this.mileRadiusOfEventsSelected = null;
    this.eventsWithinDaysLower = null;
    this.eventsWithinDaysUpper = null;
    this.newsWithinDays = null;
    this.accountsSearchWithinDays = null;
    this.lowerTotalEnergyKwhSelected = null;
    this.upperTotalEnergyKwhSelected = null;
    this.lowerGasKwhSelected = null;
    this.upperGasKwhSelected = null;
    this.lowerElectricKwhSelected = null;
    this.upperElectricKwhSelected = null;
    this.lowerScope1KwhSelected = null;
    this.upperScope1KwhSelected = null;
    this.lowerScope2KwhSelected = null;
    this.upperScope2KwhSelected = null;
    this.lowerScope3KwhSelected = null;
    this.upperScope3KwhSelected = null;
    this.lowerTotalEmissionsKgCo2eSelected = null;
    this.upperTotalEmissionsKgCo2eSelected = null;
    this.lowerScope1KgCo2eSelected = null;
    this.upperScope1KgCo2eSelected = null;
    this.lowerScope2KgCo2eSelected = null;
    this.upperScope2KgCo2eSelected = null;
    this.lowerScope3KgCo2eSelected = null;
    this.upperScope3KgCo2eSelected = null;
    this.lowerKgCo2ePerMillionRevenueSelected = null;
    this.upperKgCo2ePerMillionRevenueSelected = null;
    this.lowerKgCo2ePerEmployeeSelected = null;
    this.upperKgCo2ePerEmployeeSelected = null;
    this.patentFiledWithinDays = null;
    this.lowerPatentAwardedDays = null;
    this.upperPatentAwardedDays = null;
    this.lowerContractValueSelected = null;
    this.upperContractValueSelected = null;
    this.contractAwardedWithinMonths = null;
    this.lowerContractStartMonthsSelected = null;
    this.upperContractStartMonthsSelected = null;
    this.lowerContractEndMonthsSelected = null;
    this.upperContractEndMonthsSelected = null;
    this.lowerAlertsDayRange = null;
    this.upperAlertsDayRange = null;
  }

  // END RESETTING

  updateEmployeeRange(sliderEvent: Event): void {
    this.employeeLowerCount = sliderEvent['lower'];
    this.employeeUpperCount = sliderEvent['upper'];
  }

  updateHeadcountGrowthRange(sliderEvent: Event): void {
    this.lowerHeadCountGrowthPercentage = sliderEvent['lower'];
    this.upperHeadCountGrowthPercentage = sliderEvent['upper'];
  }

  updateDepartmentHeadcountRange(sliderEvent: Event): void {
    this.departmentHeadCountLowerCount = sliderEvent['lower'];
    this.departmentHeadCountUpperCount = sliderEvent['upper'];
  }

  updateNumberUBOsRange(sliderEvent: Event): void {
    this.lowerNumberOfUBOs = sliderEvent['lower'];
    this.upperNumberOfUBOs = sliderEvent['upper'];
  }

  updateCompanyAgeRange(sliderEvent: Event): void {
    this.lowerAge = sliderEvent['lower'];
    this.upperAge = sliderEvent['upper'];
  }

  updatePostcodeMileageRange(sliderEvent: Event): void {
    this.mileRadiusOfPostcode = sliderEvent['upper'];
  }

  updateNumberOfKnownOfficeLocationsRange(sliderEvent: Event): void {
    this.lowerNumberOfKnownOfficeLocations = sliderEvent['lower'];
    this.upperNumberOfKnownOfficeLocations = sliderEvent['upper'];
  }

  updateGeographicalRevenueRangeSelection(sliderEvent: Event): void {
    this.lowerGeographicalRevenueSelected = sliderEvent['lower'];
    this.upperGeographicalRevenueSelected = sliderEvent['upper'];
  }

  updateImportExportTimeScale(sliderEvent: Event): void {
    this.updateToDefaultImportingOrExporting();
    this.importExportTimeScale = sliderEvent['upper'];
  }

  updateImportExportFrequency(sliderEvent: Event): void {
    this.updateImportExportFrequencyToDefaultImportingOrExporting();
    this.importExportFrequency = sliderEvent['upper'];
  }

  updateImportExportFrequencyMonthCount(sliderEvent: Event): void {
    this.updateImportExportFrequencyToDefaultImportingOrExporting();
    this.importExportFrequencyMonthCount = sliderEvent['upper'];
  }

  updateWebTraffic(sliderEvent: Event): void {
    this.webTrafficLow = sliderEvent['lower'];
    this.webTrafficHigh = sliderEvent['upper'];
  }

  updateAverageDirectorAgeRange(sliderEvent: Event): void {
    this.lowerAverageDirectorAgeRange = sliderEvent['lower'];
    this.upperAverageDirectorAgeRange = sliderEvent['upper'];
  }

  updateNumberOfDirectorsRange(sliderEvent: Event): void {
    this.lowerNumberOfDirectors = sliderEvent['lower'];
    this.upperNumberOfDirectors = sliderEvent['upper'];
  }

  updateNumberOfPSCsRange(sliderEvent: Event): void {
    this.lowerNumberOfPSCs = sliderEvent['lower'];
    this.upperNumberOfPSCs = sliderEvent['upper'];
  }

  updateRecentDirectorshipMonthRange(sliderEvent: Event): void {
    this.lowerRecentDirectorshipMonth = sliderEvent['lower'];
    this.upperRecentDirectorshipMonth = sliderEvent['upper'];
  }

  updateFinancialYearEndRange(sliderEvent: Event): void {
    this.lowerDaysUntilFinancialYearEnd = sliderEvent['lower'];
    this.upperDaysUntilFinancialYearEnd = sliderEvent['upper'];
  }

  updateAnniversaryRange(sliderEvent: Event): void {
    this.lowerDaysUntilAnniversary = sliderEvent['lower'];
    this.upperDaysUntilAnniversary = sliderEvent['upper'];
  }

  updateHiringDayRange(sliderEvent: Event): void {
    this.selectedHiringDayLimit = sliderEvent['upper'];
  }

  updateNumberOfRolesHiringForRange(sliderEvent: Event): void {
    this.lowerNumberOfRolesHiringFor = sliderEvent['lower'];
    this.upperNumberOfRolesHiringFor = sliderEvent['upper'];
  }

  updateNumberOfRolesHiringForDayRange(sliderEvent: Event): void {
    this.selectedNumberOfRolesHiringForDayLimit = sliderEvent['upper'];
  }

  updateInvestmentRaisedRanges(sliderEvent: Event): void {
    this.lowerInvestmentAmount = sliderEvent['lower'];
    this.upperInvestmentAmount = sliderEvent['upper'];
  }

  updateShareFilingEventWithinDays(sliderEvent: Event): void {
    this.shareFilingEventWithinDays = sliderEvent['upper'];
  }

  updateShareFilingCapitalValue(sliderEvent: Event): void {
    this.lowerShareFilingCapitalValue = sliderEvent['lower'];
    this.upperShareFilingCapitalValue = sliderEvent['upper'];
  }

  updateRevenueRangeSelection(sliderEvent: Event): void {
    this.lowerRevenueSelected = sliderEvent['lower'];
    this.upperRevenueSelected = sliderEvent['upper'];
  }

  updateNetProfitRangeSelection(sliderEvent: Event): void {
    this.lowerNetProfitSelected = sliderEvent['lower'];
    this.upperNetProfitSelected = sliderEvent['upper'];
  }

  updateCashAtBankRangeSelection(sliderEvent: Event): void {
    this.lowerCashAtBankSelected = sliderEvent['lower'];
    this.upperCashAtBankSelected = sliderEvent['upper'];
  }

  updateDebtorsRangeSelection(sliderEvent: Event): void {
    this.lowerDebtorsSelected = sliderEvent['lower'];
    this.upperDebtorsSelected = sliderEvent['upper'];
  }

  updateCreditorsRangeSelection(sliderEvent: Event): void {
    this.lowerCreditorsSelected = sliderEvent['lower'];
    this.upperCreditorsSelected = sliderEvent['upper'];
  }

  updateCurrentAssetsRangeSelection(sliderEvent: Event): void {
    this.lowerCurrentAssetsSelected = sliderEvent['lower'];
    this.upperCurrentAssetsSelected = sliderEvent['upper'];
  }

  updateFixedAssetsRangeSelection(sliderEvent: Event): void {
    this.lowerFixedAssetsSelected = sliderEvent['lower'];
    this.upperFixedAssetsSelected = sliderEvent['upper'];
  }

  updateNetAssetsRangeSelection(sliderEvent: Event): void {
    this.lowerNetAssetsSelected = sliderEvent['lower'];
    this.upperNetAssetsSelected = sliderEvent['upper'];
  }

  updateAccountsDueInRange(sliderEvent: Event): void {
    this.lowerAccountsDueSelected = sliderEvent['lower'];
    this.upperAccountsDueSelected = sliderEvent['upper'];
  }

  updatelatestAccountsSubmittedInRange(sliderEvent: Event): void {
    this.lowerLatestAccountsSubmittedSelected = sliderEvent['lower'];
    this.upperLatestAccountsSubmittedSelected = sliderEvent['upper'];
  }

  updateGrossMarginRange(sliderEvent: Event): void {
    this.lowerGrossMargin = sliderEvent['lower'];
    this.upperGrossMargin = sliderEvent['upper'];
  }

  updateAssetsGrowthRange(sliderEvent: Event): void {
    this.lowerAnnualAssetsGrowthPercentage = sliderEvent['lower'];
    this.upperAnnualAssetsGrowthPercentage = sliderEvent['upper'];
  }

  updateFixedAssetsGrowthRange(sliderEvent: Event): void {
    this.lowerAnnualFixedAssetsGrowthPercentage = sliderEvent['lower'];
    this.upperAnnualFixedAssetsGrowthPercentage = sliderEvent['upper'];
  }

  updateRevenueGrowthRange(sliderEvent: Event): void {
    this.lowerAnnualRevenueGrowthPercentage = sliderEvent['lower'];
    this.upperAnnualRevenueGrowthPercentage = sliderEvent['upper'];
  }

  updateGrossProfitGrowthRange(sliderEvent: Event): void {
    this.lowerAnnualGrossProfitGrowthPercentage = sliderEvent['lower'];
    this.upperAnnualGrossProfitGrowthPercentage = sliderEvent['upper'];
  }

  updateGrantRangeSelected(sliderEvent: Event): void {
    this.lowerGrantAmount = sliderEvent['lower'];
    this.upperGrantAmount = sliderEvent['upper'];
  }

  updateGrantMonthsUntilProjectEndSelected(sliderEvent: Event): void {
    this.grantLowerMonthsUntilProjectEnd = sliderEvent['lower'];
    this.grantUpperMonthsUntilProjectEnd = sliderEvent['upper'];
  }

  updateEventsMileageRange(sliderEvent: Event): void {
    this.mileRadiusOfEventsSelected = sliderEvent['upper'];
  }

  updateEventsDaysRange(sliderEvent: Event): void {
    this.eventsWithinDaysLower = sliderEvent['lower'];
    this.eventsWithinDaysUpper = sliderEvent['upper'];
  }

  updateNewsDays(sliderEvent: Event): void {
    this.newsWithinDays = sliderEvent['upper'];
  }

  updateAccountsSearchDayRange(sliderEvent: Event): void {
    this.accountsSearchWithinDays = sliderEvent['upper'];
  }

  updateAlertsDayRange(sliderEvent: Event): void {
    this.lowerAlertsDayRange = sliderEvent['lower'];
    this.upperAlertsDayRange = sliderEvent['upper'];
  }

  updateTotalEnergyKwhRangeSelection(sliderEvent: Event): void {
    this.lowerTotalEnergyKwhSelected = sliderEvent['lower'];
    this.upperTotalEnergyKwhSelected = sliderEvent['upper'];
  }

  updateGasKwhRangeSelection(sliderEvent: Event): void {
    this.lowerGasKwhSelected = sliderEvent['lower'];
    this.upperGasKwhSelected = sliderEvent['upper'];
  }

  updateElectricKwhRangeSelection(sliderEvent: Event): void {
    this.lowerElectricKwhSelected = sliderEvent['lower'];
    this.upperElectricKwhSelected = sliderEvent['upper'];
  }

  updateScope1KwhRangeSelection(sliderEvent: Event): void {
    this.lowerScope1KwhSelected = sliderEvent['lower'];
    this.upperScope1KwhSelected = sliderEvent['upper'];
  }

  updateScope2KwhRangeSelection(sliderEvent: Event): void {
    this.lowerScope2KwhSelected = sliderEvent['lower'];
    this.upperScope2KwhSelected = sliderEvent['upper'];
  }

  updateScope3KwhRangeSelection(sliderEvent: Event): void {
    this.lowerScope3KwhSelected = sliderEvent['lower'];
    this.upperScope3KwhSelected = sliderEvent['upper'];
  }

  updateTotalEmissionsKgCo2eRangeSelection(sliderEvent: Event): void {
    this.lowerTotalEmissionsKgCo2eSelected = sliderEvent['lower'];
    this.upperTotalEmissionsKgCo2eSelected = sliderEvent['upper'];
  }

  updateScope1KgCo2eRangeSelection(sliderEvent: Event): void {
    this.lowerScope1KgCo2eSelected = sliderEvent['lower'];
    this.upperScope1KgCo2eSelected = sliderEvent['upper'];
  }

  updateScope2KgCo2eRangeSelection(sliderEvent: Event): void {
    this.lowerScope2KgCo2eSelected = sliderEvent['lower'];
    this.upperScope2KgCo2eSelected = sliderEvent['upper'];
  }

  updateScope3KgCo2eRangeSelection(sliderEvent: Event): void {
    this.lowerScope3KgCo2eSelected = sliderEvent['lower'];
    this.upperScope3KgCo2eSelected = sliderEvent['upper'];
  }

  updateKgCo2ePerMillionRevenueRangeSelection(sliderEvent: Event): void {
    this.lowerKgCo2ePerMillionRevenueSelected = sliderEvent['lower'];
    this.upperKgCo2ePerMillionRevenueSelected = sliderEvent['upper'];
  }

  updateKgCo2ePerEmployeeRangeSelection(sliderEvent: Event): void {
    this.lowerKgCo2ePerEmployeeSelected = sliderEvent['lower'];
    this.upperKgCo2ePerEmployeeSelected = sliderEvent['upper'];
  }

  updatePatentFiledWithinDays(sliderEvent: Event): void {
    this.patentFiledWithinDays = sliderEvent['upper'];
  }

  updatePatentAwardedWithinDays(sliderEvent: Event): void {
    this.lowerPatentAwardedDays = sliderEvent['lower'];
    this.upperPatentAwardedDays = sliderEvent['upper'];
  }

  updateContractAwardedWithinMonths(sliderEvent: Event): void {
    this.contractAwardedWithinMonths = sliderEvent['upper'];
  }

  updateContractValueRangeSelection(sliderEvent: Event): void {
    this.lowerContractValueSelected = sliderEvent['lower'];
    this.upperContractValueSelected = sliderEvent['upper'];
  }

  updateContractStartMonthsRangeSelection(sliderEvent: Event): void {
    this.lowerContractStartMonthsSelected = sliderEvent['lower'];
    this.upperContractStartMonthsSelected = sliderEvent['upper'];
  }

  updateContractEndMonthsRangeSelection(sliderEvent: Event): void {
    this.lowerContractEndMonthsSelected = sliderEvent['lower'];
    this.upperContractEndMonthsSelected = sliderEvent['upper'];
  }

  updateChargeCreatedRangeSelection(sliderEvent: Event): void {
    this.chargeCreatedMonthsAgoLower = sliderEvent['lower'];
    this.chargeCreatedMonthsAgoUpper = sliderEvent['upper'];
  }

  triggerConfigSliderUpdate(
    sliderUpdatedValues: Record<string, any>,
    key: string
  ): void {
    const { lower, upper } = sliderUpdatedValues;
    if (lower === undefined && upper === undefined) {
      delete this.sliderValues[key];
    } else {
      this.sliderValues[key] = { lower, upper };
    }
  }

  getChildSics(sicObject): Array<any> {
    const childrenSicIds = sicObject['children'];
    return this.companySICCodeOptions.filter(
      sic => childrenSicIds.indexOf(sic.id) > -1
    );
  }

  collapseSICCodes(sicRequired): void {
    const childSics = this.getChildSics(sicRequired);
    for (const sic of childSics) {
      sic.shouldShow = false;
      this.collapseSICCodes(sic);
    }
  }

  expandOrCollapseSICCodes(sicID): void {
    const sicRequired = this.companySICCodeOptions.filter(
      sic => sic.id == sicID
    )[0];
    const childSics = this.getChildSics(sicRequired);
    if (childSics.some(e => e.shouldShow == true)) {
      this.collapseSICCodes(sicRequired);
    } else {
      childSics.map(function (e) {
        e.shouldShow = true;
      });
    }
  }

  updateVisibleImportExportCodesAfterSearch(data): void {
    this.importExportCategories = data.import_export_results;
  }

  searchImportExportCodes(): void {
    this.dashboardService
      .searchImportExportCodes(this.importExportTextSearch)
      .subscribe(data => this.updateVisibleImportExportCodesAfterSearch(data));
  }

  resetImportExportSearch(): void {
    this.dashboardService
      .getInitialImportExportCategories()
      .subscribe(
        data => (this.importExportCategories = data.import_export_sections)
      );
  }

  updateImpExpCats(data, id, impExpType): void {
    for (let i = 0; i < this.importExportCategories.length; i++) {
      // tslint:disable-next-line:prefer-const
      var existingCat = this.importExportCategories[i];
      if (existingCat.id == id && existingCat.impExpType == impExpType) {
        // tslint:disable-next-line:prefer-const
        var insertionIndex = i;
        existingCat.isExpanded = true;
        break;
      }
    }

    for (const child of data.reverse()) {
      existingCat.children.push(String(child.id + '_' + child.impExpType));
      this.importExportCategories.splice(insertionIndex + 1, 0, child);
    }
  }

  collapseImpExpCat(importExportCategory): void {
    for (let i = 0; i < importExportCategory.children.length; i++) {
      const alreadyOpenChild = importExportCategory.children[i];
      const components = alreadyOpenChild.split('_');
      const _id = components[0];
      const impExpType = components[1];
      for (let j = 0; j < this.importExportCategories.length; j++) {
        const existingCat = this.importExportCategories[j];
        if (existingCat.id == _id && existingCat.impExpType == impExpType) {
          this.collapseImpExpCat(existingCat);
          this.importExportCategories.splice(j, 1);
          break;
        }
      }
    }
    importExportCategory.children = [];
    importExportCategory.isExpanded = false;
  }

  expandOrCollapseImportExportCategories(importExportCategory): void {
    if (importExportCategory.isExpanded) {
      this.collapseImpExpCat(importExportCategory);
    } else {
      this.dashboardService
        .getChildImportExportCategories(
          importExportCategory.id,
          importExportCategory.impExpType,
          importExportCategory.level
        )
        .subscribe(data =>
          this.updateImpExpCats(
            data,
            importExportCategory.id,
            importExportCategory.impExpType
          )
        );
    }
  }

  expandOrCollapseItems(companyChildren, type): void {
    const childStatusOptions = this[type].filter(
      el => companyChildren.indexOf(el.name) > -1
    );
    var showValue = childStatusOptions.some(e => e.shouldShow == true)
      ? false
      : true;
    for (const child of childStatusOptions) {
      child.shouldShow = showValue;
    }
  }

  updateCountOfSelectedCompanies(): void {
    const selectedCompanies = this.companies.filter(
      company => company.checked == true
    );
    this.totalCompaniesSelected = selectedCompanies.length;
    this.data.changeDataSourceCurrentCompanyIds(
      selectedCompanies.map(company => company.id)
    );
    // Need to do this as current search params need to be shared on individual company downloads so we can get ES column information.request.POST
    this.data.updateJustCurrentSearchParams(this.allFilterParams());
  }

  showOrHideInfoOnAllSelectedCompanies(): void {
    if (this.checkboxSelectAllCompanies) {
      this.showSelectedNumbersSection = true;
      this.companies.map(company => (company.checked = true));
      this.totalCompaniesSelected = this.companies.length;
      this.data.changeDataSourceCurrentCompanyIds(
        this.companies.map(company => company.id)
      );
    } else {
      this.hasSelectedTotalNumberMatchingFilter = false;
      this.showSelectedNumbersSection = false;
      this.companies.map(company => (company.checked = false));
      this.totalCompaniesSelected = null;
    }
  }

  hasSelectedAllCompaniesByQuery(): void {
    this.checkboxSelectAllCompanies = true;
    this.hasSelectedTotalNumberMatchingFilter = true;
    this.showOrHideInfoOnAllSelectedCompanies();
    this.data.changeDataSourceCurrentSearchParams(this.allFilterParams());
  }

  updateAddressTypeOnInputChange(): void {
    if (
      this.postcodeWithinRadius ||
      this.postcodeBeginsWith ||
      this.selectedTownId ||
      this.postcodeDistrict ||
      this.ukRegion
    ) {
      if (!this.selectedAddressTypeOption) {
        this.selectedAddressTypeOption = this.addressOptions[0];
      }
    }
  }

  postcodeBeginsWithChanged(): void {
    this.selectedTownId = '';
    this.postcodeDistrict = '';
    this.townName = '';
    this.postcodeWithinRadius = '';
    this.updateAddressTypeOnInputChange();
  }

  postcodeDistrictChanged(): void {
    this.selectedTownId = '';
    this.townName = '';
    this.postcodeWithinRadius = '';
    this.updateAddressTypeOnInputChange();
  }

  ukRegionChanged(): void {
    this.selectedTownId = '';
    this.postcodeDistrict = '';
    this.townName = '';
    this.postcodeWithinRadius = '';
    this.updateAddressTypeOnInputChange();
  }

  postcodeWithinRadiusChanged(): void {
    this.selectedTownId = '';
    this.postcodeDistrict = '';
    this.townName = '';
    this.postcodeBeginsWith = '';
    this.updateAddressTypeOnInputChange();
  }

  updateMatchingTowns(data): void {
    if (data.matching_towns) {
      this.possibleTownNames = data.matching_towns;
    }
  }

  townNameChanged(): void {
    if (this.townName && this.townName.length > 2) {
      this.dashboardService
        .getMatchingTowns(this.townName)
        .subscribe(data => this.updateMatchingTowns(data));
    }
  }

  selectShortlistedTownName(inputID, townName): void {
    this.postcodeWithinRadius = '';
    this.postcodeBeginsWith = '';
    this.selectedTownId = inputID;
    this.townName = townName;
    this.updateAddressTypeOnInputChange();
  }

  // ----------- HEADCOUNTS/DEPARTMENTS -----------

  resetFunctionalDepartmentSearch(): void {
    this.hasSearchedFunctionalDepartment = false;
    this.selectedFunctionalDepartments = this.functionalDepartmentOptions.map(
      option => (option.shouldShow = true)
    );
  }

  searchFunctionalDepartment(): void {
    if (
      this.functionalDepartmentTextSearch &&
      this.functionalDepartmentTextSearch.length > 2
    ) {
      this.isSearchingFunctionalDepartments = true;
      this.hasSearchedFunctionalDepartment = true;
      this.functionalDepartmentOptions
        .filter(option => (option.shouldShow = true))
        .map(option => (option.shouldShow = false));

      this.functionalDepartmentOptions
        .filter(
          option =>
            option.name
              .toLowerCase()
              .indexOf(this.functionalDepartmentTextSearch.toLowerCase()) >= 0
        )
        .map(option => (option.shouldShow = true));

      this.isSearchingFunctionalDepartments = false;
    }
  }

  checkIfVisible(option) {
    if (option.shouldShow) {
      option.checked = true;
    }
  }

  checkUncheckAllVisibleFunctionalDepartments(): void {
    if (this.selectAllVisibleFunctionalDepartments) {
      this.functionalDepartmentOptions.forEach(option =>
        this.checkIfVisible(option)
      );
    } else {
      this.functionalDepartmentOptions.forEach(
        option => (option.checked = false)
      );
    }
  }

  // ----------- HEADCOUNTS/DEPARTMENTS -----------

  // ----------- KEYWORDS -----------

  removeWebsiteKeyword(keyword): void {
    const index = this.websiteKeywords.indexOf(keyword);
    if (index >= 0) {
      this.websiteKeywords.splice(index, 1);
      this.possibleWebsiteKeywords = [];
    }
  }

  addWebsiteKeyword(keyword): void {
    const index = this.websiteKeywords.indexOf(keyword);
    if (index < 0) {
      this.websiteKeywords.push(keyword);
      this.possibleWebsiteKeywords = [];
      this.websiteKeywordInput = '';
    }
  }

  forceAddWebsiteKeyword(event): void {
    if (this.websiteKeywordInput && this.websiteKeywordInput.length > 2) {
      this.addWebsiteKeyword(this.websiteKeywordInput.toLowerCase());
    }
  }

  updateWebsiteKeywords(data): void {
    if (data.keywords) {
      this.possibleWebsiteKeywords = data.keywords;
    }
  }

  websiteKeywordChanged(): void {
    if (this.websiteKeywordInput && this.websiteKeywordInput.length > 2) {
      this.dashboardService
        .getMatchingWebsiteKeywords(this.websiteKeywordInput)
        .subscribe(data => this.updateWebsiteKeywords(data));
    }
  }

  // ----------- KEYWORDS -----------

  // ----------- EVENTS -----------

  removeMatchingEvent(event): void {
    const index = this.selectedEvents.indexOf(event);
    if (index >= 0) {
      this.selectedEvents.splice(index, 1);
    }
  }

  addMatchingEvent(possibleMatchingEvent): void {
    const alreadyHaveEventCounter = this.selectedEvents.filter(
      event => event.id == possibleMatchingEvent.id
    ).length;
    if (alreadyHaveEventCounter == 0) {
      this.selectedEvents.push(possibleMatchingEvent);
      this.possibleMatchingEvents = [];
      this.eventNameSearchInput = '';
      this.postcodeEventsWithinRadius = '';
    }
  }

  eventNameSearchChanged(): void {
    if (this.eventNameSearchInput && this.eventNameSearchInput.length > 2) {
      this.dashboardService
        .getMatchingEvents(this.eventNameSearchInput)
        .subscribe(data => this.updatePossibleMatchingEvents(data));
    }
  }

  updatePossibleMatchingEvents(data): void {
    if (data.events) {
      this.possibleMatchingEvents = data.events;
    }
  }

  // ----------- EVENTS -----------

  // ----------- GEOGRAPHICAL REVENUE -----------

  resetGeographicalRevenueSearch(): void {
    this.selectedGeographicalRevenueCountries =
      this.countriesOfGeographicalRevenueOptions.map(
        option => (option.shouldShow = true)
      );
    this.selectedGeographicalRevenueTerritories =
      this.territoriesOfGeographicalRevenueOptions.map(
        option => (option.shouldShow = true)
      );
  }

  searchGeographicalRevenue(): void {
    if (
      this.geographicalRevenueTextSearch &&
      this.geographicalRevenueTextSearch.length > 2
    ) {
      this.countriesOfGeographicalRevenueOptions
        .filter(option => (option.shouldShow = true))
        .map(option => (option.shouldShow = false));
      this.countriesOfGeographicalRevenueOptions
        .filter(
          option =>
            option.name
              .toLowerCase()
              .indexOf(this.geographicalRevenueTextSearch.toLowerCase()) >= 0
        )
        .map(option => (option.shouldShow = true));

      this.territoriesOfGeographicalRevenueOptions
        .filter(option => (option.shouldShow = true))
        .map(option => (option.shouldShow = false));
      this.territoriesOfGeographicalRevenueOptions
        .filter(
          option =>
            option.name
              .toLowerCase()
              .indexOf(this.geographicalRevenueTextSearch.toLowerCase()) >= 0
        )
        .map(option => (option.shouldShow = true));
    }
  }

  // ----------- GEOGRAPHICAL REVENUE -----------

  expandOrCollapseSidebar(): void {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    $('.collapse').collapse('hide');
    this.updateScrollBarDimensions();
  }

  expandSideBarIfRequired(): void {
    if (this.isSidebarCollapsed) {
      this.isSidebarCollapsed = false;
      this.updateScrollBarDimensions();
    }
  }

  addOrRemoveColumnToWrappedColumnNumbers(columnNumber): void {
    const index = this.wrappedTableColumnNumbers.indexOf(columnNumber);
    if (index >= 0) {
      this.wrappedTableColumnNumbers.splice(index, 1);
    } else {
      this.wrappedTableColumnNumbers.push(columnNumber);
    }
    localStorage.setItem(
      'zintWrappedColumns',
      JSON.stringify(this.wrappedTableColumnNumbers)
    );
  }

  columnResized($event, columnName): void {
    const width = parseInt($event.target.style.width.replace('px', ''));
    const exisitingValue = this.columnNamesToWidths[columnName];
    // if it's the same then ignore it.
    if (
      (exisitingValue != width || exisitingValue == undefined) &&
      !isNaN(width)
    ) {
      // still resize without waiting for the subscription.
      this.columnNamesToWidths[columnName] = width;

      this.dashboardService
        .saveColumnSize(columnName, width)
        .subscribe(data => {
          if (data.success) {
            this.updateScrollBarDimensions();
          }
        });
    }
  }

  mouseHasLeftHeaderCell($event, columnName): void {
    // Only trigger the column resize event if we are currently clicked on a header cell.
    // We don't want to trigger this if we are just moving the mouse over it.
    if (this.tableHeaderColumnNumberCurrentlyClicked != null) {
      this.tableHeaderColumnNumberCurrentlyClicked = null;
      this.columnResized($event, columnName);
      $($event.target).mouseup();
    }
  }

  showEditColumnsModal(): void {
    $('#editColumnsModal').modal('show');
  }
}
