import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap } from 'rxjs/operators';
import { Company } from '../classes/company';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class TerritoryService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private cookieservice: CookieService
  ) {}

  isLoading = {
    loadingListTerritories: false,
    loadingGetOrganisationUsersAndTeams: false,
    loadingDeleteTerritory: false,
    loadingTogglingTerritoryEnforcement: false
  };

  listTerritories(fullDetails = false): Observable<any> {
    this.isLoading['loadingListTerritories'] = true;
    let params = new HttpParams().set('fullDetails', fullDetails);
    return this.http
      .post(
        baseUrl + '/list_territories/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingListTerritories'] = false)),
        catchError(
          this.pubMethods.handleError('listTerritories', [], this.isLoading)
        )
      );
  }

  toggleTerritoryEnforcement(territoryId): Observable<any> {
    this.isLoading['loadingTogglingTerritoryEnforcement'] = true;
    let params = new HttpParams().set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/toggle_territory_enforcement/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingTogglingTerritoryEnforcement'] = false)),
        catchError(
          this.pubMethods.handleError('toggleTerritoryEnforcement', [], this.isLoading)
        )
      );
  }

  getOrganisationUsersAndTeams(): Observable<any> {
    this.isLoading['loadingGetOrganisationUsersAndTeams'] = true;
    return this.http
      .post(
        baseUrl + '/get_organisation_users_and_teams/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['loadingGetOrganisationUsersAndTeams'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'getOrganisationUsersAndTeams',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteTerritory(territoryId): Observable<any> {
    this.isLoading['loadingDeleteTerritory'] = true;
    let params = new HttpParams().set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/delete_territory/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingDeleteTerritory'] = false)),
        catchError(
          this.pubMethods.handleError('deleteTerritory', [], this.isLoading)
        )
      );
  }

  addUserToTerritory(userId, territoryId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/add_user_to_territory/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError('addUserToTerritory', [], this.isLoading)
        )
      );
  }

  removeUserFromTerritory(userId, territoryId): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId)
      .set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/remove_user_from_territory/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError(
            'removeUserFromTerritory',
            [],
            this.isLoading
          )
        )
      );
  }

  addTeamToTerritory(teamId, territoryId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/add_team_to_territory/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError('addTeamToTerritory', [], this.isLoading)
        )
      );
  }

  removeTeamFromTerritory(teamId, territoryId): Observable<any> {
    let params = new HttpParams()
      .set('teamId', teamId)
      .set('territoryId', territoryId);
    return this.http
      .post(
        baseUrl + '/remove_team_from_territory/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError(
            'removeTeamFromTerritory',
            [],
            this.isLoading
          )
        )
      );
  }

  saveTerritory(territoryName, territoryParams): Observable<any> {
    let _params = new HttpParams()
      .set('territoryName', territoryName)
      .set('territoryParams', territoryParams);
    this.isLoading['loadingSaveTerritory'] = true;
    return this.http
      .post(
        baseUrl + '/create_territory/',
        _params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingSaveTerritory'] = false)),
        catchError(
          this.pubMethods.handleError('saveTerritory', [], this.isLoading)
        )
      );
  }
}
